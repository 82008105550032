<template>
  <div class="popup-body">
    <p class="warning">{{ message }}</p>
    <p v-if="errorMessage" class="warning">{{ errorMessage }}</p>
    <table class="spark-table">
      <tr>
        <th colspan="5">Cost Saved for Lot Size {{ lotSize }}</th>
      </tr>
      <tr>
        <td>&nbsp;</td>
        <td>Manufacturing Cost Saved</td>
        <td>{{ $formatZeroDecimalPlaces(costSaved) }} €</td>
        <td>per Lot</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
      </tr>
      <tr v-for="cost in additionalSaving" :key="cost.uid">
        <template v-if="cost != undefined">
          <td>&nbsp;</td>
          <td>{{ savingsTypes[cost.savings_type?.uid]?.value }}</td>
          <td>{{ cost.custom_cost_saved_pl }} €</td>
          <td>per Lot</td>
          <td>{{ cost.description }}</td>
          <td><i class="fas fa-trash" @click="deleteItem(cost.uid)" /></td>
        </template>
      </tr>
      <tr v-if="!showForm && primaryAndBenchmarkExisting">
        <td>&nbsp;</td>
        <td colspan="5">
          <div class="flex-row action" @click="show()">
            <p>Add additional Cost Savings</p>
            <i class="fas fa-plus" />
          </div>
        </td>
      </tr>
      <tr>
        <td>&nbsp;</td>
        <td class="sum">Sum Cost Saved per Lot</td>
        <td colspan="3" class="sum">{{ sumCostSavings }}</td>
      </tr>

      <tr v-if="showForm" class="form-style">
        <td colspan="6">
          <div class="flex-row">
            <div class="spark-new-input-group">
              <select v-model="formData.savings_type" class="spark-new-select-input">
                <option v-for="(item, key) in savingsTypes" :key="key" :value="item.uid">
                  {{ item.value }}
                </option>
              </select>
              <label class="spark-new-select-label">Type</label>
            </div>
            <div class="spark-new-input-group">
              <input
                v-model="formData.value"
                :required="true"
                type="text"
                name="name"
                autocomplete="off"
                class="spark-new-input-clean"
              />
              <label class="spark-new-input-label">Value in €</label>
            </div>
            <div class="spark-new-input-group">
              <select v-model="formData.level" class="spark-new-select-input">
                <option v-for="(item, key) in levels" :key="key" :value="item">
                  {{ key }}
                </option>
              </select>
              <label class="spark-new-select-label">Level</label>
            </div>
            <div class="spark-new-input-group">
              <input
                v-model="formData.description"
                :required="true"
                type="text"
                name="name"
                autocomplete="off"
                class="spark-new-input-clean"
              />
              <label class="spark-new-input-label">Description</label>
            </div>
            <i class="fas fa-check icon-button" title="Add" @click="add()" />
            <i class="fas fa-times icon-button" title="Discard" @click="closeForm()" />
          </div>
        </td>
      </tr>

      <tr>
        <th>Lead Time Saved</th>
        <td>{{ leadtimeSaved }}</td>
      </tr>
      <tr>
        <th>Co2 Saved</th>
        <td>{{ co2Saved }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
export default {
  name: 'AdditionalCostsToPart',

  props: {
    additionalData: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      showForm: false,
      levels: {
        Part: 'cost_pp',
        Lot: 'cost_pl',
      },

      formData: {},
      errorMessage: '',
    };
  },

  computed: {
    ...mapState(['user', 'part']),
    ...mapState('application', ['axiosInstance']),

    ...mapGetters(['processChainsAnalysisFinished']),

    oldStat() {
      return this.part.part_stat;
    },

    additionalSaving() {
      return this.part.additional_savings;
    },

    leadtimeSaved() {
      if (this.primaryAndBenchmarkExisting && this.processChainsAnalysisFinished) {
        let primaryLeadtime = this.part.process_chains[this.primaryProcessChainUid].leadtime;
        let benchmarkLeadtime = this.part.process_chains[this.benchmarkProcessChainUid].leadtime;
        if (benchmarkLeadtime - primaryLeadtime < 0) {
          return '-';
        } else {
          return this.$formatZeroDecimalPlaces(benchmarkLeadtime - primaryLeadtime) + ' d';
        }
      } else {
        return '-';
      }
    },

    costSaved() {
      if (this.primaryAndBenchmarkExisting && this.processChainsAnalysisFinished) {
        let primaryCost = this.part.process_chains[this.primaryProcessChainUid].cost.cost_pl;
        let benchmarkCost = this.part.process_chains[this.benchmarkProcessChainUid].cost.cost_pl;
        return benchmarkCost - primaryCost;
      } else {
        return 0;
      }
    },

    co2Saved() {
      if (this.primaryAndBenchmarkExisting && this.processChainsAnalysisFinished) {
        let primaryco2 = this.part.process_chains[this.primaryProcessChainUid].co2.co2_pl;
        let benchmarkco2 = this.part.process_chains[this.benchmarkProcessChainUid].co2.co2_pl;
        if (benchmarkco2 - primaryco2 < 0) {
          return '-';
        } else {
          return this.$formatZeroDecimalPlaces(benchmarkco2 - primaryco2) + ' kg CO2-eq';
        }
      } else {
        return '-';
      }
    },

    message() {
      if (this.primaryAndBenchmarkExisting && this.processChainsAnalysisFinished) {
        let prefix = 'Please review the following savings, before changing the status';
        if (this.oldStat == 'unset') {
          return `${prefix} to '${this.additionalData.newStatText}'.`;
        } else {
          return `${prefix} from '${this.additionalData.oldStatText}' to '${this.additionalData.newStatText}'.`;
        }
      } else if (!this.primaryAndBenchmarkExisting && !this.processChainsAnalysisFinished) {
        return 'Main Process AND Benchmark Process must be selected.';
      } else if (!this.processChainsAnalysisFinished) {
        return 'Processes must be analyzed.';
      } else {
        return '';
      }
    },

    lotSize() {
      if (this.primaryAndBenchmarkExisting) {
        return this.part.process_chains[this.primaryProcessChainUid]?.lot_size;
      } else {
        return '-';
      }
    },

    primaryProcessChainUid() {
      return this.part.primary_process_chain_id;
    },

    benchmarkProcessChainUid() {
      return this.part.benchmark_process_chain_id;
    },

    primaryAndBenchmarkExisting() {
      return this.primaryProcessChainUid != null && this.benchmarkProcessChainUid != null;
    },

    savingsTypes() {
      return this.user.organization.savings_type_choices;
    },

    sumCostSavings() {
      let primaryCostSaved = this.costSaved;
      let sumCostSaved = primaryCostSaved;

      if (Array.isArray(this.additionalSaving)) {
        this.additionalSaving.forEach(saving => {
          let cost = parseFloat(saving.custom_cost_saved_pl);
          if (!isNaN(cost)) {
            sumCostSaved += cost;
          }
        });
      }

      if (primaryCostSaved < 0 && sumCostSaved < 0) {
        return '0 €';
      }

      return sumCostSaved === 0 ? '-' : this.$formatZeroDecimalPlaces(sumCostSaved) + ' €';
    },
  },

  methods: {
    ...mapMutations(['addAdditionalSaving', 'removeAdditionalSaving']),

    show() {
      this.showForm = true;
      this.errorMessage = '';
    },

    async deleteItem(uid) {
      try {
        await this.axiosInstance.delete(`api/v1/additional-saving/${uid}/`);
        this.removeAdditionalSaving(uid);
        this.errorMessage = '';
      } catch (error) {
        if (error.response && error.response.data && error.response.data.error_message) {
          this.errorMessage = `⚠️ ${error.response.data.error_message}`;
        } else {
          this.errorMessage = '⚠️ Error deleting the item.';
        }
        console.log(error);
      }
    },

    async add() {
      if (parseFloat(this.formData.value) < 0) {
        this.errorMessage = '⚠️ Additional cost savings must be positive.';
        return;
      }

      this.formData['part_id'] = this.part.part_id;
      this.formData[this.formData.level] = this.formData.value;
      this.showForm = false;
      let savingsTypeUid = this.formData.savings_type;
      this.formData['savings_type'] = { uid: savingsTypeUid, value: this.savingsTypes[savingsTypeUid].value };
      this.formData['savings_type_uid'] = savingsTypeUid;

      try {
        await this.axiosInstance.post('api/v1/additional-saving/', this.formData);
        this.addAdditionalSaving(this.formData);
        this.formData = {};
        this.errorMessage = '';
      } catch (error) {
        if (error.response && error.response.data && error.response.data.error_message) {
          this.errorMessage = `⚠️ ${error.response.data.error_message}`;
        } else {
          this.errorMessage = '⚠️ Error adding the additional cost saving.';
        }
        console.log(error);
      }
    },

    closeForm() {
      this.showForm = false;
      this.errorMessage = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.popup-body {
  margin: 20px;
}

.flex-row {
  display: flex;
  padding: 5px;
  align-items: center;
}

i {
  margin: 0px 2px;
  padding-left: 5px;
  &:hover {
    cursor: pointer;
  }
}

.warning {
  background-color: var(--light-color);
  border-radius: 3px;
  padding-left: 5px;
}

.form-style {
  margin: 0px 5px;
  height: 8em;
  border: 1px solid var(--accent-color);
  border-radius: 3px;
  &:hover {
    background-color: white;
  }
}

.sum {
  text-decoration: underline;
}

.action:hover {
  cursor: pointer;
  color: var(--accent-color);
}

.spark-new-select-label {
  transform: translateY(-35px);
}

.form-row {
  height: 5em;
}

.spark-new-input-label {
  color: var(--spark-lightgrey);
  transform: translateY(-50px);
}
</style>
