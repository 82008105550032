<template>
  <div class="flex-container row-direction">
    <img :src="iconPath" class="machine-icon" />
    <div>
      <div class="description">{{ description }}</div>
      <FeedbackValue
        :feedback-value="value"
        field-name=""
        field-unit=""
        :field-label="fieldLabel"
        field-type="normal"
        field-style="pl-0 pt-0 mt-0"
        @feedback-value-changed="$emit('feedback', $event)"
      />
    </div>
  </div>
</template>

<script>
import FeedbackValue from '@/components/Reusable/FeedbackValues/FeedbackValue.vue';

export default {
  name: 'IconWithFeedbackValue',

  components: { FeedbackValue },

  props: {
    description: { type: String, default: '' },
    value: { type: String, default: 'undefined' },
    icon: { type: String, default: '' },
    fieldLabel: { type: String, default: '' },
  },

  emits: ['feedback'],

  data() {
    return {};
  },

  computed: {
    origin() {
      return !import.meta.env.PROD ? 'http://localhost:3030' : document.location.origin;
    },

    iconPath() {
      return new URL(this.icon, this.origin);
    },
  },
};
</script>

<style lang="scss" scoped>
.description {
  font-size: var(--8px);
  color: grey;
}

.value {
  font-size: var(--12px);
}

.machine-icon {
  margin-right: 5px;
  margin-top: auto;
  margin-bottom: auto;
  width: 30px;
  height: 30px;
}

.row-direction {
  flex-direction: row;
}
</style>
