<template>
  <div class="default-layout flex h-full w-full flex-col">
    <div class="flex h-full w-full flex-col gap-16 self-center">
      <SubNavigation />
      <div class="flex h-full w-full">
        <div class="flex h-full w-full flex-col">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubNavigation from '@/views/Settings/components/SubNavigation.vue';

export default {
  name: 'DefaultLayout',

  components: { SubNavigation },
};
</script>
