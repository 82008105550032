<template>
  <fieldset class="group">
    <legend>
      {{ title }}
      <button
        :class="showButton ? 'visible' : 'invisible'"
        class="spark-btn secondary"
        type="button"
        @click="$emit('add', title)"
      >
        Add Step
      </button>
    </legend>
    <div class="flex">
      <div v-for="step in steps" :key="step" class="flex">
        <ProcessStep :step="step" :level="level" :color="color" />
      </div>
    </div>
  </fieldset>
</template>

<script>
import ProcessStep from '@/components/PartWorkflow/ProcessChain/ProcessStep.vue';

export default {
  name: 'ProcessStepGroup',

  components: {
    ProcessStep,
  },

  props: {
    title: { type: String, default: '' },
    steps: {
      type: Object,
      default() {
        return {};
      },
    },

    showButton: Boolean,

    level: {
      type: Object,
      default() {
        return {};
      },
    },

    color: { type: String, default: '' },
  },

  emits: ['add'],
};
</script>

<style lang="scss" scoped>
.group {
  border: 1px solid var(--light-color);
  border-radius: 3px;
  display: flex;
  padding: 5px;
}

.flex {
  display: flex;
}

legend {
  font-size: var(--12px);
}

.secondary {
  background-color: white;
  color: var(--spark-darkgrey);
  &:hover {
    cursor: pointer;
  }
}
</style>
