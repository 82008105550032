<template>
  <table class="spark-table">
    <tbody>
      <tr>
        <th>Restriction</th>
        <td v-if="step.restriction == 'organization'"><i class="fas fa-user-friends" /></td>
        <td v-else-if="step.restriction == 'owner'"><i class="fas fa-user" /></td>
        <td v-else>-</td>
        <td />
      </tr>
      <tr>
        <th>Calculation Level</th>
        <td>{{ getReadableCalcLvl(step.calc_lvl) }}</td>
        <td />
      </tr>
      <tr v-if="step.calc_lvl == 'expression'">
        <th>Expression</th>
        <td class="right">{{ step.calc_expression }}</td>
        <td />
      </tr>
      <tr>
        <th>Setup Time (hh:mm:ss)</th>
        <td><FeedbackValueTimespan :editable="false" :feedback-value="step?.setup_time" /></td>
        <td />
      </tr>

      <tr>
        <th>Process Time (hh:mm:ss)</th>
        <td><FeedbackValueTimespan :editable="false" :feedback-value="step?.process_time" /></td>
        <td />
      </tr>
      <tr>
        <th>Unloading Time (hh:mm:ss)</th>
        <td><FeedbackValueTimespan :editable="false" :feedback-value="step?.unloading_time" /></td>
        <td />
      </tr>

      <tr>
        <th>Fixed Cost</th>
        <td class="right">{{ step.fixed_cost }}</td>
        <td>€</td>
      </tr>
      <tr>
        <th>Machine Hourly Rate</th>
        <td class="right">{{ step.machine_hourly_rate }}</td>
        <td>€/h</td>
      </tr>
      <tr>
        <th>Staff Time Factor</th>
        <td class="right">{{ parseInt(step.staff_time_factor_percent) }}%</td>
        <td />
      </tr>
      <tr>
        <th>Worker</th>
        <td colspan="2">{{ getReadableWorkerType(step.worker_type) }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapState } from 'vuex';

import FeedbackValueTimespan from '../../../../components/Reusable/FeedbackValues/FeedbackValueTimespan.vue';

export default {
  name: 'ProcessChainStep',

  components: { FeedbackValueTimespan },

  props: {
    step: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  computed: {
    ...mapState(['workerTypes', 'calcLvls', 'user']),
  },

  methods: {
    getReadableCalcLvl(calcLvl) {
      if (this.calcLvls) {
        return Object.values(this.calcLvls).find(obj => {
          return obj.value === calcLvl;
        }).text;
      }
    },

    getReadableWorkerType(workerType) {
      if (this.workerTypes) {
        return Object.values(this.workerTypes).find(obj => {
          return obj.value === workerType;
        }).text;
      }
    },
  },
};
</script>
