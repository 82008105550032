<template>
  <div class="flex flex-wrap items-center">
    <div v-for="(cat, id) in sortedCategories" :key="id">
      <div v-if="cat.category_type === 'combo_category'" class="spark-new-input-group">
        <select
          v-model="cat.uid"
          class="spark-new-select-input"
          :title="lockedForUser ? lockedTitle : ''"
          :disabled="lockedForUser"
          :class="!cat.uid && cat.required && highlightCategories ? 'highlight-red-border' : ''"
          @change="saveComboCategory(id, cat)"
        >
          <option :value="null" />
          <option v-for="(item, itemid) in cat.items" :key="itemid" :value="itemid">
            {{ item }}
          </option>
        </select>
        <label class="spark-new-select-label crop">{{ cat.category }}<span v-if="cat.required">*</span></label>
      </div>

      <div v-if="cat.category_type === 'text_category'" class="spark-new-input-group">
        <input
          v-model="cat.value"
          type="text"
          name="text_category"
          autocomplete="off"
          class="spark-new-input-clean border rounded-4 text-13"
          :title="lockedForUser ? lockedTitle : ''"
          :disabled="lockedForUser"
          :class="!cat.value && cat.required && highlightCategories ? 'highlight-red-border' : ''"
          @change="saveTextCategory(id, cat)"
        />
        <label class="spark-new-input-label crop"> {{ cat.category }}<span v-if="cat.required">*</span></label>
      </div>
      <div v-if="validateThisUrl(cat.value)" title="Go to" class="external-link-icon" @click="goToUrl(cat.value)">
        <i class="fas fa-external-link-alt" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

import validateUrl from '@/composables/validateUrl.js';

export default {
  name: 'CategoryInput',

  props: {
    requiredCategories: { type: Boolean },
  },

  emits: ['category'],

  data() {
    return {
      comboCategories: {},
      textCategories: {},
    };
  },

  computed: {
    ...mapState(['part', 'user', 'highlightCategories']),
    ...mapState('application', ['lockedTitle', 'axiosInstance']),
    ...mapGetters(['lockedForUser']),

    allComboCategories() {
      return this.part.categories.combo_categories;
    },

    sortedCategories() {
      const allCategories = { ...this.part.categories.combo_categories, ...this.part.categories.text_categories };
        const entries = Object.entries(allCategories);
      entries.sort(([, a], [, b]) => {
        if (a['order_index'] < b['order_index']) {
          return -1;
        }
        if (a['order_index'] > b['order_index']) {
          return 1;
        }
        return 0;
      });

      const sortedObj = Object.fromEntries(entries);
      return sortedObj;
    },

    highlightCategories() {
      return this.part.highlightCategories;
    },
  },

  watch: {
    allComboCategories: {
      handler() {
        if (this.allComboCategories) {
          // get comboCAtegories if not already initialized in part
          this.setPartCategories();
        }
        this.getCategories();
      },

      immediate: true,
    },
  },

  methods: {
    ...mapMutations(['setPartCategories']),

    validateThisUrl(url) {
      return validateUrl(url).validateBool;
    },

    goToUrl(url) {
      let prefix = 'https://';
      if (!url.includes(prefix) && !url.includes('http://')) {
        url = prefix + url;
      }
      window.open(url, '_blank');
    },

    getCategories() {
      for (let [key, value] of Object.entries(this.allComboCategories)) {
        if (this.user.is_external && !value.visible_external) continue;
        if (value.required === this.requiredCategories) this.comboCategories[key] = value;
      }
      let allTextCategories = this.part.categories.text_categories;
      for (let [key, value] of Object.entries(allTextCategories)) {
        if (this.user.is_external && !value.visible_external) continue;
        if (value.required === this.requiredCategories) this.textCategories[key] = value;
      }
    },

    saveTextCategory(id, textcat) {
      let cat = {};
      cat[id] = {
        category: textcat.category,
        value: textcat.value,
        required: textcat.required,
      };
      this.$emit('category', { text_categories: cat });
    },

    saveComboCategory(id, combocat) {
      let cat = {};
      if (combocat.uid != '') {
        cat[id] = {
          uid: combocat.uid,
          value: combocat.items[combocat.uid],
        };
      } else {
        cat[id] = {
          uid: '',
          value: '',
        };
      }
      this.$emit('category', { combo_categories: cat });
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  transition: all 0.3s ease-out;
}

.flex {
  display: flex;
  align-items: center;
}
.external-link-icon {
  cursor: pointer;
  color: var(--spark-lightgrey);
  font-size: var(--12px);
  &:hover {
    color: black;
  }
  padding: 5px;
}
.highlight-red-border {
  border-color: var(--spark-delete);
}

.spark-new-input-group {
  width: 200px;
}

.crop {
  text-overflow: ellipsis;
}

.spark-new-select-label,
.spark-new-input-label {
  max-width: 150px;
}

.border {
  border: 1px solid var(--light-color);
}
</style>
