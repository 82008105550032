<template>
  <div class="flex flex-col">
    <div class="ml-16 hover:font-bold hover:cursor-pointer p-4" @click="share('pdf')">
      <i class="fas fa-file-pdf mr-8" />Download PDF Report
    </div>
    <div class="ml-16 hover:font-bold hover:cursor-pointer p-4" @click="share('cad')">
      <i class="fas fa-cube mr-8" />Download Oriented CAD
    </div>
    <div class="ml-16 hover:font-bold hover:cursor-pointer p-4" @click="share('excel')">
      <i class="fas fa-file-excel mr-8" />Download EXCEL Data
    </div>
    <div class="ml-16 hover:font-bold hover:cursor-pointer p-4" @click="share('link')">
      <i class="fas fa-copy mr-8" />Copy Part Link
    </div>
    <div v-for="option in options" :key="option">
      <div class="ml-16 hover:font-bold hover:cursor-pointer p-4" @click="share('docx-custom', option.template)">
        <i class="fas fa-file mr-8" />{{ option.text }}
      </div>
    </div>
  </div>
</template>

<script>
import { saveAs } from 'file-saver';
import { mapState, mapMutations } from 'vuex';

import { copyToClipboard } from '@/helpers.js';

export default {
  name: 'ShareOptions',

  data() {
    return {
      reportTemplate: null,
      options: [],
    };
  },

  computed: {
    ...mapState(['fetchOptimalOrientation', 'fetchAnalysisResultsXLS', 'fetchCustomReportDOCX', 'part']),
    ...mapState('application', ['axiosInstance', 'axiosInstanceFileUpload']),

    chain_id() {
      return this.$route.params.process_chain_id;
    },
  },

  watch: {
    fetchOptimalOrientation: {
      handler(newVal) {
        this.fetchOrientedCAD(newVal);
      },

      immediate: true,
    },

    fetchAnalysisResultsXLS: {
      handler(newVal) {
        this.fetchExcelResultsFile(newVal);
      },

      immediate: true,
    },

    fetchCustomReportDOCX: {
      handler(newVal) {
        this.fetchCustomReportFile(newVal);
      },

      immediate: true,
    },
  },

  mounted() {
    this.updateFetchOptimalOrientation(false);
    this.updateFetchAnalysisResultsXLS(false);
    this.getTemplateDocxList();
  },

  methods: {
    ...mapMutations([
      'updateFetchOptimalOrientation',
      'updateFetchAnalysisResultsXLS',
      'updateFetchCustomReportDOCX',
      'pdfRequested',
    ]),

    share(option, additional) {
      if (option === 'cad') {
        this.requestOrientedCAD();
      }
      if (option === 'excel') {
        this.requestExcelResultsFile();
      }
      if (option === 'link') {
        this.copyPartLink();
      }
      if (option === 'pdf') {
        this.pdfRequested(true);
      }
      if (option === 'docx-custom') {
        this.reportTemplate = additional;
        this.requestCustomReportDOCX();
      }
    },

    copyPartLink() {
      const link = window.location.href;
      copyToClipboard(link);
      this.$root.notify('success', 'Copied', 'Part Link copied to clipboard.', 3000);
    },

    async requestOrientedCAD() {
      this.shareButtonClicked = false; // hide dropdown content after clicking
      await this.axiosInstance
        .get(`/api/v1/part/${this.part.part_id}/optimal-orientation-file/${this.chain_id}/`, {
          responseType: 'blob',
        })
        .then(() => {
          this.$root.notify('success', 'File requested', 'Generating oriented CAD file.', 3000);
        })
        .catch(error => {
          this.$root.notifyError('Requesting oriented CAD', error);
        });
    },

    fetchOrientedCAD(newVal) {
      if (newVal) {
        this.axiosInstance
          .get(`/api/v1/part/${this.part.part_id}/fetch-optimal-orientation-file/`, {
            part_id: this.part.part_id,
            responseType: 'blob',
          })
          .then(response => {
            const fileName = 'Optimally_oriented_' + this.part.basename.replace(/\s/g, '_');
            saveAs(response.data, fileName);
          })
          .catch(error => {
            this.$root.notifyError('Downloading oriented CAD', error);
          });
        this.updateFetchOptimalOrientation(false);
      }
    },

    async requestExcelResultsFile() {
      this.shareButtonClicked = false; // hide dropdown content after clicking
      await this.axiosInstance
        .get(`/api/v1/part/${this.part.part_id}/analysis-results-file/${this.chain_id}/`)
        .then(() => {
          this.$root.notify('success', 'File requested', 'Generating results excel sheet.', 3000);
        });
    },

    fetchExcelResultsFile(newVal) {
      if (newVal) {
        this.axiosInstance
          .get(`/api/v1/part/${this.part.part_id}/fetch-analysis-results-file/${this.chain_id}/`, {
            responseType: 'blob',
          })
          .then(response => {
            const fileName = 'Results_' + this.part.name.replace(/\s/g, '_') + '_' + this.chain_id + '.xlsx';
            saveAs(response.data, fileName);
          })
          .catch(error => {
            console.log(error);
          });
        this.updateFetchAnalysisResultsXLS(false);
      }
    },

    async getTemplateDocxList() {
      this.axiosInstanceFileUpload.get('api/v1/doc-template-list/').then(response => {
        Object.keys(response.data).forEach(key => {
          let template = response.data[key];
          if (template.doc_type === 'part') {
            this.options.push({
              text: 'Download DOCX - ' + template.name,
              value: 'docx-custom',
              icon: 'fas fa-file',
              template: template,
            });
          }
        });
      });
    },

    // Create Document from Specific Template
    async requestCustomReportDOCX() {
      await this.axiosInstanceFileUpload
        .get(`api/v1/doc-generator/${this.part.part_id}/${this.reportTemplate.uid}/`)
        .then(() => {
          this.$root.notify('success', 'File requested', `Generating report ${this.reportTemplate.name}.`, 3000);
        })
        .catch(error => {
          this.$root.notifyError('Requesting PDF Report', error);
        });
    },

    async fetchCustomReportFile(task_string) {
      if (task_string != '') {
        let task = JSON.parse(task_string);

        this.axiosInstance
          .get(`api/v1/fetch-doc-generated/${task.part_id}/${task.template_uid}/`, {
            responseType: 'blob',
          })
          .then(response => {
            saveAs(response.data, `${task.part_name}__${task.default_file_name}`);
          })
          .catch(error => {
            this.$root.notifyError('Downloading PDF Report', error);
          });
      }
      this.updateFetchCustomReportDOCX('');
    },
  },
};
</script>
