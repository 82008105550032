<template>
  <div>
    <div class="container">
      <div class="flex-container">
        <div class="spacing-container">
          <h5>Infos</h5>
          <IconWithText description="Part Mass" :value="partMass.value + ' g'" :icon="partMassSvg" />
          <IconWithText
            v-if="activeChainIsAM"
            description="Support Mass"
            :value="supMass.value + ' g'"
            :icon="supportMassSvg"
          />
          <IconWithFeedbackValue
            v-if="activeChainIsAM"
            description="Parts per Job"
            :value="activeProcessChain.parts_pj + ''"
            :icon="partsPerJobSvg"
            @feedback="setPartsPerJob($event)"
          />
          <IconWithText
            v-if="activeChainIsAM"
            description="Full Job Build Height"
            :value="$formatZeroDecimalPlaces(activeProcessChain.bld_h) + ' mm'"
            :icon="buildHeightSvg"
          />
          <IconWithText
            v-if="activeChainIsAM"
            description="Mean Build Height"
            :value="$formatZeroDecimalPlaces(activeProcessChain.bld_h_mean) + ' mm'"
            :icon="buildHeightSvg"
          />
        </div>
        <div class="spacing-container">
          <IconWithText
            v-if="activeChainIsAM"
            description="Best Orientation"
            :value="
              $formatZeroDecimalPlaces(activeProcessChain.rot_x_cost) +
              '°, ' +
              $formatZeroDecimalPlaces(activeProcessChain.rot_y_cost) +
              '°, ' +
              $formatZeroDecimalPlaces(activeProcessChain.rot_z_cost) +
              '°'
            "
            :icon="bestOrientationsSvg"
          />
          <IconWithText
            v-if="activeChainIsAM"
            description="Cost Savings by Orientation"
            :value="$formatZeroDecimalPlaces(costSave) + ' %'"
            :icon="costSavingsByOrientationSvg"
          />
          <IconWithText
            v-if="pricePerBoundingBoxVolume && calculationStyle == 'bounding_box' && activeChainIsAM"
            description="Bounding Box Cost"
            :value="
              $formatZeroDecimalPlaces(boundingBoxVolume.value) +
              ' ' +
              boundingBoxVolume.units +
              ' @ ' +
              $formatThreeDecimalPlaces(pricePerBoundingBoxVolume.value) +
              ' ' +
              pricePerBoundingBoxVolume.units
            "
            :icon="boundingBoxCost"
          />
          <IconWithText
            v-if="activeChainIsMilling"
            description="Buy-To-Fly Ratio"
            :value="buyToFlyRatio"
            :icon="buyToFlyRatioSvg"
          />
        </div>
      </div>
      <!-- Geometry Checks-->
      <div v-if="activeProcessChainFeasibilitiesIsLegit === false" class="container">
        No Feasibilities evaluated for this process chain.
      </div>
      <div v-else class="spacing-container" style="flex: 1 1 0px">
        <h5>Geometry Checks</h5>
        <FeasibilityEntry
          name="Fits Machine"
          :show-bar="false"
          :feasibility="activeProcessChainFeasibilities?.fits_mac"
          additional=""
          class="row2-col3"
        />
        <FeasibilityEntry
          name="Fits Process"
          show-bar
          :feasibility="activeProcessChainFeasibilities?.size"
          :additional="additionalDataFitsProcess"
          class="row1-col3"
        />
        <FeasibilityEntry
          name="Wall Thickness"
          show-bar
          :feasibility="activeProcessChainFeasibilities?.thickness"
          additional=""
          class="row1-col2"
        />
        <FeasibilityEntry
          name="Gap Size"
          show-bar
          :feasibility="activeProcessChainFeasibilities?.gap_size"
          additional=""
          class="row2-col2"
        />
        <FeasibilityEntry
          name="Support Removability"
          :show-bar="false"
          :feasibility="activeProcessChainFeasibilities?.support_occluded"
          additional=""
          class="row2-col3"
        />
        <FeasibilityEntry
          name="Sharp Edges"
          show-bar
          :feasibility="activeProcessChainFeasibilities?.radii"
          additional="Are edge radiuses big enough."
          class="row2-col3"
        />
        <FeasibilityEntry
          name="Mean Wall Thickness"
          show-bar
          :feasibility="activeProcessChainFeasibilities?.thickness_mean"
          additional="Is mean wall thickness big enough for casting."
          class="row2-col3"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import IconWithFeedbackValue from '../../../components/IconWithFeedbackValue.vue';
import IconWithText from '../../../components/IconWithText.vue';

import BestOrientations from '@/assets/icons/comparison/best_orientation.svg';
import BoundingBoxCost from '@/assets/icons/comparison/bounding_box.svg';
import BuildHeight from '@/assets/icons/comparison/build_height.svg';
import BuyToFlyRatio from '@/assets/icons/comparison/buy_to_fly.svg';
import CostSavingsByOrientation from '@/assets/icons/comparison/cost_savings_by_orientation.svg';
import PartMass from '@/assets/icons/comparison/part_mass.svg';
import PartsPerJob from '@/assets/icons/comparison/parts_per_job.svg';
import SupportMass from '@/assets/icons/comparison/support_mass.svg';
import FeasibilityEntry from '@/components/PartWorkflow/Feasibility/FeasibilityEntry.vue';

export default {
  name: 'FeasibilityCard',

  components: { FeasibilityEntry, IconWithText, IconWithFeedbackValue },

  computed: {
    ...mapState(['investigationDetails', 'part']),
    ...mapState('application', ['axiosInstance']),

    activeInvestigationDetails() {
      return this.investigationDetails;
    },

    partMassSvg() {
      return PartMass;
    },

    partsPerJobSvg() {
      return PartsPerJob;
    },

    supportMassSvg() {
      return SupportMass;
    },

    buildHeightSvg() {
      return BuildHeight;
    },

    bestOrientationsSvg() {
      return BestOrientations;
    },

    costSavingsByOrientationSvg() {
      return CostSavingsByOrientation;
    },

    boundingBoxCost() {
      return BoundingBoxCost;
    },

    buyToFlyRatioSvg() {
      return BuyToFlyRatio;
    },

    activeProcessChain() {
      let allChains = this.part.process_chains;
      return allChains[this.activeInvestigationDetails.uid];
    },

    activeChainIsAM() {
      return this.activeProcessChain.tech == 'additive_manufacturing';
    },

    activeChainIsMilling() {
      return this.activeProcessChain.tech == 'milling';
    },

    activeChainIsCasting() {
      return this.activeProcessChain.tech == 'casting';
    },

    partMass() {
      let mass = this.activeProcessChain.part_mass;
      return this.roundSmallNumber(mass, 2);
    },

    supMass() {
      let mass = this.activeProcessChain.sup_mass;
      return this.roundSmallNumber(mass, 2);
    },

    costSave() {
      if (this.activeProcessChain.cost_ini_pp) {
        let percentage =
          Math.round(
            ((1 - this.activeProcessChain.cost.cost_pp / this.activeProcessChain.cost_ini_pp) * 100 + Number.EPSILON) *
              100
          ) / 100;
        return percentage > 0 ? percentage : 0;
      } else {
        return null;
      }
    },

    boundingBoxVolume() {
      return {
        value: (this.part.bb_x * this.part.bb_y * this.part.bb_z) / 1000,
        units: 'cm³',
      };
    },

    pricePerBoundingBoxVolume() {
      return {
        value: this.activeProcessChain?.price_per_bounding_box_volume,
        units: '€/cm³',
      };
    },

    buyToFlyRatio() {
      return this.activeProcessChain.info['Buy-To-Fly Ratio'] || null;
    },

    calculationStyle() {
      return this.activeProcessChain?.calc_style;
    },

    activeProcessChainFeasibilities() {
      return this.activeProcessChain?.feasibility;
    },

    activeProcessChainFeasibilitiesIsLegit() {
      return Object.keys(this.activeProcessChainFeasibilities).length > 0;
    },

    additionalDataFitsProcess() {
      return 'Minimum Scaled Bounding Box compared to Limits of ' + this.activeProcessChain.prc + ' Process';
    },
  },

  methods: {
    ...mapMutations(['updatePartsPj']),
    roundSmallNumber(number, digits) {
      if (number != undefined && number < 1 && number != 0) {
        return { value: number.toFixed(digits) };
      } else {
        return { value: this.$formatOneDecimalPlace(number) };
      }
    },

    setPartsPerJob(event) {
      this.updatePartsPj({ uid: this.activeInvestigationDetails.uid, value: event });

      let formData = {
        parts_pj: event,
      };
      this.axiosInstance.put(`/api/v1/process-chain-feedback/${this.activeProcessChain.process_chain_id}/`, formData);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: row;
  font-size: var(--12px);
}

.grid-container {
  grid-template-areas: 'row12-col1 row1-col2 row1-col3' 'row12-col1 row2-col2 row2-col3';
  grid-template-columns: 1fr 2fr 2fr;
  grid-template-rows: auto;
}

.spacing-container {
  margin-left: 1px;
}

.row12-col1 {
  grid-area: row12-col1;
}

.row1-col2 {
  margin-top: 25px;
  grid-area: row1-col2;
}

.row1-col3 {
  margin-top: 25px;
  grid-area: row1-col3;
}

.row2-col2 {
  margin-top: 25px;
  grid-area: row2-col2;
}

.row2-col3 {
  padding-top: 15px;
  grid-area: row2-col3;
}
</style>
