<template>
  <div ref="dropdownComponent" class="btn-group-menu">
    <div class="hover:cursor-pointer" @click="toggleMenu">
      <li
        v-if="title == undefined && titleIcon"
        :class="titleClass == null ? 'dropdown-toggle-menu' : titleClass"
        :style="titleStyle"
      >
        <i :class="titleIcon" />
      </li>
      <li v-if="titleImage">
        <img :src="titleImage" alt="User Profile Picture" :style="titleStyle" />
      </li>

      <li
        v-if="title != undefined && !titleIcon"
        :class="titleClass == null ? 'dropdown-toggle-menu' : titleClass"
        :style="titleStyle"
      >
        {{ title }}
        <i v-if="showCaret" class="fas fa-caret-down" />
      </li>
      <li v-if="custom" :class="customClass">
        <slot />
      </li>
    </div>

    <div v-if="showMenu && listOptions" class="dropdown-container">
      <ul>
        <li
          v-for="(option, index) in listOptions"
          :key="index"
          :class="option.disabled ? 'disabled' : ''"
          class="list"
          @click="updateOption(option)"
        >
          <hr v-if="option.border" class="p-4" />
          <span v-if="option.value === 'version'" class="list-item">
            <i v-if="option.icon" :class="option.icon" />
            <div v-text="option.text" />
          </span>
          <span v-else class="list-item">
            <i v-if="option.icon" :class="option.icon" />
            <div v-text="option.text" />
          </span>
        </li>
      </ul>
    </div>

    <div v-if="showMenu && elementOptions && notifications" class="dropdown-menu-items overflow-x-hidden">
      <DropdownMenuNotifications :element-options="elementOptions" @last-element-deleted="toggleMenu" />
    </div>

    <div v-if="showMenu && elementOptions && canvasactions" class="dropdown-menu-items overflow-x-hidden">
      <DropdownMenuCanvasActions :element-options="elementOptions" @clicked-value="updateOption($event)" />
    </div>
  </div>
</template>

<script>
// USAGE IN TEMPLATE
// <DropdownMenu
//         :listOptions="partStats"
//         :closeOnOutsideClick="true"
//         :title="part.part_stat"
//         :titleStyle="{ color: statusColor }"
//         @updateOption="show($event)"
// />

import DropdownMenuCanvasActions from './DropdownMenuCanvasActions.vue';
import DropdownMenuNotifications from './DropdownMenuNotifications.vue';

export default {
  name: 'DropdownMenu',

  components: { DropdownMenuNotifications, DropdownMenuCanvasActions },

  props: {
    title: { type: String, default: undefined },
    titleIcon: { type: String, default: undefined },
    titleImage: {
      default: null,
      type: String,
    },

    custom: { type: Boolean },

    closeClickStyle: { type: String, default: undefined },
    listOptions: {
      type: Array,
      default() {
        return [];
      },
    },

    elementOptions: {
      type: Object,
      default() {
        return {};
      },
    },

    titleClass: { type: String, default: null },
    customClass: { type: String, default: null },
    titleStyle: {
      type: Object,
      default() {
        return {};
      },
    },

    showCaret: Boolean,

    notifications: { type: Boolean },
    canvasactions: { type: Boolean },
  },

  emits: ['update-option'],

  data() {
    return {
      showMenu: false,
    };
  },

  methods: {
    updateOption(option) {
      if (option.disabled) return;
      if (option.value === 'version') return;
      this.showMenu = false;
      this.$emit('update-option', option);
    },

    toggleMenu() {
      this.showMenu = !this.showMenu;
      if (this.showMenu) {
        document.addEventListener('click', this.clickHandler, false);
      } else {
        document.removeEventListener('click', this.clickHandler, false);
      }
    },

    clickHandler(event) {
      if (event == null) return;

      const { target } = event;
      const { $el } = this;

      if (!$el.contains(target)) {
        if (this.closeClickStyle === 'always') {
          this.showMenu = false;
        } else if (
          !this.$refs.dropdownComponent.contains(target) &&
          this.closeClickStyle === 'outside' &&
          !target.classList.contains('do-not-close-dropdown-on-click')
        ) {
          // if an element is deleted - do-not-close-dropdown-on-click - it is not part of the component any more and would lead to a dropdown close.
          this.showMenu = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-group-menu {
  width: fit-content;
  height: fit-content;
  position: relative;
  margin: 0px 0px;
  display: flex;
  vertical-align: middle;
}

.dropdown-container {
  min-width: 200px;
  border-radius: 4px;
  position: absolute;
  right: 0;
  top: 25px;
  z-index: 10000;
  width: max-content;
  transform-origin: top right;
  background-color: #35373b !important;
  color: #fff !important;
  overflow: hidden;
  cursor: pointer;
}

.list {
  width: 100%;
  overflow: hidden;
  padding: 10px;
  &:hover {
    background-color: #4e5358;
  }
}

.list-item {
  font-size: 13px;
  display: flex;
  color: #fff;
  align-items: center;
  gap: 8px;
  color: #fff;
  overflow: hidden;
}

.dropdown-toggle-menu {
  color: #636b6f;
  min-width: 30px;
  padding: 10px;
  text-transform: none;
  font-size: var(--12px);
  border: 0;
  float: none;
  box-shadow: none;
  border-radius: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.dropdown-toggle-menu:hover {
  cursor: pointer;
}
.dropdown-menu-items {
  position: absolute;
  top: 100%;
  overflow-y: scroll;
  right: 0;
  z-index: 1000;
  float: left;
  width: 200px;
  padding: 2px 0;
  list-style: none;
  font-size: var(--11px);
  text-align: left;
  background-color: #fff;

  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.dropdown-menu-items > li > a {
  padding: 10px;
  display: block;
  clear: both;
  font-weight: normal;
  line-height: 1.6;
  color: #fff !important;
  white-space: nowrap;
  text-decoration: none;
}

.dropdown-menu-items > li {
  overflow: hidden;
  width: 100%;
  position: relative;
  margin: 0;

  & :hover {
    background-color: #4e5358;
  }
}

li {
  list-style: none;
}

ol,
ul {
  margin: 0 !important;
}

ul {
  padding: 0 !important;
}
</style>
