<template>
  <div class="relative" :class="{ [custom]: custom }">
    <label
      v-if="!!label || $slots.label"
      class="text-13 text-gray-500 z-10"
      :class="{
        'absolute z-10 text-13 px-2 text-gray-500 ml-8 bg-white duration-300 transform origin-[0] -top-8 start-1':
          animateLabel,
      }"
      :for="uid"
    >
      <slot name="label">
        {{ label }}
      </slot>
    </label>
    <div class="relative">
      <Field
        :id="uid"
        v-model="inputField"
        class="block border h-40 focus:!h-40 hover:!border-gray-300 px-12 pb-2 pt-4 w-full text-15 text-gray-900 bg-white rounded-4 border-1 border-gray-200 focus:outline-none focus:ring-0 focus:border-primary-600 peer"
        :class="{
          '!border-gray-200  !bg-gray-50 !text-gray-300': disabled,
          '!text-gray-300 ': inputField === '' && !!placeholder,
          '!border-red-500': !!hasErrors,
        }"
        :disabled="disabled"
        :name="name"
        :placeholder="placeholder"
        as="select"
        :autocomplete="autocomplete"
      >
        <option v-if="placeholder" value="" disabled v-text="placeholder" />
        <option v-for="(option, i) in items" :key="i" :value="option.value" v-text="option.label" />
      </Field>
    </div>
    <span v-if="error" class="text-11 absolute left-0 top-full pt-4 text-red-500" v-text="error" />
  </div>
</template>

<script>
import { Field } from 'vee-validate';
import { computed, ref, watch } from 'vue';

export default {
  name: 'SparkSelect',

  components: { Field },

  props: {
    autocomplete: { type: HTMLInputElement['autocomplete'], default: 'off' },
    disabled: { type: Boolean },
    label: { type: String, default: '' },
    animateLabel: { type: Boolean },
    name: { type: String, required: true },
    placeholder: { type: String, default: '' },
    options: { type: Array, required: true },
    custom: { type: String, default: '' },
    modelValue: { type: [Boolean, Number, String, Object, null], default: '' },
    error: { type: String, default: '' },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const inputField = computed({
      get: () => props.modelValue,
      set: value => emit('update:modelValue', value),
    });

    const uid = computed(() => `input-${Math.random().toString(36).substring(2, 9)}`);

    const hasErrors = ref(false);
    watch(
      () => props.error,
      () => {
        hasErrors.value = props.error ? true : false;
      },
      { immediate: true }
    );

    const items = computed(() => {
      return props.options.map(item => {
        if (typeof item === 'object') return item;
        return { label: item, value: item };
      });
    });

    return { inputField, items, uid, hasErrors };
  },
};
</script>
