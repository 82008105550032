<template>
  <div class="order-summary border border-gray-100 rounded-8 bg-white">
    <div
      v-for="(option, index) in costItems"
      :key="index"
      class="flex justify-end gap-100 border-b border-gray-100 last-of-type:border-0"
      :class="{
        'text-19 font-semibold': costItems.length - 1 === index || index === 0,
      }"
    >
      <div class="py-12" v-text="option.name" />
      <div class="text-primary-500 py-12 px-24" v-text="`${option.value}`" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'OrderSummary',

  props: {
    costItems: { type: Array, default: () => [] },
  },

  computed: {
    ...mapState(['user']),
  },
};
</script>
