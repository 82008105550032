import axios from 'axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Keycloak from 'keycloak-js';
import { TroisJSVuePlugin } from 'troisjs';
import { createApp } from 'vue';
import VueMatomo from 'vue-matomo';
import VueApexCharts from 'vue3-apexcharts';

import App from './App.vue';
import Styling from './components/General/Styling.vue';
import StylingColors from './components/General/StylingColors.vue';
import ClickOutsideDirective from './directives/clickOutsideDirective';
import ResizableTableDirective from './directives/resizableTable';
import installComposables from './plugins/installComposables';
import user from './plugins/user';
import router from './router';
import store from './store';
import '../public/assets/fontawesome/css/fontawesome.css'
import '../public/assets/fontawesome/css/regular.css'
import '../public/assets/fontawesome/css/solid.css'

const initOptions = {
  // Keycloak Init-Options
  url: import.meta.env.VITE_KEYCLOAK_URL,
  realm: import.meta.env.VITE_KEYCLOAK_REALM,
  clientId: import.meta.env.VITE_KEYCLOAK_CLIENTID,
  silentCheckSsoFallback: true,
  checkLoginIframe: true,
  silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso',
  onLoad: 'check-sso', // 'login-required' to protect everything always
};

const setAuthState = () => {
  store.commit("setAuthenticated", keycloak.authenticated);
};

const performLogin = destination => {
  const getUrl = window.location;
  let redirect = getUrl.protocol + '//' + getUrl.host;
  if (destination != '/') {
    redirect = redirect + '?r=' + destination;
  }
  let domainParts = getUrl.hostname.split('.');
  let subdomain = domainParts[0] === 'www' ? domainParts[1] : domainParts[0]; // Make sure that the subdomain's name is the same as the idpHint/alias in Keycloak. If idpHint is not set, Keycloak will use the default login page.
  keycloak.login({ redirectUri: redirect, idpHint: subdomain });
};

// Check for Role-Membership. Roles have to be explicit. That means: sparkmanager can't view a page if requiredRole does only list sparkuser.
const checkForRolemembership = to => {
  let requiredRoles = to.meta.requiredRole;

  // Do not forward if user is no staff member, but staff status is required
  if (to.meta.requireStaff == true && store.state.user.is_staff == false) {
    return false;
  }

  // Do not forward if user is external, but internal status is required
  if (to.meta.requireInternal == true && store.state.user.is_external == true) {
    return false;
  }

  if (requiredRoles == undefined) {
    // this will allow the page to render if no requiredRoles were specified in the router. User still has to be authenticated.
    return true;
  }
  for (var i = 0; i < requiredRoles.length; i++) {
    if (keycloak.hasRealmRole(requiredRoles[i])) {
      return true;
    }
  }
  return false;
};

// Check which route to take.
const sendToRoute = (to, next) => {
  next();
};

const keycloak = new Keycloak(initOptions);

keycloak
  .init({ onLoad: initOptions.onLoad })
  .then(() => {
    // Axios config
    axios.defaults.baseURL = !import.meta.env.PROD ? "http://localhost" : document.location.origin;
    axios.defaults.withCredentials = true;
    const app = createApp(App);
    app.use(store);
    keycloak.onAuthSuccess = setAuthState();
    keycloak.onAuthRefreshError = function () {
      store.commit('removeTokens');
    };

    // Make sure user is logged in, if required (e.g. user-account)
    router.beforeEach((to, from, next) => {
      // Set title
      const DEFAULT_TITLE = '3D Spark';
      document.title = to.meta.title || DEFAULT_TITLE;
      if (to.matched.some(record => record.meta.requireLogin)) {
        if (!keycloak.authenticated) {
          // if user is not logged in
          setAuthState();
          if (keycloak.refreshToken == undefined) {
            performLogin(window.location.pathname);
          }
        } else if (keycloak.hasRealmRole('alstom-redirect') && window.location.host == 'platform.3dspark.de') {
          window.location.replace('https://designtoprint-alstom.3dspark.de');
        } else if (
          keycloak.hasRealmRole('unauthorized') &&
          !(keycloak.hasRealmRole('external') || keycloak.hasRealmRole('external_sso_provider'))
        ) {
          if (window.location.pathname != '/new-user') {
            router.push('/new-user');
          }
        } else if (keycloak.hasRealmRole('unlicensed')) {
          if (window.location.pathname != '/unlicensed') {
            router.push('/unlicensed');
          }
        } else if (checkForRolemembership(to)) {
          setAuthState();
          // The user was authenticated, and has the app role
          keycloak
            .updateToken(import.meta.env.VITE_TIME_BEFORE_TOKEN_REFRESH)
            .then(function (refreshed) {
              if (refreshed) {
                setAuthState();
              }
              sendToRoute(to, next);
            })
            .catch(err => {
              console.error(err);
              // If the token is undefined, it might be expired
              if (keycloak.token == undefined) {
                keycloak.clearToken();
                router.push('/login');
              } else {
                keycloak.clearToken();
                router.push('/something-went-wrong');
              }
            });
        } else {
          // push to unauthorized
          router.push('/401');
        }
      } else {
        // This page did not require authentication
        next();
      }
    });

    app.config.globalProperties.$keycloak = keycloak;

    app.use(user);

    app.config.globalProperties.$dayjs = dayjs;

    dayjs.extend(utc);

    app.use(router, axios);
    app.use(TroisJSVuePlugin);
    app.use(VueApexCharts);
    app.component('Styling', Styling);
    app.component('StylingColors', StylingColors);

    app.use(VueMatomo, {
      host: 'https://matomo.3dspark.de/',
      siteId: 1,
      trackerFileName: 'matomo',
      router: router,
      enableLinkTracking: true,
      requireConsent: false,
      trackInitialView: true,
      disableCookies: false,
      requireCookieConsent: false,
      enableHeartBeatTimer: false,
      heartBeatTimerInterval: 15,
      debug: false,
      userId: undefined,
      cookieDomain: undefined,
      domains: undefined,
      preInitActions: [],
      trackSiteSearch: false,
      crossOrigin: undefined,
    });

    app.use(VueMatomo, {
      host: 'https://matomo.3dspark.de/',
      siteId: 7,
      trackerFileName: 'matomo',
      router: router,
      enableLinkTracking: true,
      requireConsent: false,
      trackInitialView: true,
      disableCookies: false,
      requireCookieConsent: false,
      enableHeartBeatTimer: false,
      heartBeatTimerInterval: 15,
      debug: false,
      userId: undefined,
      cookieDomain: undefined,
      domains: undefined,
      preInitActions: [],
      trackSiteSearch: false,
      crossOrigin: undefined,
    });

    // Set Color scheme globally, same as in assets/css/coloe_scheme -> chart palette
    Apex.colors = ['#035e7b', '#007385', '#00867e', '#009766', '#50a443', '#97ab13'];

    app.use(installComposables);

    app.use(ClickOutsideDirective);
    app.use(ResizableTableDirective);
    app.mount('#app');
  })
  .catch(e => {
    console.log('Authentication Failed');
    console.log(e);
    keycloak.logout( { redirectUri: window.location.origin + '/login' } );
  });
