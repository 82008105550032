<template>
  <div style="margin: 10px">
    <div
      class="spark-new-input-group"
      title="The active Pricing Settings for the analysis. Pricing Settings can be created by the manager of your organization via the organizational settings."
    >
      <select 
        v-model="selectedPricingOption"
        required
        class="spark-new-select-input"
        :disabled="disabledSetting"
        :title="title"
      >
        <option v-for="(option, key) in pricingOptionList" :key="key" :value="key">
          {{ option.name }}
        </option>
      </select>
      <label class="spark-new-select-label">Price Configuration</label>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
export default {
  name: 'PricingConfigSettings',

  props: {
    chain: Object,
    disabledSetting: Boolean,
    title: String,
  },

  data() {
    return {
      pricingOptionList: [],
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),

    selectedPricingOption: {
      get() {
        return this.chain?.pricing_config_id;
      },

      set(value) {
        let formData = { pricing_config_id: value };
        this.axiosInstance.put(`api/v1/process-chain/${this.chain.process_chain_id}/`, formData).then(response => {
          this.updateSingleProcessChain(response.data);
        });
      },
    },
  },

  async mounted() {
    await this.getPricingOptionList();
  },

  methods: {
    ...mapMutations(['updateSingleProcessChain']),

    async getPricingOptionList() {
      await this.axiosInstance.get(`api/v1/pricing-config-list/`).then(response => {
        const data = this.convertData(response.data);
        this.pricingOptionList = data ?? [];
        this.selectedPricingOption = this.chain.pricing_config_id;
      });
    },

    convertData(data) {
      let convertedData = {};
      data.forEach(item => {
        convertedData[item.uid] = { name: item.name, organization: item.organization };
      });
      return convertedData;
    },
  },
};
</script>

<style lang="scss" scoped>
.spark-table {
  width: 100%;
}
</style>
