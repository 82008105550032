<template>
  <div v-if="show">
    <div class="text-primary-500 whitespace-nowrap"><i class="fas fa-arrow-right pr-8" />Details</div>
  </div>
  <div v-else>
    <!-- <div class="text-white whitespace-nowrap"><i class="fas fa-arrow-right pr-8" />Details</div> -->
  </div>
</template>

<script>
export default {
  name: 'OpenDetails',

  props: {
    show: { type: Boolean },
  },
};
</script>
