<template>
  <div>
    <h4>Category Settings</h4>
    <ComboTextCategories />
  </div>
</template>

<script>
import ComboTextCategories from './ComboTextCategories.vue';


export default {
  name: 'Categories',

  components: {
    ComboTextCategories,
  },
};
</script>
