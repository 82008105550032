<template>
  <div>
    <SparkForm>
      <SparkInput
        v-model="form.name"
        class="mb-16 ml-4"
        name="name"
        label="Name *"
        :error="errors['name']"
        @input="validateField('name')"
      />

      <SparkSelect
        v-if="userIsPowerUserOrUser"
        v-model="powerUserRestriction"
        class="mb-16 ml-4 pt-8"
        disabled
        :options="restrictionOwner"
        name="restriction"
        label="Restriction"
      />

      <SparkSelect
        v-else
        v-model="form.restriction"
        class="mb-16 ml-4 pt-8"
        :options="restrictions"
        name="restriction"
        label="Restriction"
      />
      <SparkSelect
        v-model="form.prc_acronym"
        class="mb-16 ml-4"
        :options="processes"
        name="prc_acronym"
        label="Processes *"
        :error="errors['prc_acronym']"
        @change="validateField('prc_acronym')"
      />
      <SparkSelect
        v-model="form.step_type"
        class="mb-16 ml-4"
        :options="stepTypes"
        name="step_type"
        label="Step Type *"
        :error="errors['step_type']"
        @change="validateField('step_type')"
      />
      <SparkSelect
        v-model="form.calc_lvl"
        class="mb-16 ml-4"
        :options="calculationLvls"
        name="calc_lvl"
        label="Calculation Level *"
        :error="errors['calc_lvl']"
        @change="validateField('calc_lvl')"
      />

      <div
        v-if="form.calc_lvl === 'expression'"
        class="mb-16 ml-16"
        title="Per part cost expression may include 'part_volume' [cm³], 'part_surface' [cm²] or support_surface [cm²]"
      >
        <hr class="pb-8" />
        <SparkInput
          v-model="form.calc_expression"
          placeholder="0.52 * part_volume"
          name="expression"
          label="Expression"
        />

        <p class="text-13">Possible Variables</p>
        <p class="text-9">Click to add variable</p>
        <div v-for="(unit, expression) in calcExpressions" :key="expression" class="flex justify-between">
          <div
            class="border border-gray-100 rounded-4 p-1 hover:cursor-pointer text-9 mb-1 hover:text-primary-500"
            @click="appendToCalcExpression(expression)"
          >
            {{ expression }}
          </div>
          <div class="text-11">in {{ unit }}</div>
        </div>

        <hr class="pb-8" />
      </div>

      <div class="text-13 text-gray-500 ml-4 flex w-full items-center">
        <label for="setup_time" class="w-200">Setup Time (hh:mm:ss)</label>
        <FeedbackValueTimespan
          editable
          :feedback-value="form.setup_time"
          @feedback-value-changed="setTime('setup_time', $event)"
        />
      </div>
      <div class="text-13 text-gray-500 ml-4 flex w-full items-center">
        <label for="process_time" class="w-200">Process Time (hh:mm:ss)</label>
        <FeedbackValueTimespan
          editable
          :feedback-value="form.process_time"
          @feedback-value-changed="setTime('process_time', $event)"
        />
      </div>
      <div class="text-13 text-gray-500 ml-4 flex w-full items-center">
        <label for="unloading_time" class="w-200">Unload Time (hh:mm:ss)</label>
        <FeedbackValueTimespan
          editable
          :feedback-value="form.unloading_time"
          @feedback-value-changed="setTime('unloading_time', $event)"
        />
      </div>

      <SparkInput v-model="form.fixed_cost" class="ml-4 mb-20" name="fixed_cost" type="number" label="Fixed Cost (€)" />
      <SparkInput
        v-model="form.machine_hourly_rate"
        name="machine_hourly_rate"
        type="number"
        label="Machine Hourly Rate (€/ h) *"
        class="mb-20 ml-4"
        :error="errors['machine_hourly_rate']"
        @input="validateField('machine_hourly_rate')"
      />

      <SparkInput
        v-model="form.staff_time_factor_percent"
        name="staff_time_factor"
        type="number"
        :error="errors['staff_time_factor']"
        label="Staff Time Factor (%)*"
        class="mb-20 ml-4 pt-8"
        @input="validateField('staff_time_factor')"
      />

      <SparkSelect
        v-model="form.worker_type"
        class="mb-20 ml-4 pt-8"
        :options="workerTypeOptions"
        name="worker_type"
        label="Worker Type *"
        :error="errors['worker_type']"
        @change="validateField('worker_type')"
      />

      <SparkSelect
        v-model="form.obligatory"
        class="mb-20 ml-4"
        :options="obligatoryOptions"
        name="obligatory"
        label="Required *"
        :error="errors['obligatory']"
        @change="validateField('obligatory')"
      />

      <SparkButton class="mt-8" @click="validateFields">Save</SparkButton>
    </SparkForm>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { z } from 'zod';

import FeedbackValueTimespan from '../../../../components/Reusable/FeedbackValues/FeedbackValueTimespan.vue';
import SparkButton from '../../../../components/SparkComponents/SparkButton.vue';
import SparkForm from '../../../../components/SparkComponents/SparkForm.vue';
import SparkInput from '../../../../components/SparkComponents/SparkInput.vue';
import SparkSelect from '../../../../components/SparkComponents/SparkSelect.vue';

export default {
  name: 'CreateNewProcessChainStep',

  components: {
    FeedbackValueTimespan,
    SparkInput,
    SparkSelect,
    SparkButton,
    SparkForm,
  },

  props: {
    filledForm: {
      type: Object,
      default() {
        return {};
      },
    },

    emptyForm: Boolean,

    mode: { type: String, default: '' },
  },

  emits: ['created'],

  data() {
    return {
      restrictions: [
        { value: 'owner', label: 'Owner' },
        { value: 'organization', label: 'Organization' },
      ],

      restrictionOwner: [{ value: 'owner', label: 'Owner' }],

      powerUserRestriction: 'owner',

      stepTypes: [
        { value: 'pre', label: 'Pre' },
        { value: 'post', label: 'Post' },
      ],

      obligatoryOptions: [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
      ],

      form: {
        name: '',
        process_time: 0,
        unloading_time: 0,
        setup_time: 0,
        obligatory: true,
      },

      errors: {},

      calcExpressions: { part_volume: 'cm³', part_surface: 'cm²', support_surface: 'cm²' },
    };
  },

  computed: {
    ...mapState(['workerTypes', 'calcLvls', 'user']),
    ...mapState('application', ['axiosInstance']),
    ...mapState({
      storeProcesses: 'processes',
    }),

    schema() {
      return z.object({
        name: z.string().min(1, 'Required'),
        prc_acronym: z.string().min(1, 'Required'),
        step_type: z.string().min(1, 'Required'),
        calc_lvl: z.string().min(2, 'Required'),
        machine_hourly_rate: z.number().min(0, 'Minimum value is 0').max(100000, 'Maximal value is 100000'),
        staff_time_factor_percent: z.number().min(0, 'Minimum value is 0').max(100, 'Maximal value is 100'),
        worker_type: z.string().min(1, 'Required'),
        obligatory: z.boolean(),
      });
    },

    userIsPowerUserOrUser() {
      return this.user.user_role === 'poweruser' || this.user.user_role === 'user';
    },

    processes() {
      let arr = [];
      Object.keys(this.storeProcesses).forEach(key => {
        let value = this.storeProcesses[key].value;
        if (value === null) {
          value = 'all_processes';
        }
        arr.push({ label: this.storeProcesses[key].text, value: value });
      });
      arr = this.orderedProcesses(arr);
      return arr;
    },

    calculationLvls() {
      let arr = [];
      Object.keys(this.calcLvls).forEach(key => {
        arr.push({ label: this.calcLvls[key].text, value: this.calcLvls[key].value });
      });
      return arr;
    },

    workerTypeOptions() {
      let arr = [];
      Object.keys(this.workerTypes).forEach(key => {
        arr.push({ label: this.workerTypes[key].text, value: this.workerTypes[key].value });
      });
      return arr;
    },
  },

  mounted() {
    if (this.mode == 'Edit') {
      this.form = this.filledForm;
    }
  },

  methods: {
    orderedProcesses(processesArray) {
      let sortedEntries = [];
      if (processesArray != undefined) {
        sortedEntries = processesArray.sort((a, b) => {
          const name1 = a.label.toLowerCase();
          const name2 = b.label.toLowerCase();
          return name1.localeCompare(name2);
        });
      }
      return sortedEntries;
    },

    appendToCalcExpression(expression) {
      if (!this.form.calc_expression) {
        this.form.calc_expression = '';
      }
      this.form.calc_expression = this.form.calc_expression + ' ' + expression;
    },

    action() {
      let times = ['unloading_time', 'setup_time', 'process_time'];
      times.forEach(time => {
        if (this.form[time] === '00:00') {
          this.form[time] = 0;
        }
      });

      if (this.form['prc_acronym'] === 'all_processes') {
        this.form['prc_acronym'] = null;
      }

      this.form.staff_time_factor = this.form.staff_time_factor / 100;

      if (this.mode === 'Create') {
        this.axiosInstance.post('/api/v1/process-step-template/', this.form).then(() => {
          this.$emit('created');
          this.$root.notify('success', 'Process step created', '', 10000);
        });
      } else if (this.mode === 'Edit') {
        this.axiosInstance.put(`/api/v1/process-step-template/${this.form.uid}/`, this.form).then(() => {
          this.$emit('created');
          this.$root.notify('success', 'Process step edited', '', 10000);
        });
      }
    },

    validateFields() {
      const result = this.schema.safeParse(this.form);
      const errors = result.error?.issues;
      if (!errors) {
        this.action();
        return;
      }

      this.errors = errors.reduce((acc, issue) => {
        acc[issue.path[0]] = issue.message;
        return acc;
      }, {});
    },

    validateField(field) {
      const result = this.schema.safeParse(this.form);
      const errors = result.error?.issues;
      if (errors) {
        this.errors[field] = errors.find(issue => issue?.path[0] === field)?.message || '';
      } else {
        this.errors[field] = '';
      }
    },

    setTime(str, value) {
      this.form[str] = value;
    },
  },
};
</script>
