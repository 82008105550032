<template>
  <div>
    <div class="bg-white flex flex-col border border-gray-50 rounded-8 mb-20 p-8">
      <SparkButton class="w-200 mr-0 ml-auto" variant="secondary" @click="openModal()">
        <div v-text="'Create Supplier'"
      /></SparkButton>
    </div>

    <div class="bg-white flex flex-col border border-gray-50 rounded-8">
      <SupplierList />
    </div>

    <CreateSupplierModal :show="showModal" @close="closeModal()" />
  </div>
</template>

<script>
import SparkButton from '../../../../components/SparkComponents/SparkButton.vue';

import CreateSupplierModal from './components/CreateSupplierModal.vue';
import SupplierList from './components/SupplierList.vue';
export default {
  name: 'SupplierSettings',

  components: { SupplierList, CreateSupplierModal, SparkButton },

  data() {
    return {
      showModal: false,
    };
  },

  methods: {
    openModal() {
      this.showModal = true;
    },

    closeModal() {
      this.showModal = false;
    },
  },
};
</script>
