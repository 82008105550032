<template>
  <div class="w-full grid gap-1 sm:grid-cols-2">
    <div class="card box11">
      <TemplateDescription @changed-description="reloadTemplates += 1" />
    </div>
    <div class="card">
      <CustomPrpInfo />
    </div>
    <div class="card col-span-2">
      <ExternalProfile :reload-templates="reloadTemplates" />
    </div>

    <div class="card">
      <FootnotesCheckoutInfo />
    </div>
    <div class="card">
      <PRPCategories />
    </div>
    <div class="card">
      <FeatureVisibility />
    </div>
  </div>
</template>

<script>
import CustomPrpInfo from './CustomPrpInfo.vue';
import ExternalProfile from './ExternalProfile.vue';
import FeatureVisibility from './FeatureVisibility.vue';
import FootnotesCheckoutInfo from './FootnotesCheckoutInfo.vue';
import PRPCategories from './PRPCategories.vue';
import TemplateDescription from './TemplateDescription.vue';

export default {
  name: 'PRPSettings',

  components: {
    ExternalProfile,
    FeatureVisibility,
    TemplateDescription,
    FootnotesCheckoutInfo,
    CustomPrpInfo,
    PRPCategories,
  },

  data() {
    return {
      reloadTemplates: 0,
    };
  },
};
</script>
