<template>
  <div>
    <div class="chart-container">
      <h5 class="margin-30">Active Users Chart</h5>
      <div v-if="loading" class="loading-container">
        <Loading />
      </div>
      <div v-else>
        <apexchart ref="shortChart" type="line" :options="chartOptions" :series="series" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Loading from '@/components/Reusable/Loading.vue';

export default {
  name: 'ActiveUsers',
  components: { Loading },
  props: { organizations: Array },
  data: function () {
    return {
      loading: true,
      sortedData: {},
      colors: [],
      series: [],
      selectedOrganizations: [],
    };
  },
  computed: {
    ...mapState('application', ['axiosInstance']),
    chartOptions() {
      return {
        chart: {
          height: 350,
          type: 'line',
          fontFamily: 'Poppins, Arial, sans-serif',
          toolbar: {
            show: true,
            tools: {
              download: '<i class="fas fa-download"></i>',
            },
          },
        },
        subtitle: {
          text: 'An active user is a user that has uploaded a part within a given month.',
          align: 'left',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '10px',
            color: '#263238',
          },
        },
        colors: this.colors,
        dataLabels: {
          enabled: true,
        },
        stroke: {
          width: [3, 3, 3, 3],
          curve: 'smooth',
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: this.months,
        },
        yaxis: {
          title: {
            text: 'Users',
          },
          labels: {
            formatter: function (val) {
              return parseFloat(val).toFixed(0);
            },
          },
        },
        legend: {
          show: true,
          position: 'top',
        },
        tooltip: {
          enabled: true,
          shared:true,
          y: {
            formatter: function (val, { seriesIndex, dataPointIndex, w }) {
              if (val > 0) { // Check if there are active users
                let seriesData = w.config.series[seriesIndex];
                let orgName = seriesData.name;
                let usernames = seriesData.usernames && seriesData.usernames[dataPointIndex] ? seriesData.usernames[dataPointIndex] : '';
                return `${val} Active User${val > 1 ? 's' : ''} ${usernames ? `(${usernames})` : ''}`;
              }
              return null;
            },
          },
        },
        };
      },
    months() {
      if (this.sortedData && Object.keys(this.sortedData).length > 0) {
        let sortedMonths = Object.keys(this.sortedData).sort((a, b) => new Date(a) - new Date(b));
        return sortedMonths.map(month => {
          let [year, monthNum] = month.split('-');
          return `${monthNum.length === 1 ? '0' + monthNum : monthNum}/${year}`;
        });
      }
      return [];
    },
  },
  watch: {
    organizations: {
      handler(newVal) {
        this.selectedOrganizations = newVal;
        this.getData();
      },
      immediate: true,
    },
  },
  methods: {
    reset() {
      this.series = [];
      this.orgData = {};
    },
    async getData() {
      this.reset();
      let params = {};
      if (this.selectedOrganizations.length > 0) {
        params.org_ids = this.selectedOrganizations.map(org => org.uid).join(',');
      }

      this.axiosInstance
        .get('/api/v1/user-activity/active-users/', { params })
        .then(response => {
          const responseData = response.data;
          this.sortedData = responseData.activity_report;
          let totalActiveUsersData = responseData.total_active_users_per_month;
          let orgSeriesData = {}; 
          let allMonths = Object.keys(this.sortedData).sort(); 

          // Initialize series data for each organization
          Object.values(this.sortedData).forEach(monthData => {
            Object.keys(monthData).forEach(orgName => {
              if (!orgSeriesData[orgName]) {
                orgSeriesData[orgName] = {
                  data: Array(allMonths.length).fill(0),
                  usernames: Array(allMonths.length).fill('')
                };
              }
            });
          });

          // Populate orgSeriesData with actual values
          allMonths.forEach((month, index) => {
            Object.entries(this.sortedData[month]).forEach(([orgName, data]) => {
              orgSeriesData[orgName].data[index] = data.total_active_users;
              orgSeriesData[orgName].usernames[index] = data.active_users.join(', ');
            });
          });

          // Prepare the series for each organization
          this.series = Object.entries(orgSeriesData).map(([orgName, data], i) => {
            return {
              name: orgName,
              data: data.data,
              usernames: data.usernames,
              color: this.getOrgColor(i)
            };
          });

          // Prepare the total active users series
          let totalActiveUsersSeries = {
            name: 'Total Active Users',
            data: allMonths.map(month => totalActiveUsersData[month] || 0),
            color: this.getOrgColor(-1)
          };

          this.series.push(totalActiveUsersSeries);
          this.$emit('update-active-users-series', totalActiveUsersSeries);


          this.loading = false;
        });
    },
    getOrgColor(i) {
      return this.getRandomColor();
    },
    getRandomColor() {
      return `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(
        Math.random() * 256
      )})`;
    },
  },
};
</script>

<style lang="scss" scoped>
.margin-30 {
  margin-top: 30px;
}
.chart-container {
  position: relative;
}
.loading-container {
  position: relative;
  top: -100px;
  left: 0;
  right: 0;
  align-self: start;
}
</style>
