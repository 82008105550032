<template>
  <div v-if="user.email" class="m-8 rounded-4 border border-solid border-gray-50 relative">
    <div class="bg-white">
      <router-view />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

store.dispatch('fetchBatches');

const user = computed(() => store.state.user);
</script>
