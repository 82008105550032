<template>
  <div>
    <h4>Cost Settings <i class="fas fa-plus-square" @click="showDialogCostSettings = true" /></h4>
    <table class="spark-table">
      <tbody>
        <tr>
          <td>Available Profiles</td>
          <td>
            <select v-model="activeSetting" style="display: inline-block" @change="setCurrent(activeSetting)">
              <option
                v-for="(setting, uid) in user.organization.costing_configs"
                :key="uid"
                :value="uid"
                :selected="activeSetting === setting.name ? true : false"
              >
                {{ setting.name }}
              </option>
            </select>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="card" style="margin-top: 5px">
      <div style="display: inline-block; font-weight: bold; font-size: var(--13px)">
        <div v-if="!showDialogCostSettings">
          {{ costingConfig?.name }}
        </div>
        <div v-else>
          <input v-model="settingsName" class="input-header" type="text" />
        </div>
      </div>
      <div
        v-if="costingConfig?.name !== '3D Spark' || showDialogCostSettings"
        style="display: inline-block; position: absolute; right: 35px; margin-top: 5px; font-size: 0.9em"
      >
        <div v-if="!showDialogCostSettings" class="dialog-settings" @click="editCostSetting()">
          <i class="far fa-edit" />
        </div>
        <div v-if="showDialogCostSettings" class="dialog-settings" @click="closeCostDialog()">
          <i class="fas fa-times" />
        </div>
        <!--
 <div v-if="!showDialogCostSettings" @click="this.deleteCostSetting()" class="dialog-settings">
          <i class="far fa-trash-alt"></i>
        </div> 
-->
        <div v-if="showDialogCostSettings" class="dialog-settings" @click="makeCostSettings()">
          <i class="fas fa-check" />
        </div>
      </div>
      <p style="font-size: var(--12px); font-weight: bold">Hourly rates</p>
      <table class="spark-table" style="margin-bottom: 10px">
        <tbody>
          <tr style="display: flex; height: auto">
            <td class="subtable-left">AM Expert</td>
            <td v-if="!showDialogCostSettings" class="subtable-right">
              {{ costingConfig?.am_expert_hr }}
            </td>
            <td v-else class="subtable-right">
              <input v-model="roundedAmExpertHr" type="number" />
            </td>
            <td class="subtable-units">€</td>
          </tr>
          <tr style="display: flex; height: auto">
            <td class="subtable-left">Designer</td>
            <td v-if="!showDialogCostSettings" class="subtable-right">
              {{ costingConfig?.designer_hr }}
            </td>
            <td v-else class="subtable-right">
              <input v-model="roundedDesignerHr" type="number" />
            </td>
            <td class="subtable-units">€</td>
          </tr>
          <tr style="display: flex; height: auto">
            <td class="subtable-left">Technican</td>
            <td v-if="!showDialogCostSettings" class="subtable-right">
              {{ costingConfig?.technician_hr }}
            </td>

            <td v-else class="subtable-right">
              <input v-model="roundedTechnicianHr" type="number" />
            </td>
            <td class="subtable-units">€</td>
          </tr>
        </tbody>
      </table>
      <p style="font-size: var(--12px); font-weight: bold">Market Settings</p>
      <table class="spark-table" style="margin-bottom: 10px">
        <tbody>
          <tr style="display: flex; height: auto">
            <td class="subtable-left">Overhead</td>
            <td v-if="!showDialogCostSettings" class="subtable-right">
              {{ roundValue(costingConfig?.overhead * 100) }}
            </td>
            <td v-else class="subtable-right">
              <input v-model="roundedOverhead" type="number" />
            </td>
            <td class="subtable-units">%</td>
          </tr>
          <tr style="display: flex; height: auto">
            <td class="subtable-left">Margin</td>
            <td v-if="!showDialogCostSettings" class="subtable-right">
              {{ roundValue(costingConfig?.margin_default * 100) }}
            </td>
            <td v-else class="subtable-right">
              <input v-model="roundedMargin" type="number" />
            </td>
            <td class="subtable-units">%</td>
          </tr>
        </tbody>
      </table>
      <SparkButton small custom="!h-24" variant="outlined" @click="navigateToPricing">
        <div class="font-semibold text-13" v-text="'Pricing Configurator'" />
      </SparkButton>
    </div>
  </div>
</template>

<script>
SparkButton;
import { mapState } from 'vuex';

import SparkButton from '../../../components/SparkComponents/SparkButton.vue';

import { floatFormat } from '@/helpers.js';

export default {
  name: 'CostSettings',

  components: { SparkButton },

  data() {
    return {
      activeSetting: '',
      showDialogCostSettings: false,
      editMode: false,
      settingsName: 'My Settings',
      settingsAmExpert: null,
      settingsDesigner: null,
      settingsTechnician: null,
      settingsOverhead: null,
      settingsMargin: null,
      defaultAmExpert: null,
      defaultDesigner: null,
      defaultTechnician: null,
      defaultOverhead: null,
      defaultMargin: null,
      costSettingsDropdown: [
        { name: 'Option 1', uid: 123456778 },
        { name: 'Option 2', uid: 654664654 },
        { name: 'Option 3', uid: 987416534 },
      ],
    };
  },

  computed: {
    ...mapState(['user']),
    ...mapState('application', ['axiosInstance']),

    roundedAmExpertHr: {
      get() {
        return this.roundValue(this.settingsAmExpert, 2);
      },

      set(value) {
        value = Math.abs(value);
        this.settingsAmExpert = this.roundValue(value);
      },
    },

    roundedDesignerHr: {
      get() {
        return this.roundValue(this.settingsDesigner, 2);
      },

      set(value) {
        value = Math.abs(value);
        this.settingsDesigner = this.roundValue(value);
      },
    },

    roundedOverhead: {
      get() {
        return this.roundValue(this.settingsOverhead * 100, 2);
      },

      set(value) {
        value = Math.abs(value);
        this.settingsOverhead = this.roundValue(value / 100);
      },
    },

    roundedMargin: {
      get() {
        return this.roundValue(this.settingsMargin * 100, 2);
      },

      set(value) {
        value = Math.abs(value);
        this.settingsMargin = this.roundValue(value / 100);
      },
    },

    roundedTechnicianHr: {
      get() {
        return this.roundValue(this.settingsTechnician, 2);
      },

      set(value) {
        value = Math.abs(value);
        this.settingsTechnician = this.roundValue(value);
      },
    },

    costingConfig() {
      return this.user.organization.costing_config;
    },
  },

  mounted() {
    this.getDefaultCostingConfig();
    this.fillCostSettingsDropdown();
  },

  methods: {
    roundValue(number, digit = 2) {
      return parseFloat(floatFormat(number, digit));
    },

    navigateToPricing() {
      this.$router.push({ name: 'pricing' });
    },

    fillCostSettingsDropdown() {
      this.costSettingsDropdown = [];
      if (this.user?.organization?.costing_configs != undefined) {
        for (const [key, value] of Object.entries(this.user.organization.costing_configs)) {
          // Add uid to object in array form
          Object.assign(value, { uid: key });
          this.costSettingsDropdown.push(value);

          // update activeSetting
          if (value.uid === this.costingConfig.uid) {
            this.activeSetting = value.uid;
          }
        }
      }
    },

    getDefaultCostingConfig() {
      this.axiosInstance.get('api/v1/default-costing-config/').then(response => {
        this.defaultAmExpert = response.data['am_expert_hr'];
        this.defaultDesigner = response.data['designer_hr'];
        this.defaultTechnician = response.data['technician_hr'];
        this.defaultOverhead = response.data['overhead'];
        this.defaultMargin = response.data['margin_default'];
        this.resetInputs();
      });
    },

    resetInputs() {
      this.settingsName = 'My Settings';
      this.settingsAmExpert = this.defaultAmExpert;
      this.settingsDesigner = this.defaultDesigner;
      this.settingsTechnician = this.defaultTechnician;
      this.settingsOverhead = this.defaultOverhead;
      this.settingsMargin = this.defaultMargin;
    },

    closeCostDialog() {
      this.showDialogCostSettings = false;
      this.resetInputs();
    },

    setCurrent(uid) {
      // sets one profile as current organization profile
      this.axiosInstance
        .post(`api/v1/costing-config-selection/${uid}/`)
        .then(() => {
          this.$getUser(this.$keycloak, this.axiosInstance, this.$store);
        })
        .catch(error => {
          console.log(error.response);
        });
    },

    makeCostSettings() {
      // Create new cost settings or save edited values of an exisiting cost setting
      // Hide edit menu

      // create form data to send
      let formData = new FormData();
      formData.append('name', this.settingsName); // obligatorty
      formData.append('am_expert_hr', this.settingsAmExpert); // optional
      formData.append('designer_hr', this.settingsDesigner); // optional
      formData.append('technician_hr', this.settingsTechnician); // optional
      formData.append('overhead', this.settingsOverhead); // optional
      formData.append('margin_default', this.settingsMargin); // optional

      this.closeCostDialog();

      if (this.editMode) {
        // put if in editing a cost profile
        this.axiosInstance
          .put(`api/v1/costing-config/${this.costingConfig.uid}/`, formData)
          .then(() => {
            this.$getUser(this.$keycloak, this.axiosInstance, this.$store);
            this.$root.notify('success', 'Cost profile', 'Cost profile successfully updated.', 3000);
          })
          .catch(error => {
            console.log(error.response.data);
          });
      } else {
        // post if creating a new cost profile
        this.axiosInstance
          .post(`api/v1/costing-config/`, formData)
          .then(response => {
            this.setCurrent(response.data.uid);
            this.$root.notify('success', 'Cost profile', 'Cost profile successfully created.', 3000);
          })
          .catch(error => {
            console.log(error.response.data);
          });
      }
    },

    deleteCostSetting() {
      // 🤔 outdated?
      // delete the currently selected cost model and switch to another
      let deleteUid = this.costingConfig.uid;
      let number = 0;
      if (deleteUid === this.costSettingsDropdown[0].uid) {
        number = 1;
      }
      this.setCurrent(this.costSettingsDropdown[number].uid);

      this.axiosInstance
        .delete(`api/v1/costing-config/${deleteUid}/`)
        .then(() => {
          this.$getUser(this.$keycloak, this.axiosInstance, this.$store);
          this.fillCostSettingsDropdown();
          this.$root.notify('success', 'Cost profile', 'Cost profile successfully deleted.', 3000);
        })
        .catch(error => {
          console.log(error.response);
        });
    },

    editCostSetting() {
      this.showDialogCostSettings = true;
      this.editMode = true;
      this.settingsName = this.user.organization.costing_config.name;
      this.settingsAmExpert = this.user.organization.costing_config.am_expert_hr;
      this.settingsDesigner = this.user.organization.costing_config.designer_hr;
      this.settingsTechnician = this.user.organization.costing_config.technician_hr;
      this.settingsOverhead = this.user.organization.costing_config.overhead;
      this.settingsMargin = this.user.organization.costing_config.margin_default;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-styling {
  border: 1px solid darkgrey;
  border-radius: 3px;
  padding: 5px;
  margin-bottom: 5px;
}

input {
  font-size: 1em;
}

.input-header {
  -moz-appearance: textfield;
  -webkit-appearance: none;
  appearance: textfield;

  background-color: white;
  border: solid 1px var(--light-color);

  &:hover {
    border: solid 1px var(--primary-color-light);
    transition: all 0.3s ease-in;
    &:disabled {
      border: solid 1px white;
    }
  }
}

.subtable-right {
  text-align: right;
  display: inline-block;
  margin-left: 10px;
}

.subtable-left {
  width: 100%;
}

.subtable-units {
  width: 20%;
}

.dialog-settings {
  display: inline-flex;
  margin: 2px;
}

i {
  cursor: pointer;
}
</style>
