<template>
  <div>
    <div class="mb-20" v-text="'Toggle Features Visibility'" />
    <div class="flex gap-20">
      <SparkCheckbox
        v-for="(property, key) in formData"
        :key="key"
        v-model="property.value"
        :name="key"
        @change="saveFeatureVisibility(key, property.label, property.value)"
      >
        <div class="text-13">{{ property.label }}</div>
      </SparkCheckbox>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import SparkCheckbox from '../../../components/SparkComponents/SparkCheckbox.vue';

export default {
  name: 'FeatureVisibility',

  components: { SparkCheckbox },

  data() {
    return {
      formData: {
        prp_show_price: { label: 'Price', value: false },
        prp_show_feasibility: { label: 'Feasibility', value: false },
      },
    };
  },

  computed: {
    ...mapState(['user']),
    ...mapState('application', ['axiosInstance']),

    showPricePerPart() {
      return this.user.organization.prp_show_price;
    },

    showFeasibility() {
      return this.user.organization.prp_show_feasibility;
    },
  },

  mounted() {
    this.formData.prp_show_price.value = this.showPricePerPart;
    this.formData.prp_show_feasibility.value = this.showFeasibility;
  },

  methods: {
    async saveFeatureVisibility(feature, label, value) {
      await this.axiosInstance.put('api/v1/organization-settings/', { [feature]: value });
    },
  },
};
</script>
