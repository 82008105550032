<template>
  <div class="prp-layout flex h-full w-full flex-col">
    <SubNavigation />
    <div class="flex h-full w-full gap-40 p-20">
      <div class="flex h-full w-full flex-col">
        <div class="w-[900px] overflow-y-auto m-auto">
          <router-view />
        </div>
      </div>
      <transition
        appear
        name="slide-in-right"
        enter-active-class="transition-all duration-300"
        enter-from-class="opacity-0 translate-x-full"
      >
        <div
          v-if="showSidebar"
          class="w-[500px] h-screen overflow-auto relative p-16 pb-64 -m-20 bg-white flex-col gap-16 xl:flex"
        >
          <div class="absolute top-16 right-16">
            <SparkButtonIcon icon="fas fa-xmark" @click="toggleSidebar" />
          </div>
          <div id="side-bar-teleport" />
        </div>
      </transition>
    </div>
    <FooterBar />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import SparkButtonIcon from '../../components/SparkComponents/SparkButtonIcon.vue';
import FooterBar from '../../layouts/default/components/FooterBar.vue';
import SubNavigation from '../../views/Settings/components/SubNavigation.vue';

export default {
  name: 'PRPLayout',

  components: { FooterBar, SubNavigation, SparkButtonIcon },

  computed: {
    ...mapState('application', ['showSidebar']),
  },

  methods: {
    ...mapMutations('application', ['toggleSidebar']),
  },
};
</script>

<style lang="scss" scoped>
.slide-in-right-enter-active,
.slide-in-right-leave-active {
  transition: transform 0.3s ease;
}

.slide-in-right-enter,
.slide-in-right-leave-to {
  transform: translateX(100%);
}

.slide-in-right-enter-to,
.slide-in-right-leave {
  transform: translateX(0);
}
</style>
