<template>
  <div class="w-full">
    <div class="flex">
      <MarketPrice class="basis-1/2" :chain="activeProcessChain" />
      <AMManufacturingCost class="basis-1/2" />
    </div>
    <div class="flex justify-start items-baseline">
      <p class="font-bold text-15 mt-24 pr-4">Process Chain</p>
      <Toggle :possible-classes="levels" :selected-class="selectedLevel" @selected="selectLevel($event)" />
    </div>
    <ProcessChain :chain="activeProcessChain" :selected-level="selectedLevel" />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import AMManufacturingCost from '@/components/PartWorkflow/Cost/AMManufacturingCost.vue';
import MarketPrice from '@/components/PartWorkflow/Cost/MarketPrice.vue';
import ProcessChain from '@/components/PartWorkflow/ProcessChain/ProcessChain.vue';
import Toggle from '@/components/Reusable/Toggle.vue';

export default {
  name: 'CostCard',

  components: {
    AMManufacturingCost,
    MarketPrice,
    ProcessChain,
    Toggle,
  },

  data() {
    return {
      selectedLevel: {
        _pp: 'Part',
      },
    };
  },

  computed: {
    ...mapState(['investigationDetails', 'part']),
    activeInvestigationDetails() {
      return this.investigationDetails;
    },

    activeProcessChain() {
      let allChains = this.part.process_chains;
      return allChains[this.activeInvestigationDetails.uid];
    },

    levels() {
      let labels = {
        _pp: 'Part',
        _pj: '',
        _pl: 'Lot [' + this.activeProcessChain.lot_size + ']',
      };

      if (this.activeProcessChain.lot_only) {
        labels['_pj'] = 'Job [Ø ' + this.activeProcessChain.parts_pj + ']';
      } else {
        labels['_pj'] = 'Job [' + this.activeProcessChain.parts_pj + ']';
      }
      return labels;
    },
  },

  mounted() {
    this.changeDisplayInfo('breakEvenChart');
  },

  methods: {
    ...mapMutations(['changeDisplayInfo']),
    selectLevel(event) {
      this.selectedLevel = event;
    },
  },
};
</script>
