<template>
  <div class="flex flex-col mx-4" @click="trigger()">
    <FeasibilitySymbol
      class="my-8"
      :title="overallSuitability"
      :bool-feasibility="totalFeasibility(chain)?.toString()"
    />
    <OpenDetails :show="mouseHover === 'feasibility'" />
  </div>
</template>

<script>
import { markRaw } from 'vue';
import { mapState, mapMutations } from 'vuex';

import OpenDetails from '../../components/OpenDetails.vue';

import FeasibilityPopup from './components/FeasibilityPopup.vue';

import FeasibilitySymbol from '@/components/PartWorkflow/Feasibility/FeasibilitySymbol.vue';
import { totalFeasFromObj } from '@/helpers.js';

export default {
  name: 'Feasibility',

  components: {
    FeasibilitySymbol,
    OpenDetails,
  },

  props: {
    chain: {
      type: Object,
      default() {
        return {};
      },
    },

    mouseHover: { type: String, default: '' },
  },

  data() {
    return {};
  },

  computed: {
    ...mapState(['part']),

    overallSuitability() {
      if (this.chain?.float_suitabilities?.overall != null) {
        return this.$formatZeroDecimalPlaces(this.chain.float_suitabilities.overall) + '%';
      } else {
        return undefined;
      }
    },
  },

  methods: {
    ...mapMutations(['triggerPopup', 'changeInvestigationDetails']),
    totalFeasibility(chain) {
      if (chain?.feasibility === undefined || Object.keys(chain.feasibility).length == 0) {
        return undefined;
      } else {
        return totalFeasFromObj(chain.feasibility);
      }
    },

    trigger() {
      this.changeInvestigationDetails({ uid: this.chain.process_chain_id });
      this.triggerPopup({
        key: this.$options.name,
        show: true,
        title: 'Feasibility',
        type: 'component',
        buttons: true,
        buttonContent: [{ text: 'Close', type: 'outlined', value: 'cancel' }],
        data: markRaw(FeasibilityPopup),
      });
    },
  },
};
</script>
