<template>
  <div class="flex flex-col mx-4" @click="trigger()">
    <EstimationAndBenchmark
      type="carbon"
      :chain="chain"
      :process-chains="chains"
      unit="kgCO₂-eq"
      :mouse-hover="mouseHover === 'carbon'"
    />
    <OpenDetails :show="mouseHover === 'carbon'" />
  </div>
</template>

<script>
import { markRaw } from 'vue';
import { mapMutations } from 'vuex';

import EstimationAndBenchmark from '../../components/EstimationAndBenchmark.vue';
import OpenDetails from '../../components/OpenDetails.vue';

import CarbonPopup from './components/CarbonPopup.vue';

import useNumberFormatting from '@/composables/numberFormatting.js';
import { analysisFinished } from '@/composables/processChainHandling.js';

export default {
  name: 'CarbonFootprint',

  components: {
    EstimationAndBenchmark,
    OpenDetails,
  },

  props: {
    chain: {
      type: Object,
      default() {
        return {};
      },
    },

    chains: {
      type: Object,
      default() {
        return {};
      },
    },

    mouseHover: { type: String, default: '' },
  },

  computed: {
    analysisFinished() {
      return analysisFinished(this.chain.process_chain_id);
    },

    co2() {
      console.log();
      if (
        (this.chain.co2?.co2_pp == 0 && this.chain.co2?.co2_pl == 0 && this.chain.co2?.co2_pj == 0) ||
        (this.chain.co2?.co2_pp == null && this.chain.co2?.co2_pl == null && this.chain.co2?.co2_pj == null)
      ) {
        return this.format('-');
      } else {
        return this.format(this.chain?.co2.co2_pl);
      }
    },
  },

  methods: {
    format(value) {
      if (value == '-') {
        return 'n/a kg';
      } else {
        let unit = ' kg';
        if (value < 0.1) {
          value = value * 1000;
          unit = ' g';
        }
        return useNumberFormatting(value, 2).number.toString() + unit;
      }
    },

    ...mapMutations(['triggerPopup', 'changeInvestigationDetails']),

    trigger() {
      if (this.analysisFinished) {
        this.changeInvestigationDetails({ uid: this.chain.process_chain_id });
        this.triggerPopup({
          key: this.$options.name,
          show: true,
          title: 'Carbon',
          type: 'component',
          buttons: true,
          buttonContent: [{ text: 'Close', type: 'outlined', value: 'cancel' }],
          data: markRaw(CarbonPopup),
        });
      }
    },
  },
};
</script>
