<template>
  <div v-if="numberCheckedParts" class="flex-row">
    <div class="text-13 text-gray-500">{{ numberCheckedParts }} parts selected</div>
    <button class="selected-button" type="button" @click="deselectCheckedParts()">Deselect</button>
    <i title="delete selected parts" class="fas fa-trash mr-16 cursor-pointer" @click="confirmDelete" />
    <div class="spark-new-input-group w-200">
      <select v-model="selectedBatch" class="spark-new-select-input">
        <option v-for="batch in batches" :key="batch" :value="batch">
          {{ batch.batch_name }}
        </option>
      </select>
      <label class="spark-new-select-label">Add to group</label>
    </div>
    <i class="fas fa-check icon-button" @click="addToGroup()" />
    <DropdownMenu
      :list-options="options"
      :title-class="titleClass"
      :title-style="titleStyle"
      close-click-style="always"
      show-caret
      title="Share"
      @update-option="share($event)"
    />
  </div>
</template>

<script>
import { saveAs } from 'file-saver';
import { mapState, mapMutations } from 'vuex';

import DropdownMenu from '@/components/Reusable/DropdownMenu.vue';

export default {
  name: 'PartAction',

  components: { DropdownMenu },

  props: {
    checkedParts: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  emits: ['deselect-parts'],

  data() {
    return {
      selectedBatch: '',
      titleClass: 'spark-btn',
      options: [],
      titleStyle: {
        'padding-top': '6px',
        cursor: 'pointer',
        'margin-top': '0px',
        height: '2.5em',
      },

      reportTemplate: null,
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance', 'axiosInstanceFileUpload']),
    ...mapState(['fetchCustomListReportDOCX', 'user', 'popup', 'batches']),

    numberCheckedParts() {
      return Object.keys(this.checkedParts).length;
    },
  },

  watch: {
    fetchCustomListReportDOCX: {
      handler(newVal) {
        this.fetchCustomListReportFile(newVal);
      },

      immediate: true,
    },

    popup: {
      handler(popup) {
        if (popup?.key === this.$options.name) {
          if (popup?.clicked === 'ok') {
            this.deleteSelectedParts();
            this.triggerPopup('');
          } else if (popup?.clicked === 'cancel' || popup?.clicked === 'close') {
            this.triggerPopup('');
          }
        }
      },

      deep: true,
    },
  },

  async mounted() {
    this.getTemplateDocxList();
    this.updateFetchCustomListReportDOCX(false);
  },

  methods: {
    ...mapMutations(['updateFetchCustomListReportDOCX', 'triggerPopup', 'setReloadPartLibrary']),

    async deleteSelectedParts() {
      if (this.numberCheckedParts > 0) {
        const partIds = Object.keys(this.checkedParts);
        const deletePromise = partIds.map(id => this.deletePart(id));

        await Promise.all(deletePromise)
          .then(() => {
            this.$root.notify('success', 'Parts deleted', 'Selected parts have been deleted.', 3000);
          })
          .catch(error => {
            this.$root.notify('error', 'Error Deleting selected parts error', error, 6000);
          });
      }
      this.setReloadPartLibrary(true);
      this.deselectCheckedParts();
    },

    async deletePart(id) {
      await this.axiosInstance.delete(`/api/v1/part/${id}/`);
    },

    confirmDelete() {
      this.triggerPopup({
        key: this.$options.name,
        show: true,
        title: 'Delete Parts',
        message: `${this.numberCheckedParts} ${
          this.numberCheckedParts > 1 ? 'parts' : 'part'
        }  will be deleted. Are you sure?`,
        buttons: true,
        formData: this.partId,
        buttonContent: [
          { text: 'Cancel', type: 'outlined', value: 'cancel' },
          { text: 'Delete', type: 'secondary', value: 'ok' },
        ],
      });
    },

    async addToGroup() {
      if (this.selectedBatch && this.numberCheckedParts > 0) {
        await this.axiosInstance
          .put('/api/v1/add-parts-to-batch/', {
            parts: this.checkedParts,
            batch: this.selectedBatch,
          })
          .catch(error => {
            console.log('Error adding parts to batch:', error);
          });
      }
      this.selectedBatch = '';
      this.deselectCheckedParts();
    },

    deselectCheckedParts() {
      this.$emit('deselect-parts');
    },

    async getTemplateDocxList() {
      this.axiosInstance.get('api/v1/doc-template-list/').then(response => {
        Object.keys(response.data).forEach(key => {
          let template = response.data[key];
          if (template.doc_type === 'part_list') {
            this.options.push({
              text: 'Download DOCX - ' + template.name,
              value: 'docx-custom',
              icon: 'fas fa-file',
              template: template,
            });
          }
        });
        if (this.options.length === 0) {
          this.options.push({
            text: 'No Template Created Yet',
            value: 'no-templates',
            icon: 'fas fa-file',
          });
        }
      });
    },

    async requestCustomListReportDOCX() {
      await this.axiosInstance
        .post(`api/v1/doc-list-generator/${this.reportTemplate.uid}/`, {
          parts: this.checkedParts,
        })
        .then(() => {
          this.$root.notify('success', 'File requested', `Generating report ${this.reportTemplate.name}.`, 3000);
        });
    },

    fetchCustomListReportFile(newVal) {
      if (newVal) {
        this.axiosInstance
          .get(`api/v1/fetch-doc-list-generated/${this.reportTemplate.uid}/`, {
            responseType: 'blob',
            parts: this.checkedParts,
          })
          .then(response => {
            saveAs(response.data, this.reportTemplate.default_file_name);
          })
          .catch(error => {
            console.log(error);
          });
        this.updateFetchCustomListReportDOCX(false);
      }
    },

    share(option) {
      if (option.value === 'docx-custom') {
        this.reportTemplate = option.template;
        this.requestCustomListReportDOCX();
      }
      if (option.value === 'no-templates' && this.user?.user_role === 'manager') {
        this.$router.push({ name: 'organization-settings' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-row {
  display: flex;
}

.selected-button {
  border-radius: 3px;
  border: 1px solid var(--spark-lightgrey);
  box-shadow: none;
  margin-left: 10px;
  margin-right: 30px;
  font-size: var(--13px);
  background-color: white;
  color: var(--spark-lightgrey);

  &:hover {
    color: var(--accent-color);
    border: 1px solid var(--accent-color);
    cursor: pointer;
  }
}
</style>
