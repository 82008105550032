<template>
  <div v-if="chain != undefined" class="main-view bg-white">
    <div v-if="!analysisFinished" class="warning">Warning: Part is not analyzed!</div>
    <div class="report">
      <div class="py-12">{{ part.name }}</div>
      <div class="summary card">
        <div class="flex-row">
          <h2 class="flex">Summary</h2>
          <FeasibilityBar
            v-if="actualChainIsTechAM && overallFeasibility != null"
            title=""
            :value="overallFeasibility"
            class="bar"
          />
        </div>

        <div class="flex-row">
          <table v-if="chain.process_chain_id == part.primary_process_chain_id" class="spark-table">
            <tr>
              <td><div class="result-icon result-icon-cost" /></td>
              <td>Cost Saved</td>
              <td v-if="singleProcessChain">No Benchmark selected</td>
              <td v-if="!singleProcessChain" class="v-align">
                {{ costSaved }}
              </td>
              <td v-if="!singleProcessChain">€</td>
            </tr>
            <tr>
              <td><div class="result-icon result-icon-leadtime" /></td>
              <td>Lead Time Saved</td>
              <td v-if="singleProcessChain">No Benchmark selected</td>
              <td v-if="!singleProcessChain" class="v-align">
                {{ leadtimeSaved }}
              </td>
              <td v-if="!singleProcessChain">days</td>
            </tr>
            <tr>
              <td><div class="result-icon result-icon-carbon" /></td>
              <td>Carbon Emission Saved</td>
              <td v-if="singleProcessChain">No Benchmark selected</td>
              <td v-if="!singleProcessChain" class="v-align">
                {{ co2Saved }}
              </td>
              <td v-if="!singleProcessChain">kg CO₂-eq</td>
            </tr>
          </table>

          <table class="spark-table">
            <tr>
              <td>Manufacturing Cost per Part</td>
              <td class="v-align">{{ $formatTwoDecimalPlaces(amCostPerPart) }}</td>
              <td>€</td>
            </tr>
            <tr>
              <td>Quote Price per Part</td>
              <td class="v-align">{{ $formatTwoDecimalPlaces(marketPricePerPart) }}</td>
              <td>€</td>
            </tr>
            <tr>
              <td>Carbon Emission per Lot</td>
              <td class="v-align">{{ $formatTwoDecimalPlaces(chain.co2.co2_pl) }}</td>
              <td>kg CO₂-eq</td>
            </tr>
          </table>
        </div>
      </div>

      <div class="overview pagebreak-after">
        <h2>Technology Overview</h2>
        <table class="spark-table">
          <tr>
            <td>Process</td>
            <td colspan="2" class="crop">{{ chain.prc }}</td>
          </tr>
          <tr>
            <td>Material</td>
            <td colspan="2" class="crop">{{ chain.material_name }}</td>
          </tr>
          <tr>
            <td>Machine</td>
            <td colspan="2" class="v-align">{{ chain.machine_name }}</td>
          </tr>

          <tr />
          <tr>
            <td>Part Mass</td>
            <td class="v-align">
              {{ $formatZeroDecimalPlaces(chain.part_mass) }}
            </td>
            <td>g</td>
          </tr>
          <tr>
            <td>Part Volume</td>
            <td class="v-align">
              {{ $formatOneDecimalPlace(part.part_vol) }}
            </td>
            <td>cm <span class="superscript-3">3</span></td>
          </tr>
          <tr>
            <td>Bounding Box</td>
            <td class="v-align">
              {{ $formatOneDecimalPlace(part.bb_x) }} x {{ $formatOneDecimalPlace(part.bb_y) }} x
              {{ $formatOneDecimalPlace(part.bb_z) }}
            </td>
            <td>mm</td>
          </tr>

          <tr />
          <tr v-if="actualChainIsTechAM">
            <td>Part Support Mass</td>
            <td class="v-align">
              {{ $formatZeroDecimalPlaces(chain.sup_mass) }} ({{ $formatZeroDecimalPlaces(supportPercent) }}
              %)
            </td>
            <td>g</td>
          </tr>

          <tr v-if="actualChainIsTechAM">
            <td>Minimum Bounding Box</td>
            <td class="v-align">
              {{ $formatOneDecimalPlace(part.min_bb_x) }} x {{ $formatOneDecimalPlace(part.min_bb_y) }} x
              {{ $formatOneDecimalPlace(part.min_bb_z) }}
            </td>
            <td>mm</td>
          </tr>
          <tr v-if="actualChainIsTechAM">
            <td>Number of Layers</td>
            <td class="v-align">
              {{ chain.n_lyr }}
            </td>
            <td />
          </tr>
          <tr v-if="actualChainIsTechAM">
            <td>Full Job Build Height</td>
            <td class="v-align">
              {{ $formatOneDecimalPlace(chain.bld_h) }}
            </td>
            <td>mm</td>
          </tr>
        </table>
      </div>

      <div class="processchain pagebreak-before pagebreak-after">
        <h2>Process Chain</h2>
        <ProcessChain :chain="chain" :display-title="false" pdf-view />
      </div>

      <!-- Cost -->
      <div v-if="actualChainIsTechAM" class="costtable">
        <div class="flex-row">
          <h2 class="flex">Cost</h2>
          <FeasibilityBar title="" :value="economicalFeasibility" class="bar" />
        </div>
        <table class="spark-table">
          <tr>
            <td colspan="2">Cost Savings by Orientation</td>
            <td class="v-align">
              {{ $formatZeroDecimalPlaces(costSave) }}
            </td>
            <td>%</td>
          </tr>
          <tr />
          <tr>
            <td colspan="2">Lot Size</td>
            <td class="v-align">{{ chain.lot_size }}</td>
            <td />
          </tr>
          <tr>
            <td colspan="2">Cost per Lot</td>
            <td class="v-align">{{ $formatTwoDecimalPlaces(chain.cost.cost_pl) }}</td>
            <td>€</td>
          </tr>

          <tr>
            <td colspan="2">Parts per Job</td>
            <td class="v-align">{{ chain.parts_pj }}</td>
            <td />
          </tr>
          <tr>
            <td colspan="2">Cost per Job</td>
            <td class="v-align">{{ $formatTwoDecimalPlaces(chain.cost.cost_pj) }}</td>
            <td>€</td>
          </tr>

          <tr />

          <tr>
            <td colspan="2">Cost per Part</td>
            <td class="v-align">{{ $formatTwoDecimalPlaces(amCostPerPart) }}</td>
            <td>€</td>
          </tr>

          <tr>
            <td class="legend" :style="{ 'background-color': paletteArray[0] }" />
            <td>Pre-Process</td>
            <td class="v-align">
              {{ $formatTwoDecimalPlaces(amCostBreakdown.pre_process) }}
            </td>
            <td>€</td>
          </tr>
          <tr>
            <td class="color-block" :style="{ 'background-color': paletteArray[1] }" />
            <td>In-Process</td>
            <td class="v-align">
              {{ $formatTwoDecimalPlaces(amCostBreakdown.in_process) }}
            </td>
            <td>€</td>
          </tr>
          <tr>
            <td class="color-block" :style="{ 'background-color': paletteArray[2] }" />
            <td>Post-Process</td>
            <td class="v-align">
              {{ $formatTwoDecimalPlaces(amCostBreakdown.post_process) }}
            </td>
            <td>€</td>
          </tr>
          <tr>
            <td class="color-block" :style="{ 'background-color': paletteArray[3] }" />
            <td>Material</td>
            <td class="v-align">
              {{ $formatTwoDecimalPlaces(amCostBreakdown.material) }}
            </td>
            <td>€</td>
          </tr>
        </table>
      </div>

      <div v-if="actualChainIsTechAM" class="costchart">
        <h2>AM Manufacturing Cost per Part</h2>
        <CostChart :chain="chain" :label="label" short-bool @mounted="childComponentsMounted += 1" />
      </div>

      <div v-if="actualChainIsTechAM" class="feastable">
        <div class="flex-row">
          <h2 class="flex">Feasibility</h2>
          <FeasibilityBar title="" class="bar" :value="technicalFeasibility" />
        </div>
        <div v-if="activeProcessChainFeasibilities != null" class="feas-title">
          <FeasibilityEntry
            name="Fits Machine"
            :show-bar="false"
            :feasibility="activeProcessChainFeasibilities?.fits_mac"
            additional=""
            class="row2-col3"
          />
          <FeasibilityEntry
            name="Fits Process"
            show-bar
            :feasibility="activeProcessChainFeasibilities?.size"
            :additional="additionalDataFitsProcess"
            class="row1-col3"
          />
          <FeasibilityEntry
            name="Wall Thickness"
            show-bar
            :feasibility="activeProcessChainFeasibilities?.thickness"
            additional=""
            class="row1-col2"
          />
          <FeasibilityEntry
            name="Gap Size"
            show-bar
            :feasibility="activeProcessChainFeasibilities?.gap_size"
            additional=""
            class="row2-col2"
          />
          <FeasibilityEntry
            name="Support Removability"
            :show-bar="false"
            :feasibility="activeProcessChainFeasibilities?.support_occluded"
            additional=""
            class="row2-col3"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import CostChart from '@/components/PartWorkflow/Cost/CostChart.vue';
import FeasibilityEntry from '@/components/PartWorkflow/Feasibility/FeasibilityEntry.vue';
import ProcessChain from '@/components/PartWorkflow/ProcessChain/ProcessChain.vue';
import FeasibilityBar from '@/components/Reusable/FeasibilityBar.vue';
import { analysisFinished } from '@/composables/processChainHandling.js';

export default {
  name: 'DownloadPdf',

  components: {
    CostChart,
    FeasibilityBar,
    FeasibilityEntry,
    ProcessChain,
  },

  data() {
    return {
      childComponentsMounted: 0,
      label: {
        labor: 'Labor',
        machine: 'Machine',
        other: 'Other',
        build_material: 'Build Material',
        support_material: 'Support Material',
        waste_material: 'Waste Material',
      },
    };
  },

  computed: {
    ...mapState(['part', 'user']),
    benchmarkProcessChainUid() {
      return this.part.benchmark_process_chain_id;
    },

    costSaved() {
      if (this.part.cost_saved * this.chain.lot_size < 0) return '-';
      else {
        return this.$formatTwoDecimalPlaces(this.part.cost_saved * this.chain.lot_size);
      }
    },

    leadtimeSaved() {
      if (this.part.leadtime_saved < 0) return '-';
      else {
        return this.part.leadtime_saved;
      }
    },

    co2Saved() {
      let primaryco2 = this.chain?.co2.co2_pl;
      if (this.benchmarkProcessChainUid) {
        let benchmarkco2 = this.processChains[this.benchmarkProcessChainUid]?.co2.co2_pl;

        if (benchmarkco2 - primaryco2 < 0) return '-';
        else return this.$formatTwoDecimalPlaces(benchmarkco2 - primaryco2);
      } else {
        return '-';
      }
    },

    singleProcessChain() {
      return Object.keys(this.processChains).length == 1;
    },

    pdfRequested() {
      return this.part.pdf_requested;
    },

    chain() {
      let process_chain_id = this.$route.params.process_chain_id;
      if (this.processChains != undefined) {
        return this.processChains[process_chain_id];
      } else {
        return {};
      }
    },

    processChains() {
      return this.part.process_chains;
    },

    actualChainIsTechAM() {
      return this.chain?.tech == 'additive_manufacturing';
    },

    activeProcessChainFeasibilities() {
      return this.chain.feasibility;
    },

    paletteArray() {
      return getComputedStyle(document.body).getPropertyValue('--chart-palette').split(',');
    },

    amCostBreakdown() {
      let obj = this.chain.cost_breakdown;
      let result = {};
      for (const [key] of Object.entries(obj)) {
        result[key] = 0;
        for (const [subkey] of Object.entries(obj[key])) {
          result[key] += obj[key][subkey];
        }
      }
      return result;
    },

    amCostPerPart() {
      return this.chain?.cost.cost_pp;
    },

    supportPercent() {
      return (this.chain.sup_mass / this.chain.part_mass) * 100;
    },

    marketPricePerPart() {
      let marketPrice = this.chain.market_price.market_price_pp;
      if (marketPrice.user == null) {
        return marketPrice.calculated;
      } else {
        return marketPrice.user;
      }
    },

    technicalFeasibility() {
      return this.chain?.float_suitabilities?.technical;
    },

    economicalFeasibility() {
      return this.chain?.float_suitabilities?.economic;
    },

    overallFeasibility() {
      return this.chain?.float_suitabilities?.overall;
    },

    costSave() {
      if (this.chain.cost_ini_pp) {
        return Math.round(((1 - this.chain.cost.cost_pp / this.chain.cost_ini_pp) * 100 + Number.EPSILON) * 100) / 100;
      } else {
        return null;
      }
    },

    analysisFinished() {
      return analysisFinished(this.chain.process_chain_id);
    },

    additionalDataFitsProcess() {
      return 'Minimum Scaled Bounding Box compared to Limits of ' + this.chain.prc + ' Process';
    },
  },

  watch: {
    pdfRequested(newVal) {
      if (newVal == true) {
        setTimeout(this.print, 1000);
        this.requestPdf(false);
      }
    },
  },

  methods: {
    ...mapMutations('canvas', ['updateReloadCanvas']),
    ...mapMutations({
      requestPdf: 'pdfRequested',
    }),

    print() {
      this.setPrintDisplay('none'); // Hide elements
      window.print();
      this.setPrintDisplay(''); // Show elements
    },

    setPrintDisplay(value) {
      // Popup
      const popupElements = document.getElementsByClassName('confirm');
      for (let i = 0; i < popupElements.length; i++) {
        popupElements[i].style.display = value;
      }

      // SubNav
      const subNavElements = document.getElementsByClassName('navi');
      for (let i = 0; i < subNavElements.length; i++) {
        subNavElements[i].style.display = value;
      }

      // Footer
      const footerElements = document.getElementsByClassName('part-footer');
      for (let i = 0; i < footerElements.length; i++) {
        footerElements[i].style.display = value;
      }

      // Navbar
      const navElement = document.getElementById('nav');
      if (navElement) {
        navElement.style.display = value;
      }

      // Buttons
      const buttonsElement = document.getElementById('buttons');
      if (buttonsElement) {
        buttonsElement.style.display = value;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: var(--18px);
}

h2 {
  padding-top: 5px;
  font-size: var(--15px);
}

.report {
  display: grid;
  grid-template-columns: 1fr;
  max-width: 100%;
  min-width: 0;
  background-color: white;
  grid-auto-rows: auto auto auto auto auto;
  grid-template-areas: 'title' 'header' 'summary' 'costtable' 'overview' 'feastable' 'costchart' 'processchain';
  print-color-adjust: exact !important;
}

@media only screen and (min-width: 800px) {
  .report {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 100%;
    min-width: 0;
    grid-auto-rows: auto auto auto auto auto;
    grid-template-areas: 'title header' 'summary summary' 'summary-1 summary-2' ' costtable feastable ' ' costchart  overview' 'processchain processchain';
    print-color-adjust: exact !important;
  }
}

.overview {
  grid-area: overview;
  min-width: 0;
  max-width: 100%;
  padding: 20px 20px 20px 0px;
}

.processchain {
  grid-area: processchain;
  margin: 10px;
  padding: 20px 20px 20px 0px;
  min-width: 0;
}

.costtable {
  grid-area: costtable;
  min-width: 0;
  padding: 20px 20px 20px 0px;
}

.costchart {
  grid-area: costchart;
  min-width: 0;
  padding: 20px 20px 20px 0px;
  max-width: 79%;
}

.feastable {
  grid-area: feastable;
  min-width: 0;
  padding: 20px 20px 20px 0px;
}
.summary {
  cursor: default;
  grid-area: summary;
  min-width: 0;
  padding: 10px;
}

.superscript-3 {
  vertical-align: super;
  font-size: 0.8em;
}

.summary-1 {
  grid-area: summary-1;
  padding: 20px 20px 20px 0px;
  min-width: 0;
}
.summary-2 {
  grid-area: summary-2;
  padding: 20px 20px 20px 0px;
  min-width: 0;
}

.bar {
  width: 30%;
  display: inline-block;
  margin-left: 10px;
  min-width: 300px;
}

.spark-table {
  margin: unset;
}

.flex {
  display: flex;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media print {
  @page {
    margin: 1.6cm;
    background-color: white;
  }

  // body {
  //   padding: 3cm;
  //   margin: 3cm;
  // }

  .pagebreak-before {
    page-break-before: always;
  } /* page-break-after works, as well */
  .pagebreak-after {
    page-break-after: always;
  }
}

.crop {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: right;
  max-width: 0;
}

.result-icon-cost {
  background: url('@/assets/icons/comparison/cost.svg');
  float: left;
  margin-top: 3px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.result-icon-leadtime {
  background: url('@/assets/icons/comparison/leadtime.svg');
  float: left;
  margin-top: 3px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.result-icon-carbon {
  background: url('@/assets/icons/comparison/carbon.svg');
  float: left;
  margin-top: 3px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.subtext {
  text-align: right;
  padding: 2px;
  color: var(--spark-lightgrey);
  transition: all 0.3s ease-in;
  border-radius: 2px;
}

.main-view {
  padding: var(--grid-outer-whitespace);
  // margin: 0px var(--grid-outer-whitespace);
  background-color: white;
}

.warning {
  background-color: var(--spark-delete);
  margin: 3px 0px;
  padding: 3px;
  opacity: 0.5;
}

.v-align {
  text-align: right;
}

.color-block {
  width: 2px;
}

.feas-title {
  font-size: var(--12px);
}
</style>
