<template>
  <div />
</template>

<script>
import { mapState, mapMutations } from 'vuex';
export default {
  name: 'Logout',

  props: { triggerLogout: Boolean },

  data() {
    return {};
  },

  computed: {
    ...mapState(['isAuthenticated', 'refreshToken']),
    ...mapState('application', ['axiosInstance']),

  },

  watch: {
    triggerLogout: {
      handler(triggerLogout) {
        if (triggerLogout) this.logout();
      },

      immediate: true,
    },
  },

  methods: {
    ...mapMutations(['removeUser', 'removeTokens']),

    logout() {
      const getUrl = window.location;
      const basePath = getUrl.protocol + '//' + getUrl.host;
      this.removeUserTokens();
      this.$keycloak.logout({ redirectUri: basePath });
      this.$router.push('/');

    },

    removeUserTokens() {
      this.removeTokens(); // Sets 'isAuthenticated' to false
      this.removeUser();
    },
  },
};
</script>
