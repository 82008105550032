<template>
  <div class="flex flex-col mx-4">
    <EstimationAndBenchmark type="leadtime" :chain="chain" :process-chains="chains" unit="d" @click="setToActive()" />
    <OpenDetails :show="false" />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

import EstimationAndBenchmark from '../../components/EstimationAndBenchmark.vue';
import OpenDetails from '../../components/OpenDetails.vue';

export default {
  name: 'Leadtime',

  components: {
    EstimationAndBenchmark,
    OpenDetails,
  },

  props: {
    chain: {
      type: Object,
      default() {
        return {};
      },
    },

    chains: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  methods: {
    ...mapMutations(['changeInvestigationDetails']),

    setToActive() {
      this.changeInvestigationDetails({ uid: this.chain.process_chain_id });
    },
  },
};
</script>
