<template>
  <div
    v-if="isCadLess"
    class="flex flex-col border border-solid rounded-4 border-gray-200 mr-5"
    :class="isCadLess ? 'highlight' : ''"
  >
    <!-- Input Requirement Instructions -->
    <div class="flex flex-col items-center mt-4">
      <p v-if="!isValidInput" class="text-red-500">Please enter either Volume, or both Mass and Material.</p>
    </div>

    <div class="flex mt-4 mr-4 mb-0 ml-16 flex-wrap">
      <SparkBadge v-if="dataComplete" class="m-2" variant="success"
        ><i class="fas fa-check mr-2" />Data Input Complete</SparkBadge
      >
      <SparkBadge v-if="dataComplete && checkVolume" class="m-2" variant="error"
        ><i class="fas fa-times mr-2" />Part Volume cannot be bigger than Bounding Box</SparkBadge
      >
      <SparkBadge v-if="dataComplete && checkDensity" class="m-2" variant="error"
        ><i class="fas fa-times mr-2" />Density too high. Please check volume and mass</SparkBadge
      >
      <SparkBadge v-if="dataComplete && checkVolumeRatio" class="m-2" variant="error"
        ><i class="fas fa-times mr-2" />Part Volume is too small for Bounding Box. Please check Volume and Bounding
        Box</SparkBadge
      >
    </div>

    <div class="flex mt-20">
      <div class="flex flex-col ml-4">
        <div class="flex align-center items-center">
          <SparkInput
            v-if="bb_x !== undefined"
            v-model.lazy="bb_x"
            class="ml-8"
            name="x"
            label="Bounding Box X"
            :disabled="!isCadLess"
            @change="changed('bb_x', bb_x)"
          />
          <div class="text-11 pl-4 pr-8">{{ part.upload_file_units }}</div>
        </div>
        <div class="flex align-center items-center">
          <SparkInput
            v-if="bb_y !== undefined"
            v-model.lazy="bb_y"
            class="ml-8"
            name="y"
            label="Bounding Box Y"
            :disabled="!isCadLess"
            @change="changed('bb_y', bb_y)"
          />
          <div class="text-11 pl-4 pr-8">{{ part.upload_file_units }}</div>
        </div>
        <div class="flex align-center items-center">
          <SparkInput
            v-if="bb_z !== undefined"
            v-model.lazy="bb_z"
            class="ml-8"
            name="z"
            label="Bounding Box Z"
            :disabled="!isCadLess"
            @change="changed('bb_z', bb_z)"
          />
          <div class="text-11 pl-4 pr-8">{{ part.upload_file_units }}</div>
        </div>
      </div>
      <div class="flex flex-col ml-4">
        <div class="flex align-center items-center">
          <SparkInput
            v-if="part_vol !== undefined"
            v-model.lazy="part_vol"
            name="text"
            label="Volume"
            :disabled="!isCadLess"
            @change="changed('part_vol', part_vol)"
          />
          <div class="text-11 pl-4 pr-8">cm³</div>
        </div>
        <div class="flex align-center items-center">
          <SparkInput
            v-if="current_part_mass !== undefined"
            v-model.lazy="current_part_mass"
            name="text"
            label="Mass"
            :disabled="!isCadLess"
            @change="changed('current_part_mass', current_part_mass)"
          />
          <div class="text-11 pl-4 pr-8">g</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import SparkBadge from '../../SparkComponents/SparkBadge.vue';
import SparkInput from '../../SparkComponents/SparkInput.vue';

export default {
  name: 'CADLessInput',

  components: { SparkInput, SparkBadge },

  data() {
    const getPartProperty = (property, defaultValue = null) =>
      this.part && this.part[property] !== undefined ? this.part[property] : defaultValue;

    return {
      bb_x: getPartProperty('bb_x'),
      bb_y: getPartProperty('bb_y'),
      bb_z: getPartProperty('bb_z'),
      part_vol: getPartProperty('part_vol'),
      current_part_mass: getPartProperty('current_part_mass'),
    };
  },

  computed: {
    ...mapState(['part']),
    ...mapState('application', ['axiosInstance']),

    isMaterialSelected() {
      return this.part.mat_id !== undefined;
    },

    isValidInput() {
      return this.part_vol || (this.current_part_mass && this.isMaterialSelected);
    },

    dataComplete() {
      return this.bb_x && this.bb_x && this.bb_y && this.bb_y && this.bb_z && this.bb_z && this.isValidInput;
    },

    isCadLess() {
      return this.part.is_cadless;
    },

    checkVolume() {
      if (this.part_vol <= (this.bb_x * this.bb_y * this.bb_z) / 1000) {
        return false;
      } else {
        return true;
      }
    },

    checkVolumeRatio() {
      if (!this.part_vol || (this.part_vol * 1000) / (this.bb_x * this.bb_y * this.bb_z) >= 1 / 1000) {
        return false;
      } else {
        return true;
      }
    },

    checkDensity() {
      if (this.current_part_mass === undefined) {
        return false;
      } else if (this.current_part_mass <= ((this.bb_x * this.bb_y * this.bb_z) / 1000) * 9) {
        return false;
      } else {
        return true;
      }
    },

    partId() {
      return this.part.part_id;
    },
  },

  watch: {
    dataComplete(newVal) {
      if (newVal) {
        this.savePart();
      }
    },

    isCadLess() {
      this.bb_x = this.part.bb_x;
      this.bb_y = this.part.bb_y;
      this.bb_z = this.part.bb_z;
      this.part_vol = this.part.part_vol;
      this.current_part_mass = this.part.current_part_mass;
    },
  },

  mounted() {
    this.bb_x = this.part.bb_x;
    this.bb_y = this.part.bb_y;
    this.bb_z = this.part.bb_z;
    this.part_vol = this.part.part_vol;
    this.current_part_mass = this.part.current_part_mass;
  },

  methods: {
    ...mapMutations(['updatePart']),

    changed(key, value) {
      if (value !== '') {
        this[key] = value;
      }

      let obj = {};
      obj[key] = this[key] !== '' ? this[key] : null;
      this.updatePart(obj);
      this.savePart();
    },

    savePart() {
      if (!this.dataComplete) {
        return;
      }

      let formData = {
        bb_x: this.bb_x,
        bb_y: this.bb_y,
        bb_z: this.bb_z,
        part_vol: this.part_vol,
        current_part_mass: this.current_part_mass,
        is_cadless: this.isCadLess,
      };
      if (this.isCadLess && (this.part.part_id == 0 || this.part.part_id === undefined || this.part.part_id === '')) {
        this.axiosInstance
          .post(`/api/v1/part/`, formData)
          .then(response => {
            this.updatePart(response.data);
            this.$root.notify('success', 'Part created', '', 3000);
            this.$router.replace({
              name: this.$route.name,
              params: {
                part_id: response.data.part_id,
              },
            });
          })
          .catch(error => {
            console.log(JSON.stringify(error));
          });
      } else {
        this.axiosInstance
          .put(`/api/v1/part/${this.part.part_id}/`, formData)
          .then(() => {})
          .catch(error => {
            console.log(JSON.stringify(error));
          });
      }
    },
  },
};
</script>
