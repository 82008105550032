<template>
  <div>
    <div class="report-print-exclude flex flex-col mr-32">
      <div class="text-11 text-gray-200 flex-start">Validation</div>
      <DropdownMenu
        v-if="!lockedForUser"
        :list-options="!lockedForUser ? partStats : []"
        close-click-style="always"
        custom
        @update-option="show($event)"
      >
        <div class="flex flex-start items-center">
          <SparkBadge v-if="oldStat == 'validated'" variant="success" custom="w-200 h-120">
            <div class="flex items-center justify-center w-full gap-16">
              <i class="fas fa-check" />
              <span v-text="'Validated'" />
              <i class="fas fa-caret-down pl-8" />
            </div>
          </SparkBadge>
          <SparkBadge v-if="oldStat == 'not_validated'" variant="warning" custom="w-200 h-120">
            <div class="flex items-center justify-center w-full gap-16">
              <i class="fas fa-times" />
              <span v-text="'Not Validated'" />
              <i class="fas fa-caret-down pl-8" />
            </div>
          </SparkBadge>
          <SparkBadge v-if="oldStat == 'unset'" variant="outlined" custom="w-200 h-120">
            <div class="flex items-center justify-center w-full gap-16">
              <i class="fas fa-minus" />
              <span v-text="'Unset'" />
              <i class="fas fa-caret-down pl-8" />
            </div>
          </SparkBadge>
        </div>
      </DropdownMenu>
    </div>
    <div class="report-print-include">
      Analyzed for you by
      <img src="../../../assets/img/BW_3d_spark_logo.png" alt="3D Spark Logo" class="nav-brand-img p-4" height="30" />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

import SparkBadge from '../../SparkComponents/SparkBadge.vue';

import StatusPopup from './StatusPopup.vue';

import DropdownMenu from '@/components/Reusable/DropdownMenu.vue';

export default {
  name: 'Status',

  components: {
    DropdownMenu,
    SparkBadge,
  },

  data() {
    return {
      newStat: '',
      statusColor: 'grey',
    };
  },

  computed: {
    ...mapState(['part', 'partStats', 'popup']),
    ...mapState('application', ['lockedTitle', 'axiosInstance']),
    ...mapState({
      storePartStats: 'partStats',
    }),

    ...mapGetters(['lockedForUser', 'processChainsAnalysisFinished']),
    oldStat() {
      return this.part.part_stat;
    },

    oldStatText() {
      return this.partStats.find(entry => entry.value == this.oldStat)?.text;
    },

    newStatText() {
      return this.partStats.find(entry => entry.value == this.newStat)?.text;
    },

    partStats() {
      return Object.values(this.storePartStats);
    },
  },

  watch: {
    popup(popup) {
      if (popup?.key == this.$options.name) {
        if (popup?.clicked == 'ok') {
          this.triggerPopup('');
          this.updatePartStatInStore();
        } else if (popup?.clicked == 'cancel' || popup?.clicked == 'close') {
          this.triggerPopup('');
        }
      }
    },
  },

  mounted() {
    this.statusColor = this.color(this.oldStat);
  },

  methods: {
    ...mapMutations(['triggerPopup', 'updatePartStat', 'updatePart']),

    color(stat) {
      if (stat === 'validated') {
        return 'var(--spark-darkgreen)';
      } else if (stat === 'not_validated') {
        return 'var(--spark-warning)';
      } else {
        return 'grey';
      }
    },

    show(newStat) {
      this.newStat = newStat.value;
      this.triggerPopup({
        key: this.$options.name,
        show: true,
        title: 'Changing of Part Status',
        type: 'component',
        buttons: true,
        buttonContent: [
          { text: 'Cancel', type: 'outlined', value: 'cancel' },
          { text: 'Ok', type: 'secondary', value: 'ok' },
        ],
        data: StatusPopup,
        additionalData: {
          oldStatText: this.oldStatText,
          newStatText: this.newStatText,
        },
      });
    },

    updatePartStatInStore() {
      this.updatePartStat(this.newStat);
      this.statusColor = this.color(this.newStat);
      this.savePart();
    },

    savePart() {
      let part_id = this.part.part_id;
      let formData = { part_stat: this.newStat };
      this.axiosInstance
        .put(`/api/v1/part/${part_id}/`, formData)
        .then(response => {
          this.updatePart(response.data);
        })
        .catch(error => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss">
.status {
  font-size: var(--14px);
  min-width: 100px;
  // background-color: var(--lighter-color);
  box-shadow: none;
  border: none;
  margin: 0px;
  &:hover {
    // background-color: var(--light-color);
    border: none;
  }
  &:focus {
    // background-color: var(--light-color);
    border: none;
  }
}
.report-print-include {
  display: none;
}
@media print {
  .report-print-exclude {
    display: none;
  }
  .report-print-include {
    display: block;
  }
}
</style>
