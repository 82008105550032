<template>
  <div style="margin-top: 20px">
    <div style="margin-left: 25px">
      <OrganizationFilter @selected="onOrganizationSelected($event)" />
    </div>
    <div class="dashboard-container">
      <div class="dashboard-box" style="width: 69%">
        <div style="flex-grow: 2">
          <TotalNewUsers :organizations="selectedOrganizations" @update-total-new-users-series="handleTotalNewUsersSeries" />
        </div>
      </div>
    </div>
    <div class="dashboard-container">
      <div class="dashboard-box" style="width: 69%">
        <div style="flex-grow: 2">
          <TotalUsers
            :organizations="selectedOrganizations" 
            @update-total-users-series="handleTotalUsersSeries"
            @update-months="handleMonthsUpdate"
          ></TotalUsers>
        </div>
      </div>
    </div>
    <div class="dashboard-container">
      <div class="dashboard-box" style="width: 69%">
        <div style="flex-grow: 2">
          <ActiveUsers :organizations="selectedOrganizations" @update-active-users-series="handleActiveUsersSeries" />
        </div>
      </div>
    </div>
    <div class="dashboard-container">
      <div class="dashboard-box" style="width: 69%">
        <div style="flex-grow: 2">
          <TotalAndActiveUsers
            :organizations="selectedOrganizations"
            :total-active-users="totalActiveUsers"
            :total-new-users="totalNewUsers"
            :total-users="totalUsers"
            :months="monthsArray"
          ></TotalAndActiveUsers>
        </div>
      </div>
    </div>
    <div style="margin-left: 25px">
      {{ selectedOrganizations }}
    </div>
  </div>
</template>

<script>
import TotalAndActiveUsers from './TotalAndActiveUsers.vue';
import OrganizationFilter from '@/components/Reusable/Filter/OrganizationFilter.vue';
import ActiveUsers from './ActiveUsers.vue';
import TotalNewUsers from './TotalNewUsers.vue';
import TotalUsers from './TotalUsers.vue';

export default {
  name: 'Dashboard',
  components: {
    TotalAndActiveUsers,
    OrganizationFilter,
    ActiveUsers,
    TotalUsers,
    TotalNewUsers,
  },
  data() {
    return {
      selectedOrganizations: [],
      totalActiveUsers: {},
      totalNewUsers: {},
      totalUsers: {},
      monthsArray: [],
    };
  },
  methods: {
    onOrganizationSelected(event) {
      if (event.organizations != undefined) {
        this.selectedOrganizations = event.organizations;
      } else {
        this.selectedOrganizations = event;
      }
    },
    handleTotalUsersSeries(totalUsersSeries) {
      this.totalUsers = totalUsersSeries;
    },
    handleActiveUsersSeries(totalActiveUsersSeries) {
      this.totalActiveUsers = totalActiveUsersSeries;
    },
    handleTotalNewUsersSeries(totalNewUsersSeries) {
      this.totalNewUsers = totalNewUsersSeries;
    },
    handleMonthsUpdate(months) {
      this.monthsArray = months.map(month => {
        const [year, monthNum] = month.split('-');
        return `${monthNum.length === 1 ? '0' + monthNum : monthNum}/${year}`;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-container {
  margin: 25px;
  display: flex;
  margin-top: 10px;
}

.spark-btn {
  margin: 20px 0px 0px 0px;
  width: 100%;
}
.dashboard-row {
  width: auto;
}

.dashboard-box {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  margin: 10px;
  padding: 10px;
}
</style>
