<template>
  <div>
    <SparkForm class="w-200 ml-10">
      <SparkInput
        :model-value="currentMaterial"
        name="input"
        label="Current Material"
        :title="lockedForUser ? lockedTitle : ''"
        animate-label
        @change="updateCurrentMaterial($event)"
      />
    </SparkForm>
    <span v-if="currentMaterial" class="text-11 ml-10 p-2">
      <i class="fas fa-check mr-4" />Material Proposals are shown at the next page.
    </span>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

import SparkForm from '../../SparkComponents/SparkForm.vue';
import SparkInput from '../../SparkComponents/SparkInput.vue';
export default {
  name: 'CurrentMaterialSelection',

  components: { SparkForm, SparkInput },

  emits: ['material-change'],

  computed: {
    ...mapState(['part', 'lockedTitle']),

    currentMaterial: {
      get() {
        return this.part.current_mat;
      },

      set(value) {
        this.setCurrentMat(value);
        this.$emit('material-change', { current_mat: value });
      },
    },
  },

  watch: {
    currentMaterial(newVal) {
      this.$emit('material-change', { current_mat: newVal });
    },
  },

  methods: {
    ...mapGetters(['lockedForUser']),
    ...mapMutations(['setCurrentMat']),

    updateCurrentMaterial(event) {
      this.currentMaterial = event.target.value;
    },
  },
};
</script>
