<template>
  <!-- Draggable for Operands/Operators/Conditionals -->
  <div class="container">
    <div>
      <div v-for="operationItem in operationItems" :key="`${operationItem.title}`">
        <h2 v-text="operationItem.title" />

        <draggable
          v-model="operationItem.items"
          tag="ul"
          :group="{ name: 'variables', pull: 'clone', put: false }"
          class="operator-list"
          item-key="id"
        >
          <template #item="{ element: item }">
            <div class="item-input">
              <input
                v-if="item.variableType === 'input'"
                v-model="item.variableName"
                class="operator-item operator-item__input"
                placeholder="Enter a number"
              />
              <li v-else class="operator-item" v-text="item.displayName" />
            </div>
          </template>
        </draggable>

        <button
          v-if="operationItem.title === 'Operands'"
          class="operator-item"
          type="button"
          @click="addScalarValue"
          v-text="'➕ Operand'"
        />
      </div>
    </div>

    <div class="dropzones">
      <div>
        <h2>Expression</h2>
        <div class="expression-zone">
          <div
            v-if="!formData.expression.length"
            class="expression-zone__placeholder"
            v-text="'Create an expression by dragging and dropping operands and operators here.'"
          />
          <draggable v-model="formData.expression" tag="ul" group="variables" class="expression-list" item-key="id">
            <template #item="{ element: expressionItem }">
              <li
                class="expression-item"
                @dragstart="setDraggedItem(expressionItem, 'expression')"
                v-text="
                  expressionItem.variableType === 'input' ? expressionItem.variableName : expressionItem.displayName
                "
              />
            </template>
          </draggable>
        </div>
      </div>
      <!-- Draggable Rule -->
      <div>
        <h2>Rules</h2>
        <div class="expression-zone">
          <div
            v-if="!formData.rule.length"
            class="expression-zone__placeholder"
            v-text="'Create a rule by dragging and dropping operands and conditionals here.'"
          />
          <draggable v-model="formData.rule" tag="ul" group="variables" class="expression-list" item-key="id">
            <template #item="{ element: ruleItem }">
              <li
                class="expression-item"
                @dragstart="setDraggedItem(ruleItem, 'rule')"
                v-text="ruleItem.variableType === 'input' ? ruleItem.variableName : ruleItem.displayName"
              />
            </template>
          </draggable>
        </div>
      </div>
    </div>
  </div>

  <!-- Trash  -->
  <div class="action-button trash" @drop="deleteItem" @dragover.prevent>
    <span class="action-icon" dropzone="true">🗑️</span>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  name: 'ExpressionBuilder',

  components: {
    draggable,
  },

  emits: ['input'],

  data() {
    return {
      operationItems: [
        {
          title: 'Conditionals',
          items: [
            { displayName: '>', variableName: '>', variableType: 'conditional', id: 11, value: null },
            { displayName: '<', variableName: '<', variableType: 'conditional', id: 12, value: null },
            {
              displayName: '>=',
              variableName: '>=',
              variableType: 'conditional',
              id: 13,
              value: null,
            },
            { displayName: '<=', variableName: '<=', variableType: 'conditional', id: 14, value: null },
            { displayName: '==', variableName: '==', variableType: 'conditional', id: 15, value: null },
            { displayName: '!=', variableName: '!=', variableType: 'conditional', id: 16, value: null },
            { displayName: '&', variableName: '&', variableType: 'conditional', id: 17, value: null },
            { displayName: '|', variableName: '|', variableType: 'conditional', id: 18, value: null },
          ],
        },
        {
          title: 'Operators',
          items: [
            { displayName: '+', variableName: '+', variableType: 'operator', id: 4, value: null },
            { displayName: '-', variableName: '-', variableType: 'operator', id: 5, value: null },
            { displayName: '*', variableName: '*', variableType: 'operator', id: 6, value: null },
            { displayName: '/', variableName: '/', variableType: 'operator', id: 7, value: null },
            { displayName: '(', variableName: '(', variableType: 'operator', id: 8, value: null },
            { displayName: ')', variableName: ')', variableType: 'operator', id: 9, value: null },
            { displayName: '^', variableName: '^', variableType: 'operator', id: 10, value: null },
          ],
        },
        {
          title: 'Operands',
          items: [
            { displayName: 'Cost per Part', variableName: 'cost_pp', variableType: 'variable', id: 0, value: 0 },
            { displayName: 'Lot Size', variableName: 'lot_size', variableType: 'variable', id: 1, value: 0 },
            { displayName: 'Margin', variableName: 'margin', variableType: 'variable', id: 2, value: 0 },
            { displayName: 'Overhead', variableName: 'overhead', variableType: 'variable', id: 3, value: 0 },
          ],
        },
      ],

      formData: {
        expression: [],
        rule: [],
      },

      currentDraggedItem: { item: null, type: '' },
    };
  },

  computed: {
    // expressionVerbose() {
    //   return this.formData.expression.map(item => item.displayName).join(' ');
    // },

    expressionReal() {
      return this.formData.expression.map(item => item.variableName).join(' ');
    },

    // verboseRule() {
    //   return this.formData.rule.map(item => item.displayName).join(' ');
    // },

    realRule() {
      return this.formData.rule.map(item => item.variableName).join(' ');
    },

    // expressionEvaluated() {
    //   return this.formData.expression
    //     .map(item => {
    //       if (item.variableType === 'variable') {
    //         // For variables, use their values
    //         return item.value;
    //       } else if (item.variableType === 'operator' || item.variableType === 'conditional') {
    //         // For operators, use their displayName
    //         return item.displayName;
    //       }
    //       // Handle other cases if needed
    //       return '';
    //     })
    //     .join(' ');
    // },
  },

  watch: {
    formData: {
      handler() {
        this.$emit('input', {
          expression: this.expressionReal,
          rule: this.realRule,
        });
      },

      deep: true,
    },
  },

  methods: {
    setDraggedItem(operator, type) {
      this.currentDraggedItem = { item: operator, type };
    },

    deleteItem() {
      if (this.currentDraggedItem.item) {
        if (this.currentDraggedItem.type === 'expression') {
          this.formData.expression = this.formData.expression.filter(
            item => item.id !== this.currentDraggedItem.item.id
          );
        } else if (this.currentDraggedItem.type === 'rule') {
          this.formData.rule = this.formData.rule.filter(item => item.id !== this.currentDraggedItem.item.id);
        }
        this.currentDraggedItem = null;
      }
    },

    addScalarValue() {
      const operands = this.operationItems.find(item => item.title === 'Operands');
      if (operands) {
        operands.items.push({
          displayName: '',
          variableName: '',
          variableType: 'input',
          id: this.generateRandomId(),
          value: 0,
        });
      }
    },

    generateRandomId() {
      const randomId = Math.random().toString(16).substring(2);
      return randomId;
    },
  },
};
</script>

<style lang="scss" scoped>
.formula {
  font-family: 'Courier New', monospace;
  font-size: large;
  font-weight: bold;
}

.operator-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  min-height: 50px;
}

.operator-item {
  text-align: center;
  border: 1px solid #ddd;
  padding: 10px;
  margin: 5px;
  background-color: #f5f5f5;
  border-radius: 4px;
  cursor: move;

  &__input {
    width: fit-content;

    &:hover {
      border: 1px solid #aaa;
    }

    &:focus {
      border: 1px solid #aaa !important;
    }
  }
}

.item-input {
  display: flex;
  gap: 8px;
}

.expression-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}

.expression-item {
  border: 1px solid #ddd;
  padding: 10px;
  margin: 5px;
  background-color: #d9edf7;
  cursor: move;
}

.action-button {
  width: 50px;
  height: 50px;
  background-color: #f2f2f2;
  border: 1px solid #ddd;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
}

.expression-zone {
  border: 1px solid #ddd;
  padding: 10px;
  min-height: 50px;
  max-width: 100%;
  overflow-x: auto;
}

.expression-zone__placeholder {
  color: #aaa;
  font-style: italic;
  text-align: center;
}

.action-icon {
  font-size: 40px;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 32px;
}

.trash {
  margin: auto;
  margin-bottom: 32px;
}

.dropzones {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
</style>
