<template>
  <div class="pricing">
    <h1 class="font-bold">Pricing Configurator [Beta]</h1>

    <h2 class="mt-10">Create a new Pricing Configuration or select an existing one.</h2>
    <div>
      <label for="pricingOptionDropdown" class="mt-10">Select a pricing configuration:</label>
      <select
        id="pricingOptionDropdown"
        v-model="selectedPricingOptionUid"
        class="h-40 focus:h-40 text-13 rounded-4"
        @change="getPricingList"
      >
        <option value="">Create a new pricing configuration</option>
        <option value="CombinedConfig">Create a combined pricing configuration</option>
        <option v-for="pricing in pricingOptionList" :key="pricing.uid" :value="pricing.uid">{{ pricing.name }}</option>
      </select>
    </div>

    <div class="mt-10">
      <h2>Pricing Configuration Name</h2>
      <SparkInput
        v-model.lazy="selectedPricingOptionName"
        name="selectedPricingOptionName"
        title="Please assign a descriptive name for the pricing configuration."
        placeholder="Pricing Configuration Name"
        :disabled="selectedPricingOptionName === 'CombinedConfig'"
        custom="spark-input h-40 focus:h-40 text-13 rounded-4"
        @change="savePricingConfig()"
      />
    </div>

    <div class="mt-10">
      <h2>Minimum Price per Part [€]:</h2>
      <SparkInput
        v-model.lazy="minPrice"
        name="minPrice"
        title="Please assign a minimum price for the pricing configuration."
        placeholder="Minimum Price per Part [€]"
        custom="spark-input h-40 focus:h-40 text-13 rounded-4"
        @change="savePricingConfig()" 
      />
    </div>

    <div class="mt-10">
      <h2>Lower and Upper Price Deviation [%]:</h2>
      <SparkInput
        v-model.lazy="lowerPriceDeviation"
        name="lowerPriceDeviation"
        title="You can assign a lower deviation for the pricing configuration."
        placeholder="Lower Price Deviation [%]"
        custom="spark-input h-40 focus:h-40 text-13 rounded-4"
        @change="savePricingConfig()" 
      />
      <SparkInput
        v-model.lazy="upperPriceDeviation"
        name="upperPriceDeviation"
        title="You can assign an upper deviation for the pricing configuration."
        placeholder="Upper Price Deviation [%]"
        custom="spark-input h-40 focus:h-40 text-13 rounded-4"
        @change="savePricingConfig()" 
      />
    </div>

    <div v-if="selectedPricingOptionUid === 'CombinedConfig'" class="mt-10">
      <h2>Create a Combined Pricing Configuration</h2>
      <div v-for="pricing in pricingOptionList" :key="pricing.uid" class="mt-10">
        <label v-if="pricing.combined_configs.length === 0" >
          <input v-model="selectedPricingOptions" class="ml-20" type="checkbox" :value="pricing.uid" />
          {{ pricing.name }}
        </label>
      </div>
      <div class="action-button" @click="addPricing">
        <span class="action-icon" title="Create a Combined Pricing Configuration">+</span>
      </div>
    </div>

    <div v-if="combinedConfigUid && combinedConfigList.length != 0 && selectedPricingOptionUid != ''" class="mt-10">
      <h2>You have selected a Combined Pricing Configuration.</h2>
      <summary>It combines the following configurations: {{ combinedConfigList.join(', ') }}</summary>
    </div>

    <div>
      <div v-if="selectedPricingOptionUid && selectedPricingOptionUid != 'CombinedConfig'" class="mt-10 float-end flex align-baseline">
        <h2 class="mt-10">Delete this Pricing Configuration</h2>
        <SparkButton class="ml-10" variant="outlined" @click="deleteConfig(selectedPricingOptionUid)">
          <div  v-text="'✕'"/>
        </SparkButton>
      </div>
    </div>

    <div v-if="(!combinedConfigUid && selectedPricingOptionUid != 'CombinedConfig') | (combinedConfigUid && selectedPricingOptionUid==='')">

      <ExpressionBuilder @input="assignInput" />

      <!-- Add pricing  -->
      <div class="action-button" @click="addPricing">
        <span class="action-icon" title="Add Expression to Pricing Configuration">+</span>
      </div>


      <!-- Pricing Configurations -->
      <div>
        <h2>Pricing Expression Order</h2>
        <summary>{{ numberOfPricings }} Pricing Expressions</summary>
        <div v-if="pricingList.length > 0">
          <draggable :list="pricingList" item-key="order_index" @end="reindexPricings">
            <template #item="{ element }">
              <div class="draggable-item" :class="[{ 'active-pricing': element.order_index === activePricing }]">
                <div class="drag-icon">&#x2630;</div>
                <table>
                  <tr>
                    <th>Expression</th>
                    <th>Rule</th>
                    <th />
                  </tr>
                  <tr>
                    <td>
                      <template v-if="editingIndex === element.order_index">
                        <input v-model="tempExpression" type="text" />
                      </template>
                      <template v-else>
                        {{ element.expression }}
                      </template>
                    </td>
                    <td>
                      <template v-if="editingIndex === element.order_index">
                        <input v-model="tempRule" type="text" />
                      </template>
                      <template v-else>
                        {{ element.rule }}
                      </template>
                    </td>
                    <td>
                      <SparkButton variant="outlined" @click="deletePricing(element.uid)">
                        <div  v-text="'✕'"/>
                      </SparkButton>
                    
                      <SparkButton v-if="editingIndex === element.order_index" variant="outlined" class="edit-button" @click="saveEdit(element.order_index)">
                        <div class="fas fa-save"/>
                      </SparkButton>
                    
                      <SparkButton v-else variant="outlined" class="edit-button ml-10" @click="editPricing(element, element.order_index)">
                        <div class="fas fa-edit"/>
                      </SparkButton>
                    </td>
                  </tr>
                </table>
              </div>
            </template>
          </draggable>
        </div>
        <div v-else>
          <p>No pricings available.</p>
        </div>
      </div>

      <div class="evaluate mt-16">

        <h2>Test this Pricing Configuration.</h2>
        <div class="mt-10">
          <label for="partDropdown">Select a Part:</label>
          <select
            id="partDropdown"
            v-model="selectedPartId"
            class="h-40 focus:h-40 text-13 rounded-4"
            @change="getProcessChainList"
          >
            <option v-for="part in partList" :key="part.part_id" :value="part.part_id">{{ part.name }}</option>
          </select>
        </div>

        <div v-if="selectedPartId" class="mt-10">
          <label for="processChainDropdown">Select a Process Chain:</label>
          <select id="processChainDropdown" v-model="selectedProcessChainId" class="h-40 focus:h-40 text-13 rounded-4">
            <option
              v-for="processChain in processChainList"
              :key="processChain.process_chain_id"
              :value="processChain.process_chain_id"
            >
              {{ processChain.name }}
            </option>
          </select>
          <!-- <input v-if="selectedProcessChainId" v-model="selectedProcessChainId" type="text" disabled /> -->
        </div>

        <!-- Evaluate  -->
        <div class="action-button" title="Evaluate Pricing Configuration" @click="evaluatePricing">
          <span class="action-icon">🟰</span>
        </div>

        <div class="calculated_price">
          <div>Calculated Price: {{ $formatTwoDecimalPlaces(calculatedPrice) + ' €' }}</div>
          <div>Price Range: {{ $formatTwoDecimalPlaces(lowerPrice) + ' - ' + $formatTwoDecimalPlaces(upperPrice) + ' €' }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapState } from 'vuex';

import SparkButton from '../../components/SparkComponents/SparkButton.vue';
import SparkInput from '../../components/SparkComponents/SparkInput.vue';

import ExpressionBuilder from './components/ExpressionBuilder.vue';
export default {
  name: 'Pricing',

  components: {
    ExpressionBuilder,
    draggable,
    SparkButton,
    SparkInput,
  },

  data() {
    return {
      partList: null,
      selectedPartId: null,
      processChainList: null,
      selectedProcessChainId: null,
      selectedPricingOptionName: '',
      selectedPricingOptionUid: '',
      pricingOptionList: [],
      pricingList: [],
      numberOfPricings: 0,
      expression: '',
      rule: '',
      calculatedPrice: null,
      lowerPrice: null,
      upperPrice: null,
      activePricing: null,
      editingIndex: null,
      tempExpression: '',
      tempRule: '',
      minPrice: null,
      lowerPriceDeviation: null,
      upperPriceDeviation: null,
      selectedPricingOptions: [],
      combinedConfigUid: '',
      combinedConfigList: [],
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),
  },

  watch: {

    selectedPricingOptionUid() {
      if(this.selectedPricingOptionUid === 'CombinedConfig' || this.selectedPricingOptionUid === '') {
        this.selectedPricingOptionName = '';
        this.minPrice = 0;
        this.lowerPriceDeviation = 0;
        this.upperPriceDeviation = 0;
      }
    },
  },

  async mounted() {
    this.getPricingOptionList();
    this.getPricingList();
    this.getPartList();
  },

  methods: {

    async savePricingConfig() {
      if (this.selectedPricingOptionUid && this.selectedPricingOptionUid !== 'CombinedConfig') {

        if (!this.minPrice || this.minPrice === null || this.minPrice === undefined || this.minPrice < 0) {
            this.minPrice = 0;
        } else if (this.minPrice > 10000000) {
          this.$root.notify('error', 'Error', 'Please lower the minimum price.');
          return;
        }

        if (!this.lowerPriceDeviation || this.lowerPriceDeviation === null || this.lowerPriceDeviation === undefined) {
          this.lowerPriceDeviation = 0;
        }

        if (!this.upperPriceDeviation || this.upperPriceDeviation === null || this.upperPriceDeviation === undefined) {
          this.upperPriceDeviation = 0;
        }

        let formData = {
          pricing_config_name: this.selectedPricingOptionName,
          min_price: this.minPrice,
          lower_deviation: this.lowerPriceDeviation/100,
          upper_deviation: this.upperPriceDeviation/100,
        };      

        try {
          await this.axiosInstance.put(`api/v1/pricing-config/${this.selectedPricingOptionUid}/`, formData);
          // await this.getPricingList(); // Update the pricing list
          await this.getPricingOptionList();
          this.$root.notify('success', '', 'Price Configuration updated.', 3000);
        } catch (error) {
          if (error.response.status === 400) {
            this.$root.notify('error', 'Error', 'Pricing Configuration could not be updated.');
          }
          return;
        }

      }
    },

    async evaluatePricing() {
      if (!this.selectedProcessChainId) {
        this.$root.notify('error', 'Error', 'Fill out all fields');
        return;
      }

      if (this.selectedPricingOptionUid) {
        try {
          this.getPricingOptionList();
          let formData = new FormData();
          formData.append('min_price', this.minPrice);
          formData.append('lower_deviation', this.lowerPriceDeviation/100);
          formData.append('upper_deviation', this.upperPriceDeviation/100);
          await this.axiosInstance
            .post(`api/v1/pricing-config/${this.selectedProcessChainId}/${this.selectedPricingOptionUid}/`, formData)
            .then(response => {
              this.calculatedPrice = response.data.result; // Bind the calculated price
                this.lowerPrice = response.data.lower_price || this.calculatedPrice;
              this.upperPrice = response.data.upper_price || this.calculatedPrice;
              this.activePricing = response.data.active_pricing_idx; // Order index of the active pricing
              this.variablesObject = response.data;
              this.expressionObject.forEach(item => {
                if (item.variableType === 'variable') {
                  // Set the value from the dataObject based on variableName
                  item.value = this.variablesObject[item.variableName];
                }
              });
            })
            .catch(error => {
              console.log(error.response);
            });
        } catch (error) {
          console.error(error);
          return;
        }
      }
    },

    async addPricing() {
      if (!this.expression && this.selectedPricingOptionUid != 'CombinedConfig') {
        this.$root.notify('error', 'Error', 'Please add an expression');
        return;
      }

      if (!this.minPrice || this.minPrice === null || this.minPrice === undefined || this.minPrice < 0) {
          this.minPrice = 0;
      } else if (this.minPrice > 10000000) {
        this.$root.notify('error', 'Error', 'Please lower the minimum price.');
        return;
      }
      
      if (!this.lowerPriceDeviation || this.lowerPriceDeviation === null || this.lowerPriceDeviation === undefined) {
        this.lowerPriceDeviation = 0;
      }

      if (!this.upperPriceDeviation || this.upperPriceDeviation === null || this.upperPriceDeviation === undefined) {
        this.upperPriceDeviation = 0;
      }

      if (!this.selectedPricingOptionUid || this.selectedPricingOptionUid === 'CombinedConfig') {
        try {
          let formData = new FormData();
          formData.append('pricing_config_name', this.selectedPricingOptionName);
          formData.append('min_price', this.minPrice);
          formData.append('lower_deviation', this.lowerPriceDeviation/100);
          formData.append('upper_deviation', this.upperPriceDeviation/100);
          formData.append('combined_config', this.selectedPricingOptions);

          const response = await this.axiosInstance.post(`api/v1/pricing-config/`, formData);
          await this.getPricingList(); // Update the pricing list
          await this.getPricingOptionList();
          if (this.selectedPricingOptionUid === 'CombinedConfig') {
            this.combinedConfigUid = response.data.uid;
          }
          this.selectedPricingOptionUid = response.data.uid;
        } catch (error) {
          console.error(error);
          return;
        }
      }

      if (this.selectedPricingOptionUid != this.combinedConfigUid) {
        let formData = new FormData();
        formData.append('pricing_config_uid', this.selectedPricingOptionUid);
        formData.append('expression', this.expression);
        formData.append('rule', this.rule);
        formData.append('min_price', this.minPrice);
        formData.append('lower_deviation', this.lowerPriceDeviation/100);
        formData.append('upper_deviation', this.upperPriceDeviation/100);

        try {
          await this.axiosInstance.post(`api/v1/pricing-list/${this.selectedPricingOptionUid}/`, formData);
          await this.getPricingList(); // Update the pricing list
          await this.getPricingOptionList();
        } catch (error) {
          if (error.response.status === 400) {
            this.$root.notify('error', 'Error', 'Bad expression or rule.');
          }
          return;
        }
      } else {
        this.selectedPricingOptionUid = '';
        this.selectedPricingOptionName = '';
        this.combinedConfigUid = '';
        this.getPricingOptionList();
      }
    },

    assignInput(formData) {
      this.expression = formData.expression;
      this.rule = formData.rule;
    },

    async getPartList() {
      await this.axiosInstance.get('api/v1/part-list/').then(response => {
        this.partList = response.data;
      });
    },

    getProcessChainList() {
      if (this.selectedPartId) {
        this.axiosInstance.get(`api/v1/process-chain-list/${this.selectedPartId}/`).then(response => {
          this.processChainList = response.data;
        });
      }
    },

    async getPricingOptionList() {
      try {
        const response = await this.axiosInstance.get(`api/v1/pricing-config-list/`);
        this.pricingOptionList = response.data.filter(pricing => pricing.uid !== 'cc3d0e78623d4ad58f66b3a61d4d2d59'); // filter out the default option, it should not be ediable in the cofigurator
        this.activePricing = null;
      } catch (error) {
        console.error('Error fetching pricing options:', error);
      }
    },

    async getPricingList() {
      if (this.selectedPricingOptionUid) {
        const selectedPricingConfig = this.pricingOptionList.find(item => item.uid === this.selectedPricingOptionUid);
        this.selectedPricingOptionName = selectedPricingConfig ? selectedPricingConfig.name : null;
        this.minPrice = selectedPricingConfig ? selectedPricingConfig.min_price : null;
        this.lowerPriceDeviation = selectedPricingConfig ? selectedPricingConfig.lower_deviation*100 : null;
        this.upperPriceDeviation = selectedPricingConfig ? selectedPricingConfig.upper_deviation*100 : null;
        this.axiosInstance.get(`api/v1/pricing-list/${this.selectedPricingOptionUid}/`).then(response => {
          this.pricingList = response.data;
          this.numberOfPricings = this.pricingList.length;
          this.checkCombinedConfigs();
        });
      } else {
        this.pricingList = [];
        this.numberOfPricings = 0;
      }
    },

    reindexPricings() {
      let formData = {
        pricing_order: {},
      };
      this.pricingList.forEach((pricing, index) => {
        pricing.order_index = index;
        formData.pricing_order[index] = pricing.uid;
      });

      this.axiosInstance.put(`api/v1/pricing-list/${this.selectedPricingOptionUid}/`, formData);
      this.activePricing = null;
    },

    deletePricing(uid) {
      this.axiosInstance.delete(`api/v1/pricing/${uid}/`).then(() => {
        this.getPricingList();
        this.getPricingOptionList();
      });
    },

    editPricing(element, index) {
      this.editingIndex = index;
      this.tempExpression = element.expression;
      this.tempRule = element.rule === null ? '' : element.rule;
    },

    async saveEdit(index) {
      const item = this.pricingList[index];
      this.editingIndex = null; // End editing mode

      let formData = new FormData();
      formData.append('expression', this.tempExpression);
      formData.append('rule', this.tempRule);
      try {
        await this.axiosInstance.put(`api/v1/pricing-edit/${item.uid}/`, formData).then(() => {
          this.getPricingList();
          // this.getPricingOptionList();
        });
      } catch (error) {
        this.$root.notify('error', `${error.response.data.error_message}`,  6000);
      }
    },

    checkCombinedConfigs() {
      const selectedPricing = this.pricingOptionList.find(pricing => pricing.uid === this.selectedPricingOptionUid);
      if (selectedPricing && selectedPricing.combined_configs.length > 0) {
        this.combinedConfigUid = this.selectedPricingOptionUid;
        this.combinedConfigList = selectedPricing.combined_configs.map(uid => {
          const combinedConfig = this.pricingOptionList.find(pricing => pricing.uid === uid);
          return combinedConfig ? combinedConfig.name : null;
        });
      } else {
        this.combinedConfigUid = '';
      }
    },

    deleteConfig(uid) {
      this.axiosInstance.delete(`api/v1/pricing-config/${uid}/`).then(() => {
        this.selectedPricingOptionUid = '';
        this.selectedPricingOptionName = '';
        this.getPricingOptionList();
        this.combinedConfigUid = '';
        this.getPricingList();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
td {
  padding: 5px;
}

.pricing {
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.action-button {
  margin: auto;
  width: 50px;
  height: 50px;
  background-color: #f2f2f2;
  border: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
}

.action-icon {
  font-size: 40px;
}

.draggable-item {
  display: flex;
  align-items: center; /* Align icon and template details horizontally */
  margin-top: 10px;
}

.draggable-item td {
  min-width: 200px;
}

.drag-icon {
  margin-right: 10px; /* Add some spacing between icon and template details */
}

.active-pricing {
  padding: 0 5px;
  border: 3px solid #97ab13;
}
</style>
