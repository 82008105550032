<template>
  <div>
    <h4>Pipeline State Settings</h4>
    <p class="small">Reeorder using Drag & Drop</p>
    <div class="flex wrap">
      <draggable :list="pipeline" item-key="order_index" class="flex wrap" @end="reindexStati">
        <template #item="{ element }">
          <div class="draggable-item">
            <div v-if="statusToEdit === element.uid" class="status flex">
              <input v-model="element.name" type="text" class="spark-new-input" />
              <i class="fas fa-trash-alt icon-task" @click="deleteCheck(element.uid, element.name)" />
              <i class="fas fa-check icon-task" @click="editStatus(element.name, element.uid)" />

              <i class="fas fa-times icon-task" @click="discard(element.name, element.uid)" />
            </div>
            <div v-else class="flex" @click="statusToEdit = element.uid">
              <span class="status flex"
                >{{ element.name }}
                <i class="fas fa-pen icon-task" />
              </span>
            </div>
          </div>
        </template>
        <i v-if="order_index < amoundOfStati - 1" class="fas fa-arrow-right icon-arrow" />
      </draggable>
      <div v-if="statusToEdit == 'new'" class="flex margin-top">
        <input v-model="newStatusName" type="text" class="spark-new-input" />
        <i class="fas fa-check icon-task" @click="addNewStatus()" />
        <i class="fas fa-times icon-task" @click="discard()" />
      </div>
      <span v-else><i class="fas fa-plus icon-arrow icon-button margin-top" @click="openForm()" /> </span>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  name: 'PipelineSettings',

  components: { draggable },

  data() {
    return {
      statusToEdit: '',
      newStatusName: '',
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),
    ...mapState(['pipeline', 'popup']),

    amoundOfStati() {
      return this.pipeline?.length;
    },
  },

  watch: {
    popup(popup) {
      if (popup?.clicked == 'ok') {
        if (popup?.key == this.$options.name + 'DeletePipelineStatus') {
          this.deleteStatus(popup.additionalData);
          this.triggerPopup('');
        }
      } else if (popup?.clicked == 'cancel' || popup?.clicked == 'close') {
        this.triggerPopup('');
      }
    },
  },

  mounted() {
    this.fetchPipelineStatuses();
  },

  methods: {
    ...mapActions([
      'fetchPipelineStatuses',
      'addPipelineStatus',
      'deletePipelineStatus',
      'updatePipelineStatus',
      'updatePipelineStatusOrder',
    ]),

    ...mapMutations(['triggerPopup']),

    async addNewStatus() {
      await this.addPipelineStatus({ name: this.newStatusName });
      this.newStatusName = '';
      this.statusToEdit = '';
    },

    async editStatus(name, uid) {
      let formData = { uid: uid, name: name };
      await this.updatePipelineStatus(formData);
      this.statusToEdit = '';
    },

    async deleteStatus(uid) {
      this.statusToEdit = '';
      await this.deletePipelineStatus(uid);
    },

    openForm() {
      this.statusToEdit = 'new';
    },

    discard() {
      this.statusToEdit = '';
      this.fetchPipelineStatuses();
    },

    async reindexStati() {
      let formData = {
        pipeline_order: {},
      };
      this.pipeline.forEach((pipelineStatus, index) => {
        pipelineStatus.order_index = index;
        formData.pipeline_order[index] = pipelineStatus.uid;
      });

      await this.updatePipelineStatusOrder(formData);
    },

    deleteCheck(uid, name) {
      this.triggerPopup({
        key: this.$options.name + 'DeletePipelineStatus',
        show: true,
        title: 'Delete a status',
        message:
          'Do you really want to delete ' + name + ' ? If it is set as a status to a part, this will be also deleted.',
        additionalData: uid,
        buttons: true,
        buttonContent: [
          { text: 'Cancel', type: 'outlined', value: 'cancel' },
          { text: 'Ok', type: 'secondary', value: 'ok' },
        ],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.small {
  margin-bottom: 30px;
  font-size: var(--9px);
  margin-left: 0px;
  padding-left: 0px;
}

.margin-top {
  margin-top: 10px;
}

.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.wrap {
  flex-wrap: wrap;
}

.status {
  border: 1px solid var(--light-color);
  border-radius: 5px;
  margin-right: 30px;
  margin-top: 3px;
  padding: 20px;
  &:hover {
    cursor: grab;
  }
}
.icon-task {
  margin: 0px 20px;
  &:hover {
    cursor: pointer;
    color: var(--accent-color);
  }
}

.icon-arrow {
  padding: 10px 20px;
  &:hover {
    cursor: pointer;
  }
}

input {
  min-width: 150px;
  margin: 0px;
}
</style>
