<template>
  <div class="border border-gray-200 rounded-8 p-4">
    <div>
      <div class="header-title">
        <i v-if="lockedForUser" class="fas fa-lock" :title="lockedTitle" />
        <div class="checkbox-item">
          <SparkCheckbox v-model="initialRowsHidden" name="hidden-rows">
            <div class="text-13" v-text="'Hide 3D Spark templates'" />
          </SparkCheckbox>
        </div>
      </div>
    </div>
    <div v-if="!lockedForUser && Object.keys(templatesAndProfiles).length != 0">
      <div v-for="uid in sortedTemplatesAndProfiles" :key="uid" class="template-row">
        <TemplateRow
          v-if="templatesAndProfiles[uid]?.type == 'template'"
          :template="templatesAndProfiles[uid]"
          :loading-uid="loadingUid"
          :title="templatesAndProfiles[uid]?.name"
          @selected-template="createProcessChains(templatesAndProfiles[uid])"
        />
        <ProfileRow
          v-else-if="templatesAndProfiles[uid]?.name"
          :title="templatesAndProfiles[uid]?.name"
          :profile="templatesAndProfiles[uid]"
          :loading-uid="loadingUid"
          @selected-profile="createProcessChains(templatesAndProfiles[uid])"
        />

        <DropdownMenu
          class="margin-left"
          :list-options="createDropdownOptions(templatesAndProfiles[uid])"
          close-click-style="always"
          :show-caret="false"
          title-icon="fas fa-ellipsis-h"
          @update-option="choose($event, templatesAndProfiles[uid])"
        />
      </div>
    </div>

    <AddEditProfileModal
      :show="showEditModal"
      mode="edit"
      :external-profile="externalProfile"
      :profile="formData"
      :current-uid="currentUid"
      @save="editProfile"
      @close="closeEditModal"
    />
  </div>
</template>

<script>
import { parseISO, compareDesc } from 'date-fns';
import { mapState, mapGetters, mapMutations } from 'vuex';

import SparkCheckbox from '../../../SparkComponents/SparkCheckbox.vue';
import AddEditProfileModal from '../modals/AddEditProfileModal.vue';

import ProfileRow from './ProfileRow.vue';
import TemplateRow from './TemplateRow.vue';

import DropdownMenu from '@/components/Reusable/DropdownMenu.vue';

export default {
  name: 'SuggestionTable',

  components: { DropdownMenu, TemplateRow, ProfileRow, SparkCheckbox, AddEditProfileModal },

  data() {
    return {
      templatesAndProfiles: {},
      loadingUid: '',
      initialRowsHidden: true,
      sortedTemplatesAndProfiles: [],
      externalProfile: {},
      showEditModal: false,
      currentUid: '',
    };
  },

  computed: {
    ...mapState(['user', 'part']),
    ...mapState('application', ['lockedTitle', 'axiosInstance']),
    ...mapGetters(['lockedForUser']),

    username() {
      return this.user.username;
    },

    reloadTemplatesAndProfiles() {
      return this.$store.state.reloadTemplatesAndProfiles;
    },

    defaultAnalysisProfile() {
      return this.user.default_analysis_profile;
    },

    currentMatAnalysis() {
      return this.part.current_mat_analysis;
    },

    restrictions() {
      if (this.user.user_role === 'manager' && !this.user.is_external) {
        return [
          { label: 'Owner', value: 'owner' },
          { label: 'PRP', value: 'external' },
          { label: 'Organization', value: 'organization' },
        ];
      } else {
        return [{ label: 'Owner', value: 'owner' }];
      }
    },

    formData() {
      return {
        0: {
          db_name: 'name',
          label: 'Name',
          field: 'input-text',
          options: '',
          unit: '',
          required: true,
          value: '',
        },

        1: {
          db_name: 'restriction',
          label: 'Restriction',
          field: 'select',
          options: this.restrictions,
          unit: '',
          required: true,
          value: '',
          validityCheck: { external: '' },
        },
      };
    },

    cadUploadedOrCadless() {
      return this.part.part_id != 0 && (this.part.basename != '' || this.part.is_cadless);
    },
  },

  watch: {
    reloadTemplatesAndProfiles() {
      this.getAllOptions();
    },

    initialRowsHidden: {
      handler(value) {
        this.saveInStorage(value);
      },
    },

    currentMatAnalysis: {
      handler() {
        this.getMaterialPropsalTemplates();
      },

      deep: true,
    },
  },

  mounted() {
    this.initialRowsHidden = localStorage.getItem('hide-spark-templates') === 'true';
    this.getAllOptions();
  },

  methods: {
    ...mapMutations(['updateTemplatesAndProfiles']),
    ...mapMutations({
      updateStorePart: 'updatePart',
    }),

    async getAllOptions() {
      this.templatesAndProfiles = {};
      await this.getListTemplates();
      await this.getListProfiles();
      this.getMaterialPropsalTemplates();
    },

    createDropdownOptions(item) {
      let options = [];
      options.push({
        text: 'Delete',
        value: 'delete',
        icon: 'fas fa-trash',
        owner: item?.owner_username,
        disabled: item?.owner_username != this.username,
      });
      if (item?.type == 'profile') {
        options.push({
          text: 'Edit',
          value: 'edit',
          icon: 'fas fa-pen',
          disabled: false,
        });
      }
      if (item?.type == 'profile' && this.defaultAnalysisProfile != item.uid) {
        options.push({
          text: 'Set to default',
          value: 'set-default',
          icon: 'fas fa-compass',
          owner: item?.owner_username,
          disabled: false,
        });
      } else if (item?.type == 'profile' && this.defaultAnalysisProfile == item.uid) {
        options.push({
          text: 'Remove as default',
          value: 'unset-default',
          icon: 'fas fa-compass',
          owner: item?.owner_username,
          disabled: false,
        });
      }
      options.push({ text: 'Id: ' + item?.uid, disabled: true });
      return options;
    },

    async getListTemplates() {
      await this.axiosInstance
        .get('api/v1/process-chain-template-list/')
        .then(response => {
          let templates = {};
          templates = this.filterByRestriction(response.data, null);
          templates = this.addType(templates, 'template');
          this.templatesAndProfiles = { ...this.templatesAndProfiles, ...templates };
          this.sortedTemplatesAndProfiles = this.orderByDate(this.templatesAndProfiles);
        })
        .catch(error => {
          console.error(error);
        });
    },

    getMaterialPropsalTemplates() {
      this.templatesAndProfiles = { ...this.templatesAndProfiles, ...this.currentMatAnalysis };
      this.sortedTemplatesAndProfiles = this.orderByDate(this.templatesAndProfiles);
    },

    async getListProfiles() {
      this.axiosInstance.get(`/api/v1/analysis-profile-list/`).then(response => {
        let profiles = {};
        profiles = this.filterByRestriction(response.data, null);
        this.setExternalProfile(profiles);
        profiles = this.addType(profiles, 'profile');
        this.templatesAndProfiles = { ...this.templatesAndProfiles, ...profiles };
        this.sortedTemplatesAndProfiles = this.orderByDate(this.templatesAndProfiles);
      });
    },

    setExternalProfile(profiles) {
      this.externalProfile = {};
      Object.keys(profiles).forEach(uid => {
        if (profiles[uid].restriction == 'external') {
          this.externalProfile = profiles[uid];
        }
      });
    },

    filterByRestriction(obj, restriction) {
      if (this.initialRowsHidden) {
        return Object.fromEntries(Object.entries(obj).filter(([, value]) => value.restriction != restriction));
      } else {
        return obj;
      }
    },

    orderByDate(obj) {
      this.sortedTemplatesAndProfiles = Object.keys(obj).sort((keyA, keyB) => {
        const dateA = parseISO(obj[keyA].updated);
        const dateB = parseISO(obj[keyB].updated);
        return compareDesc(dateA, dateB);
      });
      return this.sortedTemplatesAndProfiles;
    },

    addType(obj, type) {
      Object.keys(obj).forEach(key => {
        obj[key]['type'] = type;
      });
      return obj;
    },

    // This one should initialize a real process chain
    async createProcessChains(item) {
      if (this.cadUploadedOrCadless) {
        this.loadingUid = item.uid;
        if (item.type === 'template') {
          if (item.owner_username === 'material_proposal') {
            let formData = { mat_id: item.material_uid };
            this.axiosInstance.put(`/api/v1/part/${this.part.part_id}/`, formData).then(response => {
              this.updateStorePart(response.data);
              this.boolProhibitRapidMaterialChange = false;
              this.loadingUid = '';
            });
          } else {
            let formData = { part_id: this.part.part_id, process_chain_template_id: item.uid };
            this.axiosInstance.post(`api/v1/process-chain/`, formData).then(() => {
              this.loadingUid = '';
            });
          }
        } else if (item.type === 'profile') {
          let formData = {
            part_id: this.part.part_id,
            profile_id: item.uid,
          };
          this.axiosInstance
            .post(`/api/v1/process-chains-from-analysis-profile/`, formData)
            .then(() => {
              this.loadingUid = '';
            })
            .catch(error => {
              console.log(JSON.stringify(error));
              if (error.response.status == 403) {
                this.$root.notify('warning', 'Permission Denied', error.response.data, 3000);
              }
              this.loadingUid = '';
            });
        }
      } else {
        this.loadingUid = 'abort';
        this.$root.notify(
          'warning',
          'CAD File or part dimensions missing',
          'Please go back to the input page to propose part dimension data.',
          6000
        );
      }
    },

    async setDefaultAnalysisProfile(uid) {
      this.axiosInstance.post(`/api/v1/default-analysis-profile/${uid}/`).then(() => {
        this.getAllOptions();
        this.$getUser(this.$keycloak, this.axiosInstance, this.$store);
      });
    },

    async openEditModal(profile) {
      this.currentUid = profile.uid;
      Object.keys(this.formData).forEach(key => {
        this.formData[key].value = profile[this.formData[key]['db_name']];
      });
      this.formData[1].validityCheck.external = await this.validityCheckExternalProfileText(profile.uid);
      this.showEditModal = true;
    },

    closeEditModal() {
      this.showEditModal = false;
    },

    async validityCheckExternalProfileText(profileUidToEdit) {
      let text = '';

      if (Object.keys(this.externalProfile).length !== 0 && profileUidToEdit !== this.externalProfile.uid) {
        text = "⚠️ Current PRP profile '" + this.externalProfile.name + "' will be unset.";
      }
      return text;
    },

    async editProfile(data) {
      let filledFormData = this.convert(data);
      await this.axiosInstance
        .put(`/api/v1/analysis-profile/${this.currentUid}/`, filledFormData)
        .then(() => {
          this.getAllOptions();
        })
        .catch(error => {
          this.$root.notify('error', 'Error', error.response.data.error_message, 6000);
        });
      if (Object.keys(this.externalProfile).length != 0) {
        if (filledFormData?.restriction === 'external' && this.externalProfile.uid !== this.currentUid) {
          this.removeProfileAsExternal();
        }
      }
    },

    convert(data) {
      let converted = {};
      Object.keys(data).forEach(key => {
        converted[data[key].db_name] = data[key].value;
      });
      return converted;
    },

    async removeProfileAsExternal() {
      let formData = {
        restriction: 'owner',
      };
      this.axiosInstance.put(`/api/v1/analysis-profile/${this.externalProfile.uid}/`, formData).then(() => {
        this.updateTemplatesAndProfiles(new Date());
        this.getAllOptions();
      });
    },

    choose(event, item) {
      if (event.value === 'delete' && event.owner === this.username) {
        this.delete(item);
      } else if (event.value == 'delete') {
        this.$root.notify('error', 'Authorization Error', 'You have no permission to delete this entry.', 6000);
      } else if (event.value == 'edit') {
        this.openEditModal(item);
      } else if (event.value == 'set-default') {
        this.setDefaultAnalysisProfile(item.uid);
      } else if (event.value == 'unset-default') {
        this.setDefaultAnalysisProfile('undefined');
      }
    },

    async delete(item) {
      delete this.templatesAndProfiles[item.uid];
      if (item.type === 'template') {
        await this.axiosInstance.delete(`api/v1/template-from-process-chain/${item.uid}/`);
      } else if (item.type === 'profile') {
        this.axiosInstance.delete(`/api/v1/analysis-profile/${item.uid}/`);
      }
      this.getAllOptions();
    },

    saveInStorage(value) {
      this.getAllOptions();
      localStorage.setItem('hide-spark-templates', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.template-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  margin-bottom: 5px;
  border-radius: 5px;
  border: 1px solid var(--light-color);
  max-width: 100%;
}

.invisible {
  visibility: hidden;
  cursor: pointer;
}

.template-row:hover .invisible {
  visibility: visible;
}

i {
  padding: 0px 5px;
  color: var(--primary-color);
  font-size: 1em;
}

.margin-top-30 {
  margin-top: 30px;
}

.header-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkbox-item {
  display: flex;
  font-size: var(--10px);
  align-items: center;
  width: 160px;
}

input[type='checkbox'] {
  height: 15px;
}

p {
  display: flex;
  flex-wrap: nowrap;
}

.margin-left {
  margin-left: auto;
  margin-right: 0;
}
</style>
