<template>
  <div class="container">
    <ComparisonPageMaterialTable @mat-id="updatePart($event)" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

import ComparisonPageMaterialTable from '@/components/PartWorkflow/Material/ComparisonPageMaterialTable.vue';
import { analysisFinished, computationRunning } from '@/composables/processChainHandling.js';

export default {
  name: 'MaterialCard',

  components: {
    ComparisonPageMaterialTable,
  },

  props: {
    processChainUid: { type: String, default: '' },
  },

  data() {
    return {
      newMatId: '',
      boolProhibitRapidMaterialChange: false,
    };
  },

  computed: {
    ...mapState(['investigationDetails', 'popup', 'part']),
    ...mapState('application', ['axiosInstance']),
    ...mapState({
      storePart: 'part',
    }),

    ...mapGetters(['fileGeometricPropertiesFinished', 'lockedForUser']),
    part: {
      get() {
        return this.storePart;
      },

      set(value) {},
    },

    analysisFinished() {
      return analysisFinished(this.activeInvestigationDetails.uid);
    },

    boolCadComputationFinished() {
      return this.fileGeometricPropertiesFinished;
    },

    activeInvestigationDetails() {
      return this.investigationDetails;
    },
  },

  watch: {
    popup: {
      handler(popup) {
        if (popup?.key == this.$options.name) {
          if (this.popup?.key == this.$options.name && this.popup?.title == 'Material Change') {
            if (this.popup?.clicked == 'ok') {
              this.triggerPopup('');
              let formData = { mat_id: this.newMatId };
              this.savePart(formData);
            } else if (this.popup?.clicked == 'cancel' || this.popup?.clicked == 'close') {
              this.triggerPopup('');
            }
          } else if (this.popup?.key == this.$options.name && this.popup?.title == 'Material Selection') {
            if (this.popup?.clicked == 'ok' || this.popup?.clicked == 'close') {
              let formData = { mat_id: this.newMatId };
              this.savePart(formData);
              localStorage.setItem('create-processchain-popup', !this.popup?.formData[0].value);
              this.triggerPopup('');
            }
          }
        }
      },

      deep: true,
    },
  },

  methods: {
    ...mapMutations([
      'triggerPopup',
      'updateUnsavedChanges',
      'changeInvestigationDetails',
      'changeProcessChainUids',
      'updateProcessChain',
      'changeProcessName',
    ]),

    ...mapMutations({
      updateStorePart: 'updatePart',
    }),

    updatePart(event) {
      this.prohibitionHandler();
      this.newMatId = event.mat_id;

      if (this.analysisFinished) {
        // this.triggerPopup({
        //   key: this.$options.name,
        //   show: true,
        //   title: 'Material Change',
        //   buttons: true,
        //   message:
        //     'You are about to change the material of an analyzed part. This will delete the results and require a new analysis. Are you sure to proceed?',
        //   buttonContent: [
        //     { text: 'Cancel', type: 'outlined', value: 'cancel' },
        //     { text: 'Ok', type: 'secondary', value: 'ok' },
        //   ],
        // });
        // return;
      }
      let noProcessChainsExisting = Object.keys(this.part.process_chains).length == 0;
      let showPopup = JSON.parse(localStorage.getItem('create-processchain-popup'));
      if (noProcessChainsExisting && showPopup) {
        this.triggerPopup({
          key: this.$options.name,
          show: true,
          title: 'Material Selection',
          message:
            'Three different Processes will be created. One with the material you selected, and additional one Milling Process and one Casting Process.',
          buttons: true,
          type: 'checkBox',
          formData: [{ label: "Don't show again.", value: false }],
          buttonContent: [{ text: 'Ok', type: 'secondary', value: 'ok' }],
        });
        return;
      }
      if (!this.boolCadComputationFinished && !this.part.is_cadless) {
        this.updateUnsavedChanges({ mat_id: this.newMatId });
      } else {
        this.updateStorePart(this.part);
        this.changeProcessChainUids({ primary_process_chain_id: '', benchmark_process_chain_id: '' });
        this.savePart({ mat_id: event.mat_id });
      }
    },

    async savePart(formData) {
      this.boolProhibitRapidMaterialChange = true;
      formData['name'] = '';
      if (this.processChainUid !== undefined) {
        // Update material on existing process chain
        let uid = this.processChainUid;
        this.axiosInstance
          .put(`/api/v1/process-chain/${uid}/`, formData)
          .then(response => {
            this.changeInvestigationDetails('');
            this.updateProcessChain(response.data);
            let name = '';
            this.changeProcessName({ uid: uid, name: name });
            this.boolProhibitRapidMaterialChange = false;
          })
          .catch(error => {
            this.boolProhibitRapidMaterialChange = false;
            this.$root.notify(
              'warning',
              'Material Selection Error',
              'An unexpected error occured with the material you selected. Please try again or try a different material. Please inform 3D Spark support if the error is persistent.',
              4000
            );
          });
      } else {
        // Create new process chain from material
        if (this.part.part_id != 0) {
          this.axiosInstance
            .put(`/api/v1/part/${this.part.part_id}/`, formData)
            .then(response => {
              // this.changeInvestigationDetails("");
              this.updateStorePart(response.data);
              this.boolProhibitRapidMaterialChange = false;
            })
            .catch(error => {
              this.boolProhibitRapidMaterialChange = false;
              this.$root.notify(
                'warning',
                'Material Selection Error',
                'An unexpected error occured with the material you selected. Please try again or try a different material. Please inform 3D Spark support if the error is persistent.',
                4000
              );
            });
        } else {
          this.$root.notify(
            'warning',
            'CAD File or part dimensions missing',
            'Please go back to the input page to propose part dimension data.',
            6000
          );
        }
      }
    },

    prohibitionHandler() {
      if (this.lockedForUser) {
        return;
      }
      if (computationRunning(this.activeInvestigationDetails.uid)) {
        this.$root.notify(
          'warning',
          'Analysis running',
          'You may select a new material once the analysis is finished.',
          4000
        );
        return;
      } else if (this.boolProhibitRapidMaterialChange) {
        this.$root.notify(
          'warning',
          'Material currently processing',
          'Please wait a few seconds before changing the material again.',
          4000
        );
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin-bottom: 300px;
}
</style>
