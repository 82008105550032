<template>
  <div class="container">
    <div style="margin: 10px" >
      <div style="display: flex; justify-content: space-between; align-items: baseline; margin-top: 20px">
        <h5>CO2 Equivalent</h5>
      </div>
      <table class="spark-table">
        <thead>
          <tr>
            <th>Emissions based on:</th>
            <th class="right">Machine Energy Consumption</th>
            <th class="right">Embodied Carbon</th>
            <th class="right">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="warning != null">
            <td colspan="4">{{ warning }}</td>
          </tr>
          <!-- Row for "Per Part" -->
          <tr>
            <td style="padding-left: 10px">Per Part</td>
            <td class="right">{{ format((co2?.co2_pp - co2?.co2_ec_pp)) }}</td> <!-- should be replaced by explicit value for co2_mc_pp: co2 per part by machine power consumption -->
            <td class="right">{{ format(co2?.co2_ec_pp) }}</td>
            <td class="right">{{ format(co2?.co2_pp) }} CO&#8322;-eq</td>
          </tr>
          <!-- Row for "Per Job" -->
          <tr>
            <td style="padding-left: 10px">Per Job</td>
            <td class="right">{{ format((co2?.co2_pj - co2?.co2_ec_pj)) }}</td> <!-- should be replaced by explicit value for co2_mc_pj: co2 per job by machine power consumption -->
            <td class="right">{{ format(co2?.co2_ec_pj) }}</td>
            <td class="right">{{ format(co2?.co2_pj) }} CO&#8322;-eq</td>
          </tr>
          <!-- Row for "Per Lot" -->
          <tr>
            <td style="padding-left: 10px">Per Lot</td>
            <td class="right">{{ format((co2?.co2_pl - co2?.co2_ec_pl)) }}</td> <!-- should be replaced by explicit value for co2_mc_pl: co2 per lot by machine power consumption -->
            <td class="right">{{ format(co2?.co2_ec_pl) }}</td>
            <td class="right">{{ format(co2?.co2_pl) }} CO&#8322;-eq</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import useNumberFormatting from '@/composables/numberFormatting.js';

export default {
  name: 'CarbonEquivalent',

  data() {
    return {
      warningNoPowerConsumption: 'Please add average power consumption of your machine to calculate these values.',
      warningRecalculate: 'Please consider to reanalyze this part to calculate these values.',
    };
  },

  computed: {
    ...mapState(['part', 'investigationDetails']),
    ...mapState('application', ['axiosInstance']),

    warning() {
      if (this.machineEnergyConsumption == null && this.co2NotCalculated) {
        return this.warningNoPowerConsumption;
      } else if (this.machineEnergyConsumption != null && this.co2NotCalculated) {
        return this.warningRecalculate;
      } else {
        return null;
      }
    },

    activeProcessChain() {
      let allChains = this.part.process_chains;
      return allChains[this.activeInvestigationDetails.uid];
    },

    activeInvestigationDetails() {
      return this.investigationDetails;
    },

    machineEnergyConsumption() {
      let uid = this.activeProcessChain?.mac_id;
      if (uid != undefined) {
        return this.getMachinePowerConsumption(uid);
      }
    },

    co2NotCalculated() {
      if (this.co2?.co2_pp == 0 && this.co2?.co2_pl == 0 && this.co2?.co2_pj == 0) return true;
      else {
        return false;
      }
    },

    co2() {
      return this.activeProcessChain?.co2;
    },
  },

  methods: {
    getMachinePowerConsumption(uid) {
      this.axiosInstance.get(`api/v1/machine/${uid}/`).then(response => {
        return response.data.power_consumption;
      });
    },

    format(value, showUnit=true) {
      if (this.co2NotCalculated) {
        return 'n/a';
      } else {
        let unit = ' kg';
        if (value < 0.1) {
          value = value * 1000;
          unit = ' g';
        }
        // this if clause added to not display a negative machine power consumption, if only the embodied carbon is defined (co2 = co2 - co2_ec)
        if (value == 0) {
          value = 0;
          showUnit = false;
        }
        if (showUnit) {
          return useNumberFormatting(value, 2).number.toString() + unit;
        } else {
          return useNumberFormatting(value, 2).number.toString();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.right {
  text-align: right;
}

.price-header {
  font-weight: bold;
}

.spark-table {
  margin: unset;
}

.row1-col1 {
  grid-area: row1-col1;
  margin-bottom: 10px;
}
.row1-col2 {
  grid-area: row1-col2;
  margin-bottom: 10px;
}

.container {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'row1-col1 row1-col2';
}
</style>
