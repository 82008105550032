<template>
  <div class="batch-table">
    <div v-for="batch in batches" :key="batch">
      <div class="batch-title">
        <div class="icon-button" @click="select(batch.batch_pk)">{{ batch.batch_name }}</div>
        <SparkBadge v-if="batch.prp_order_uid" custom="!cursor-default" class="mx-2" variant="success"
          ><div v-text="'PRP'"
        /></SparkBadge>
        <SparkBadge
          v-if="batch.prp_order_uid"
          class="m-8"
          variant="outlined"
          @click="openOrderDetailsModal(batch.prp_order_uid)"
          ><div v-text="'View details'"
        /></SparkBadge>
        <div v-if="selectedBatchId === batch.batch_pk" class="batch-buttons">
          <AnalysisButton v-if="showAnalysisButton" :checked-parts="checkedParts" />
          <i class="icon-button fas fa-trash" @click="confirmDelete(batch.batch_pk)" />
          <i class="icon-button fas fa-times" @click="selectedBatchId = ''" />
        </div>
      </div>
      <div v-if="selectedBatchId === batch.batch_pk" class="batch-content">
        <PartTable
          :filter-key="Number(batch.batch_pk)"
          :show-title="false"
          @update:checkedParts="handleCheckedPartsUpdate"
        />
        <CalibrationButton v-show="user.user_role === 'manager'" :batch-id="selectedBatchId" />
      </div>
      <div v-else class="border-bottom" />
    </div>
    <OrderDetailsModal
      :order-details="currentOrderDetails"
      :combo-categories="currentOrderDetails.prp_combo_categories"
      :text-categories="currentOrderDetails.prp_text_categories"
      :show="showOrderDetailsModal"
      @close="closeOrderDetailsModal"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

import AnalysisButton from './AnalysisButton.vue';
import CalibrationButton from './CalibrationButton.vue';
import OrderDetailsModal from './OrderDetailsModal.vue';

import defaultImage from '@/assets/img/Logo_Transparent.png';
import PartTable from '@/components/PartLibrary/PartTable.vue';
import SparkBadge from '@/components/SparkComponents/SparkBadge.vue';

export default {
  name: 'BatchTable',

  components: {
    PartTable,
    CalibrationButton,
    AnalysisButton,
    SparkBadge,
    OrderDetailsModal,
  },

  emits: ['selected-batch'],

  data() {
    return {
      selectedBatchId: '',
      checkedParts: [],
      showOrderDetailsModal: false,
      currentOrderDetails: {},
      allOrderDetails: [],
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),
    ...mapState(['user', 'batches', 'popup']),

    showAnalysisButton() {
      return Object.keys(this.checkedParts).length > 0;
    },
  },

  watch: {
    popup: {
      handler(popup) {
        if (popup?.key === this.$options.name && popup?.formData === this.selectedBatchId) {
          if (popup?.clicked === 'ok') {
            this.triggerPopup('');
            this.deleteBatch(this.selectedBatchId);
          } else if (popup?.clicked === 'cancel' || popup?.clicked === 'close') {
            this.triggerPopup('');
          }
        }
      },

      deep: true,
    },

    allOrderDetails: {
      handler(value) {
        if (value.length > 0) {
          this.calculateTotalOrderPrice();
        }
      },
    },
  },

  async mounted() {
    await this.fetchAllOrderDetails();
  },

  methods: {
    ...mapMutations(['triggerPopup']),
    ...mapActions(['fetchBatches']),

    confirmDelete(batchPk) {
      this.triggerPopup({
        key: this.$options.name,
        show: true,
        title: 'Delete Group',
        message: 'Deleting this group will delete all the parts in the group. Are you sure?',
        buttons: true,
        formData: batchPk,
        buttonContent: [
          { text: 'Cancel', type: 'outlined', value: 'cancel' },
          { text: 'Delete', type: 'secondary', value: 'ok' },
        ],
      });
    },

    select(id) {
      this.selectedBatchId = id;
      this.$emit('selected-batch', this.selectedBatchId);
    },

    async deleteBatch(batchPk) {
      await this.axiosInstance
        .delete(`api/v1/batch/${batchPk}/`)
        .then(() => {
          this.fetchBatches();
          this.$root.notify('success', 'Group Deleted', 3000);
        })
        .catch(error => {
          if (error.response) {
            this.$root.notify('warning', error.response.data.error_message, 3000);
          }
        });
    },

    handleCheckedPartsUpdate(checkedParts) {
      this.checkedParts = { ...checkedParts };
    },

    async openOrderDetailsModal(orderUid) {
      this.currentOrderDetails = this.allOrderDetails.find(order => order.uid === orderUid);
      if (!this.currentOrderDetails) {
        this.$root.notify('warning', 'Order details not found', 3000);
        return;
      }
      this.showOrderDetailsModal = true;
    },

    calculateTotalOrderPrice() {
      this.allOrderDetails.forEach(order => {
        order.minTotalPrice = order.prp_parts.reduce((acc, part) => acc + part.min_part_price_pl, 0);
      });

      this.allOrderDetails.forEach(order => {
        order.maxTotalPrice = order.prp_parts.reduce((acc, part) => acc + part.max_part_price_pl, 0);
      });

      this.allOrderDetails.forEach(order => {
        if (order.minTotalPrice !== order.maxTotalPrice) {
          order.summary = [
            {
              name: 'Total Price',
              value: `${this.$formatPrice(order.minTotalPrice)} - ${this.$formatPrice(order.maxTotalPrice)}`,
            },
          ];
        } else {
          order.summary = [
            {
              name: 'Total Price',
              value: this.$formatPrice(order.minTotalPrice),
            },
          ];
        }
      });
    },

    async fetchAllOrderDetails() {
      const orderUids = this.batches.map(batch => batch.prp_order_uid).filter(Boolean);
      const orderDetails = await Promise.all(
        orderUids.map(async orderUid => {
          const response = await this.axiosInstance.get(`api/v1/prp-order-summary/${orderUid}/`);
          return response.data;
        })
      );

      orderDetails.forEach(order => {
        order.prp_parts?.map(async part => {
          try {
            const response = await this.axiosInstance.get(`api/v1/part-thumbnail/${part.part_id}/`, {
              responseType: 'blob',
            });
            const fileReader = new FileReader();
            fileReader.readAsDataURL(response.data);
            fileReader.onload = e => {
              const thumbnail = e.target.result || defaultImage;
              part.thumbnail = thumbnail ?? defaultImage;
            };
          } catch (error) {
            part.thumbnail = defaultImage;
          }

          try {
            const response = await this.axiosInstance.get(`/api/v1/prp-part/${part.part_id}/`);
            part.partDescription = response.data.part_description_items;
          } catch (error) {
            console.error('Error fetching part details:', error);
          }

          if (part.min_part_price_pl !== part.max_part_price_pl) {
            part.priceRangePerLot = `${this.$formatPrice(part.min_part_price_pl)} - ${this.$formatPrice(
              part.max_part_price_pl
            )}`;
          } else {
            part.priceRangePerLot = this.$formatPrice(part.min_part_price_pl);
          }
        });
      });
      this.allOrderDetails = orderDetails;
    },

    closeOrderDetailsModal() {
      this.showOrderDetailsModal = false;
      this.currentOrderUid = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.batch-table {
  border-radius: 10px;
  border: 1px solid var(--light-color);
  overflow: hidden;
}

.border-bottom {
  border-bottom: 1px solid var(--light-color);
}

.batch-title {
  display: flex;
  // padding: 5px 0px 5px 10px;
  background-color: white;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
}

.batch-buttons {
  display: flex;
  align-items: center;
  // justify-items: end;
}

.batch-content {
  background-color: white;
  padding: 20px 20px 20px 25px;
  border-bottom: 1px solid var(--light-color);
}
</style>
