<template>
  <div v-if="showBar">
    <div v-if="componentKey === 0"><SparkSpinner class="!w-24 !h-24 my-12" /></div>
    <apexchart
      v-else
      :key="componentKey"
      type="bar"
      :options="apexOptions"
      :series="apexSeries"
      width="100%"
      height="80px"
    />
  </div>
  <div v-else style="height: 40px" />
</template>

<script>
const percentLimitsOnBar = {
  validArea: {
    min: 25,
    max: 75,
  },
  reachableFeasValues: {
    min: 0,
    max: 90,
  },
};
const barColors = {
  invalid: '#e6e6e6',
  valid: '#2986a6',
};

import SparkSpinner from '../../SparkComponents/SparkSpinner.vue';

export default {
  name: 'FeasibilityMarkedBarApex',

  components: {
    SparkSpinner,
  },

  props: {
    feasibility: {
      type: Object,
      default() {
        return {};
      },
    },

    showBar: Boolean,
    redesignSuggestion: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      componentKey: 0,
      apexOptions: {
        chart: {
          type: 'bar',
          stacked: true,
          stackType: '100%',
          animations: {
            enabled: true,
          },

          toolbar: {
            show: false,
          },

          fontFamily: 'Poppins, Arial, sans-serif',
        },

        dataLabels: {
          enabled: false,
        },

        legend: {
          show: false,
        },

        tooltip: {
          enabled: true,
        },

        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
            borderRadiusApplication: 'end',
            borderRadiusWhenStacked: 'last',
            barHeight: '50%',
          },
        },

        grid: {
          show: false,
        },

        xaxis: {
          show: false,
          axisTicks: { show: false },
          labels: {
            show: false,
          },

          axisBorder: {
            show: false,
          },
        },

        annotations: {
          xaxis: [],
          points: [],
        },

        yaxis: {
          show: false,
          labels: {
            show: false,
          },

          axisBorder: {
            show: false,
          },
        },
      },
    };
  },

  computed: {},

  watch: {
    feasibility: {
      handler(feasibility) {
        this.setApexProperties(feasibility);
      },

      immediate: true,
    },
  },

  mounted() {
    this.rerender();
  },

  methods: {
    setApexProperties(feasibility) {
      if (feasibility != undefined) {
        this.apexOptions['tooltip'] = this.tooltip(feasibility);
        this.apexOptions['annotations']['xaxis'] = this.feasValuesTickMarksAndLabels(feasibility);
        this.apexOptions['annotations']['points'] = this.limitsTicks(feasibility);
        this.apexSeries = this.stackedBars(feasibility);
      }
    },

    tooltip(feasibility) {
      let tooltip = '';
      if (!feasibility?.min?.chk) {
        tooltip += this.redesignSuggestion?.min;
      }
      if (!feasibility?.max?.chk) {
        tooltip += this.redesignSuggestion?.max;
      }
      let dict = {
        custom: function () {
          return '<div>' + tooltip + '</div>';
        },
      };
      return dict;
    },

    feasValuesTickMarksAndLabels(feasibility) {
      let minMaxList = [];
      let min = feasibility.min;
      let max = feasibility.max;

      if (min != null) {
        minMaxList.push(this.singleFeasTick(min.val, min?.lim, max?.lim, min?.chk, 'Min'));
      }
      if (max != null) {
        minMaxList.push(this.singleFeasTick(max.val, min?.lim, max?.lim, max?.chk, 'Max'));
      }
      return minMaxList;
    },

    singleFeasTick(value, limMin, limMax, check, textPrefix) {
      let tickPos = this.xPosOnBarInPerc(value, limMin, limMax);
      tickPos = this.cropIfRequired(tickPos);

      let labelColor = check || check == null ? '#50a443' : '#ff0000';
      let tickText = textPrefix + ': ' + this.$formatTwoDecimalPlaces(value) + ' mm';
      let tickDict = this.setUpActualMarkDict(tickPos, labelColor, tickText);
      return tickDict;
    },

    xPosOnBarInPerc(value, limMin, limMax) {
      let validArea = percentLimitsOnBar.validArea;

      let bothLimitsGiven = limMin != null && limMax != null;
      let justMinLimitGiven = limMin != null && limMax == null;

      let xPosOnBarInPerc;
      if (bothLimitsGiven) {
        let relPosInRegardsToLimits = (value - limMin) / (limMax - limMin);
        xPosOnBarInPerc = this.interp(relPosInRegardsToLimits, validArea.min, validArea.max);
      } else if (justMinLimitGiven) {
        xPosOnBarInPerc = (validArea.min / limMin) * value;
      }
      return xPosOnBarInPerc;
    },

    interp(fraction, start, end) {
      return start * (1 - fraction) + end * fraction;
    },

    cropIfRequired(tickPos) {
      let reachLims = percentLimitsOnBar.reachableFeasValues;
      tickPos = tickPos > reachLims.max ? reachLims.max : tickPos;
      tickPos = tickPos < reachLims.min ? reachLims.min : tickPos;
      return tickPos;
    },

    setUpActualMarkDict(tickPos, labelColor, tickText) {
      let dict = {
        x: tickPos,
        strokeDashArray: 0,
        borderColor: labelColor,
        label: {
          borderColor: null,
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 0,
          text: tickText,
          style: {
            color: '#fff',
            background: labelColor,
          },
        },
      };
      return dict;
    },

    limitsTicks(feasibility) {
      let limMinTick = {
        x: percentLimitsOnBar.validArea.min,
        label: {
          borderWidth: 0,
          text: feasibility.min?.lim,
        },
      };

      let limMaxTick = {
        x: percentLimitsOnBar.validArea.max,
        label: {
          borderWidth: 0,
          text: feasibility.max?.lim,
        },
      };

      return [limMinTick, limMaxTick];
    },

    stackedBars(feasibility) {
      let bothLimitsGiven = feasibility?.min?.lim != null && feasibility?.max?.lim != null;
      let onlyMinLimitGiven = feasibility?.min?.lim != null && feasibility?.max?.lim == null;
      if (bothLimitsGiven) {
        return this.setUpBarsInvalidValidInvalid();
      } else if (onlyMinLimitGiven) {
        return this.setUpBarsInvalidValid();
      }
    },

    setUpBarsInvalidValidInvalid() {
      let validArea = percentLimitsOnBar.validArea;

      let stackedBarValuesToAccumulate100Perc = [validArea.min, validArea.max - validArea.min, 100 - validArea.max];

      let leftBarAkaValueTooSmall = {
        data: [stackedBarValuesToAccumulate100Perc[0]],
        color: barColors.invalid,
      };
      let validBar = {
        data: [stackedBarValuesToAccumulate100Perc[1]],
        color: barColors.valid,
      };
      let rightBarAkaValueTooBig = {
        data: [stackedBarValuesToAccumulate100Perc[2]],
        color: barColors.invalid,
      };
      return [leftBarAkaValueTooSmall, validBar, rightBarAkaValueTooBig];
    },

    setUpBarsInvalidValid() {
      let validArea = percentLimitsOnBar.validArea;

      let stackedBarValuesToAccumulate100Perc = [validArea.min, 100 - validArea.min];

      let leftBarAkaValueTooSmall = {
        data: [stackedBarValuesToAccumulate100Perc[0]],
        color: barColors.invalid,
      };
      let validBar = {
        data: [stackedBarValuesToAccumulate100Perc[1]],
        color: barColors.valid,
      };
      return [leftBarAkaValueTooSmall, validBar];
    },

    rerender() {
      // Wait for 50 milliseconds before executing the function
      setTimeout(() => {
        this.componentKey = this.componentKey + 1;
      }, 400);
    },
  },
};
</script>
