<template>
  <div>
    <table class="w-full">
      <thead>
        <tr class="text-gray-500 text-11 border-b border-gray-100 align-middle h-48 mx-8">
          <th class="pl-8">Supplier</th>
          <th>Contact</th>
          <th>Show in My Suppliers</th>
          <td>&nbsp;</td>
        </tr>
      </thead>
      <tr
        v-for="supplier in publicSuppliers"
        :key="supplier"
        class="text-11 align-middle h-40 border-b border-gray-100 hover:bg-gray-50"
      >
        <!-- <td>{{ supplier.has_logo }}</td> -->
        <td class="pl-8">{{ supplier.name }}</td>
        <td>
          <div class="flex">
            <div>{{ supplier.sales_contact?.first_name }} {{ supplier.sales_contact?.last_name }}</div>
          </div>
          <div class="text-gray-500">
            {{ supplier.sales_contact?.email }}
          </div>
        </td>
        <td class="hover:cursor-pointer">
          <SparkButton v-if="checkIfSupplierInMyProduction(supplier.uid)" class="w-100" small variant="primary"
            ><div class="text-11" v-text="'Activated'"
          /></SparkButton>
          <SparkButton v-else class="w-100" small variant="outlined"
            ><div class="text-11" v-text="'Deactivated'"
          /></SparkButton>
        </td>
        <td>
          <DropdownMenu
            :list-options="dropdownOptions[supplier.uid]"
            title-icon="fas fa-ellipsis-h"
            close-click-style="always"
            @update-option="show($event, supplier)"
          />
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

import DropdownMenu from '../../../../../components/Reusable/DropdownMenu.vue';
import SparkButton from '../../../../../components/SparkComponents/SparkButton.vue';

// import DeleteSupplier from './DeleteSupplier.vue';
// import EditSupplier from './EditSupplier.vue';

export default {
  name: 'SupplierList',

  components: {
    SparkButton,
    DropdownMenu,
    // DeleteSupplier,
    // EditSupplier,
  },

  data() {
    return { dropdownOptions: {} };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),
    ...mapState('supplier', ['users', 'selectedSuppliers', 'publicSuppliers', 'selectedSalesRep', 'isSupplier']),

    selectedSuppliersValue: {
      get() {
        return this.selectedSuppliers;
      },

      set(value) {
        this.setSelectedSuppliers(value);
      },
    },
  },

  async mounted() {
    await this.fetchSelectedSuppliers(); // Get all supplier
    await this.fetchPublicSuppliers(); // Get the selected suppliers from backend
    this.calculateDropownOptions();
  },

  methods: {
    ...mapActions('supplier', [
      'createEmailSupplier',
      'updateSuppliers',
      'fetchSelectedSuppliers',
      'fetchPublicSuppliers',
      'fetchUsers',
    ]),

    ...mapMutations('supplier', ['setSelectedSuppliers']),

    calculateDropownOptions() {
      this.publicSuppliers.forEach(supplier => {
        if (this.checkIfSupplierInMyProduction(supplier.uid)) {
          this.dropdownOptions[supplier.uid] = [
            {
              text: 'Remove from my suppliers',
              value: 'remove',
              icon: 'fas fa-columns',
              disabled: false,
            },
          ];
        } else {
          this.dropdownOptions[supplier.uid] = [
            {
              text: 'Add in my production',
              value: 'add',
              icon: 'fas fa-columns',
              disabled: false,
            },
          ];
        }
      });
    },

    checkIfSupplierInMyProduction(uid) {
      let isInMyProduction = false;
      Object.keys(this.selectedSuppliersValue).forEach(key => {
        if (uid === this.selectedSuppliers[key].uid) {
          isInMyProduction = true;
        }
      });
      return isInMyProduction;
    },

    show(event, supplier) {
      if (event.value === 'remove') {
        this.removeFromInMyProduction(supplier);
      } else if (event.value === 'add') {
        this.addToInMyProduction(supplier);
      }
    },

    removeFromInMyProduction(supplier) {
      this.selectedSuppliersValue = this.selectedSuppliersValue.filter(function (el) {
        return el.uid != supplier.uid;
      });
      this.updateSupplierList();
      this.calculateDropownOptions();
    },

    addToInMyProduction(supplier) {
      this.selectedSuppliersValue.push(supplier);
      this.setSelectedSuppliers(this.selectedSuppliersValue);
      this.updateSupplierList();
      this.calculateDropownOptions();
    },

    updateSupplierList() {
      this.updateSuppliers({ notify: this.$root.notify });
    },
  },
};
</script>
