<template>
  <div>
    <h5 class="text-right cursor-pointer">
      Add Category<i class="fas fa-plus-square" @click="showDialogNewCategory()" />
    </h5>

    <div
      v-if="newCategoryEditMode"
      class="items-center gap-x-8 flex text-11 p-4 border border-primary-500 rounded-4 flex-wrap"
    >
      <SparkInput
        id="category"
        v-model="newCategory.category_label"
        class="name-input"
        :class="markRedBorder ? 'red-border' : ''"
        type="text"
        name="name"
        placeholder="Category Name"
      />
      <div class="flex gap-8">
        <div class="flex gap-4 text-11 items-center mr-4">
          <input v-model="newCategory.required" type="checkbox" />
          <p>Required</p>
        </div>

        <div class="checkbox-item">
          <input v-model="newCategory.visible_external" type="checkbox" />
          <span class="flex-row">Available in PRP</span>
        </div>

        <div class="checkbox-item">
          <SparkSwitch v-model="switchActive" name="Fixed Options" :disabled="switchDisabled" />
          <span class="flex-row ml-12">Fixed Options</span>
        </div>
      </div>

      <div class="flex-row icons-end">
        <i class="fas fa-check icon-button" @click="addNewCategory()" />
        <i class="fas fa-times icon-button" @click="closeNewCategory()" />
      </div>
      <div v-if="switchActive" class="flex items-center">
        <SparkInput id="item" v-model="newItem" class="name-input" type="text" name="name" placeholder="New Item" />
        <i class="fas fa-plus icon-button" @click="addItemAndCheckCategory()" />
      </div>
    </div>
    <draggable :list="categoryList" item-key="order_index" @end="reindexCategories">
      <template #item="{ element }">
        <div class="draggable-item flex text-13">
          <div class="drag-icon mt-16 mr-10">&#x2630;</div>
          <SparkInput
            id="category"
            v-model="element.label"
            :class="categoryEditMode === element.uid ? 'input-edit-mode' : 'input-view-mode'"
            class="min-w-100"
            type="text"
            name="name"
            :disabled="categoryEditMode !== element.uid"
            placeholder="Category Name"
          />
          <div v-if="element.uid === categoryEditMode" class="flex-row">
            <div class="checkbox-item">
              <input v-model="element.required" type="checkbox" />
              <span>Required</span>
            </div>
            <div class="checkbox-item">
              <input v-model="element.visible_external" type="checkbox" />
              <span class="flex-row">Available in PRP</span>
            </div>
          </div>
          <div v-else class="flex-row">
            <div>
              <i v-if="element.required" class="fas fa-check category-checked" />
              <i v-else class="fas fa-xmark category-unchecked" />
              <span>required</span>
            </div>
            <div>
              <i
                v-if="element.visible_external"
                class="fas fa-check category-checked"
                title="Visible in Part Request Platform"
              />
              <i v-else class="fas fa-xmark category-unchecked" title="Not visible in Part Request Platform" />
              <span>Available in PRP</span>
            </div>
          </div>
          <div v-if="element.category_type === 'ComboCategory'" class="items flex-1">
            <div class="items">
              <div class="mt-auto mb-auto flex flex-wrap">
                <template v-for="item in element.items" :key="item">
                  <div class="item">
                    <SparkInput
                      v-if="element.uid === categoryEditMode"
                      v-model.lazy="item.value"
                      name="name"
                      class="item-input"
                    />
                    <span v-if="element.uid !== categoryEditMode">{{ item.value }}</span>
                    <i
                      v-if="element.uid === categoryEditMode"
                      class="fas fa-times hover-pointer"
                      @click="deleteComboCategoryItemCheck(item, element.label)"
                    />
                  </div>
                </template>
              </div>
              <div v-if="element.uid === categoryEditMode">
                <SparkInput
                  id="item"
                  v-model="newItem"
                  class="name-input"
                  type="text"
                  name="name"
                  placeholder="New Item"
                />
                <i class="fas fa-plus icon-button" @click="addItemToCategory(element.uid)" />
              </div>
              <span v-if="Object.keys(element.items).length == 0" class="item warning">Please add options</span>
            </div>
          </div>
          <div v-if="element.uid != categoryEditMode" class="flex-row icons-end">
            <i class="far fa-edit icon-button" @click="categoryInEditMode(element.uid)" />
            <i
              class="far fa-trash-alt icon-button"
              @click="deleteCategoryCheck(element.uid, element.label, element.category_type)"
            />
          </div>
          <div v-else class="flex-row icons-end">
            <i class="fas fa-check icon-button" @click="updateCategory(element.uid, element, element.items)" />
          </div>
        </div>
      </template>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapState, mapMutations } from 'vuex';

import SparkInput from '../../../components/SparkComponents/SparkInput.vue';
import SparkSwitch from '../../../components/SparkComponents/SparkSwitch.vue';

export default {
  name: 'ComboTextCategories',

  components: {
    draggable,
    SparkInput,
    SparkSwitch,
  },

  data() {
    return {
      categoryList: [],
      categoryEditMode: '',
      newItem: '',
      newCategoryEditMode: false,
      newCategory: {},
      markRedBorder: false,
      switchActive: false,
      switchDisabled: false,
    };
  },

  computed: {
    ...mapState(['user', 'popup']),
    ...mapState('application', ['axiosInstance']),
  },

  watch: {
    popup(popup) {
      if (popup?.clicked == 'ok') {
        if (popup?.key == this.$options.name + 'DeleteItem') {
          this.deleteComboCategoryItem(popup.additionalData);
          this.triggerPopup('');
        } else if (popup?.key == this.$options.name + 'DeleteCategory') {
          this.deleteCategory(popup.additionalData);
          this.triggerPopup('');
        }
      } else if (popup?.clicked == 'cancel' || popup?.clicked == 'close') {
        this.triggerPopup('');
      }
    },
  },

  async mounted() {
    await this.getCategoryList();
  },

  methods: {
    ...mapMutations(['triggerPopup']),

    categoryInEditMode(uid) {
      this.categoryEditMode = uid;
    },

    showDialogNewCategory() {
      this.newCategoryEditMode = true;
    },

    addNewCategory() {
      if (this.newCategory?.category_label != undefined) {
        if (this.newItem != '') {
          this.addNewComboCategory();
        } else {
          this.addNewTextCategory();
        }
      }
    },

    addNewComboCategory() {
      if (this.newItem != '') {
        this.newCategory = { ...this.newCategory, ...{ item: this.newItem } };
      }
      this.newCategoryEditMode = false;
      this.axiosInstance.post('/api/v1/combo-category-item/', this.newCategory).then(() => {
        this.$getUser(this.$keycloak, this.axiosInstance, this.$store);
        this.newCategory = {};
        this.markRedBorder = false;
        this.getCategoryList();
      });
    },

    addItemAndCheckCategory() {
      if (this.newCategory?.category_label === undefined) {
        this.markRedBorder = true;
      } else {
        let formData = { ...this.newCategory, ...{ item: this.newItem } };
        this.axiosInstance.post('/api/v1/combo-category-item/', formData).then(() => {
          this.$getUser(this.$keycloak, this.axiosInstance, this.$store);
          this.newCategoryEditMode = false;
          this.newCategory = {};
          this.newItem = '';
          this.markRedBorder = false;
        });
      }
      this.getCategoryList();
    },

    closeNewCategory() {
      this.newCategory = {};
      this.newCategoryEditMode = false;
    },

    addItemToCategory(category) {
      let formData = {
        category_uid: category,
        item: this.newItem,
      };
      this.axiosInstance.post('/api/v1/combo-category-item/', formData).then(() => {
        this.$getUser(this.$keycloak, this.axiosInstance, this.$store);
        this.newItem = '';
        this.getCategoryList();
      });
    },

    updateComboCategory(categoryUid, category, items) {
      let formData = {
        category_label: category.label,
        required: category.required,
        visible_external: category.visible_external,
      };
      this.categoryEditMode = '';

      this.axiosInstance.put(`/api/v1/combo-category/${category.uid}/`, formData).then(() => {
        this.$getUser(this.$keycloak, this.axiosInstance, this.$store);
      });

      Object.values(items).forEach(item => {
        // Update each combo category item
        this.axiosInstance.put(`/api/v1/combo-category-item/${item.uid}/`, { value: item.value }).catch(error => {
          console.error('Error updating combo category item:', error);
        });
      });
    },

    deleteCategoryCheck(categoryUid, categoryLabel, categoryType) {
      this.triggerPopup({
        key: this.$options.name + 'DeleteCategory',
        show: true,
        title: 'Delete a category',
        message:
          'Do you really want to delete ' +
          categoryLabel +
          ' including all items? If there is data related to a part. This will be also deleted.',
        additionalData: { uid: categoryUid, type: categoryType },
        buttons: true,
        buttonContent: [
          { text: 'Cancel', type: 'outlined', value: 'cancel' },
          { text: 'Ok', type: 'secondary', value: 'ok' },
        ],
      });
    },

    deleteCategory(CategoryData) {
      this.CategoryUid = CategoryData['uid'];
      this.CategoryType = CategoryData['type'];

      if (this.CategoryType === 'ComboCategory') {
        this.deleteComboCategory(this.CategoryUid);
      } else if (this.CategoryType === 'TextCategory') {
        this.deleteTextCategory(this.CategoryUid);
      } else {
        console.log('error');
      }
    },

    deleteComboCategory(comboCategoryUid) {
      this.axiosInstance.delete(`/api/v1/combo-category/${comboCategoryUid}`).then(() => {
        this.$getUser(this.$keycloak, this.axiosInstance, this.$store);
        this.getCategoryList();
      });
    },

    deleteTextCategory(textCategoryUid) {
      this.axiosInstance.delete(`/api/v1/text-category/${textCategoryUid}`).then(() => {
        this.$getUser(this.$keycloak, this.axiosInstance, this.$store);
        this.getCategoryList();
      });
    },

    deleteComboCategoryItemCheck(item, categoryLabel) {
      this.itemUid = item.uid;
      this.itemLabel = item.value;
      this.triggerPopup({
        key: this.$options.name + 'DeleteItem',
        show: true,
        title: 'Delete a category item',
        message: 'Do you really want to delete ' + this.itemLabel + ' from category ' + categoryLabel + '?',
        additionalData: this.itemUid,
        buttons: true,
        buttonContent: [
          { text: 'Cancel', type: 'outlined', value: 'cancel' },
          { text: 'Ok', type: 'secondary', value: 'ok' },
        ],
      });
    },

    deleteComboCategoryItem(itemuid) {
      this.axiosInstance.delete(`/api/v1/combo-category-item/${itemuid}`).then(() => {
        this.$getUser(this.$keycloak, this.axiosInstance, this.$store);
        this.getCategoryList();
      });
    },

    addNewTextCategory() {
      this.axiosInstance
        .post('api/v1/text-category/', this.newCategory)
        .then(() => {
          this.newCategory = {};
          this.newCategoryEditMode = false;
          this.$getUser(this.$keycloak, this.axiosInstance, this.$store);
          this.getCategoryList();
        })
        .catch(() => {});
    },

    updateTextCategory(uid, category) {
      this.axiosInstance.put(`/api/v1/text-category/${uid}/`, category).then(() => {
        this.$getUser(this.$keycloak, this.axiosInstance, this.$store);
        this.categoryEditMode = '';
      });
    },

    updateCategory(id, element, items) {
      if (element.category_type == 'ComboCategory') {
        this.updateComboCategory(id, element, items);
      } else if (element.category_type == 'TextCategory') {
        this.updateTextCategory(id, element);
      } else {
        console.log('error');
      }
    },

    async getCategoryList() {
      await this.axiosInstance.get(`api/v1/category-order/`).then(response => {
        this.categoryList = response.data['category_list'];
      });
    },

    reindexCategories() {
      let formData = {
        category_order: {},
      };

      const list = this.categoryList;
      list.forEach((category, index) => {
        category.order_index = index;
        formData.category_order[index] = {
          uid: category.uid,
          category_type: category.category_type,
        };
      });

      this.axiosInstance.put(`api/v1/category-order/`, formData);
    },
  },
};
</script>

<style lang="scss" scoped>
span {
  cursor: default;
}

input {
  border: 1px solid var(--light-color);
  width: 20%;
  height: 2em;
  margin: 5px 3px 5px 0px;
}

.hover-pointer:hover {
  cursor: pointer;
}

.input-edit-mode {
  border: 1px solid var(--light-color);
}

.input-view-mode {
  border: 1px solid white;
  background-color: white;
  font-weight: bold;
}

.flex-row {
  display: flex;
  flex-wrap: nowrap;
}

.items {
  display: flex;
  flex-wrap: wrap;
  margin-left: 5px;
}

.item {
  display: flex;
  margin: 2px;
  background-color: #dbd3d8;
  border-radius: 10px;
  padding: 0px 5px;
  white-space: nowrap;
  align-items: center;
  width: auto;
}

.category-checked {
  color: var(--accent-color);
  padding-left: 10px;
}

.category-unchecked {
  color: red;
  padding-left: 10px;
}

.checkbox-item {
  display: flex;
  font-size: var(--10px);
  align-items: center;
  margin-right: 5px;
}

input[type='checkbox'] {
  height: 15px;
  width: 15px;
  &:hover {
    cursor: pointer;
  }
}

i {
  margin: 0px 5px;
}

.icons-end {
  justify-self: end;
  margin-left: auto;
  margin-right: 0;
}

.red-border {
  border: 1px solid red;
}

.warning {
  background-color: var(--spark-delete);
}

.item-input {
  flex-grow: 1;
}

.drag-icon:hover {
  cursor: grab;
}

.dragging .drag-icon {
  cursor: grabbing;
}
</style>
