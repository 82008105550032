<template>
  <div class="whitespace-nowrap px-4 flex self-end">
    <div v-if="analysisFinished">
      <div class="flex items-baseline">
        <FeedbackValueInput
          v-if="type == 'cost'"
          :feedback-value="Math.round(chain.cost?.cost_pl)"
          @feedback-value-changed="OverwriteWithFeedbackValueAndPUT(chain, 'cost_pl', $event)"
          @click="setToActive()"
        />
        <FeedbackValueInput
          v-if="type == 'leadtime'"
          :feedback-value="Math.round(chain.leadtime)"
          @feedback-value-changed="OverwriteWithFeedbackValueAndPUT(chain, 'leadtime', $event)"
          @click="setToActive()"
        />
        <FeedbackValueInput
          v-if="type == 'carbon'"
          :feedback-value="Math.round(chain.co2?.co2_pl)"
          @feedback-value-changed="OverwriteWithFeedbackValueAndPUT(chain, 'co2_pl', $event)"
          @click="setToActive()"
        />
        <div class="pl-4 text-gray-500">{{ unit }}</div>
      </div>
      <div v-if="!mouseHover" class="w-full max-w-200 whitespace-nowrap">
        <div class="text-11 flex-end text-right mb-4" :class="comparisonColor">
          {{ valueSaved }}
          {{ unit }}
        </div>
      </div>
    </div>
    <div v-else class="flex">
      <div class="font-bold">{{ calculateRange(chain) }}</div>
      <div class="pl-4 text-gray-500">{{ unit }}</div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import FeedbackValueInput from './FeedbackValueInput.vue';

import { analysisFinished } from '@/composables/processChainHandling.js';

export default {
  name: 'EstimationAndBenchmark',

  components: { FeedbackValueInput },

  props: {
    chain: { type: Object, required: true },
    processChains: { type: Object, required: true },
    type: { type: String, required: true },
    unit: { type: String, default: '' },
    mouseHover: { type: Boolean },
  },

  data() {
    return {
      betterThanComparison: false,
    };
  },

  computed: {
    ...mapState(['part']),
    ...mapState('application', ['axiosInstance']),

    analysisFinished() {
      return analysisFinished(this.chain.process_chain_id);
    },

    valueSaved() {
      let uid = this.chain.process_chain_id;
      if (this.processChains == null || this.mainProcessUid == null || this.benchmarkProcessUid == null) return '-';
      let chainValue = 0;
      if (this.type == 'cost') {
        chainValue = this.chain.cost.cost_pl;
      } else if (this.type == 'leadtime') {
        chainValue = this.chain.leadtime;
      } else if (this.type == 'carbon') {
        chainValue = this.chain.co2.co2_pl;
      }
      chainValue = Math.round(chainValue);
      let comparison = 0;
      // only one process Chain
      if (Object.keys(this.processChains).length < 2) {
        comparison = 0;
      } else {
        // if chain is main compare with benchmark if benchmark is set
        if (this.mainProcessUid == uid && this.benchmarkProcessUid != undefined) {
          if (this.type == 'cost') {
            comparison = chainValue - Math.round(this.processChains[this.benchmarkProcessUid].cost?.cost_pl);
          } else if (this.type == 'leadtime') {
            comparison = chainValue - Math.round(this.processChains[this.benchmarkProcessUid].leadtime);
          } else if (this.type == 'carbon') {
            comparison = chainValue - Math.round(this.processChains[this.benchmarkProcessUid].co2?.co2_pl);
          }

          // if chain is main compare with cheapest if benchmark is not set
        } else if (this.mainProcessUid == uid && this.benchmarkProcessUid == undefined) {
          let cheapest = this.findCheapestValue();
          comparison = chainValue - cheapest;
        }
        // else: since chain is not main it is benchmark, so compare it with main
        else {
          if (this.type == 'cost') {
            comparison = chainValue - Math.round(this.processChains[this.mainProcessUid].cost?.cost_pl);
          } else if (this.type == 'leadtime') {
            comparison = chainValue - Math.round(this.processChains[this.mainProcessUid].leadtime);
          } else if (this.type == 'carbon') {
            comparison = chainValue - Math.round(this.processChains[this.mainProcessUid].co2?.co2_pl);
          }
        }
      }

      this.calculateBetterThanComparison(comparison);
      if (this.type == 'cost') {
        return comparison;
      } else if (this.type == 'leadtime') {
        return comparison;
      } else if (this.type == 'carbon') {
        return comparison;
      } else {
        return '-';
      }
    },

    comparisonColor() {
      if (this.valueSaved == 0) {
        return 'text-gray-500';
      } else {
        return this.betterThanComparison ? 'text-green-500' : 'text-red-500';
      }
    },

    mainProcessUid() {
      return this.part.primary_process_chain_id;
    },

    benchmarkProcessUid() {
      return typeof this.part.benchmark_process_chain_id == 'string' && this.part.benchmark_process_chain_id != ''
        ? this.part.benchmark_process_chain_id
        : undefined;
    },
  },

  methods: {
    ...mapMutations(['updateSingleProcessChain', 'changeInvestigationDetails']),

    calculateBetterThanComparison(benchmark) {
      if (benchmark < 0) {
        this.betterThanComparison = true;
      } else {
        this.betterThanComparison = false;
      }
    },

    isMainProcessUid(chain) {
      return this.mainProcessUid == chain.process_chain_id;
    },

    async OverwriteWithFeedbackValueAndPUT(chain, modelFieldToChange, value) {
      let formData = {};
      formData[modelFieldToChange] = value;

      this.axiosInstance
        .put(`/api/v1/process-chain-feedback-overwrite/${chain.process_chain_id}/`, formData)
        .then(response => {
          console.log(response);
          this.updateSingleProcessChain(response.data);
        });
    },

    findCheapestValue() {
      let cheapest = 999999999;
      let newVal = 999999999;
      Object.keys(this.processChains).forEach(key => {
        if (key != this.mainProcessUid) {
          if (this.type == 'cost') {
            newVal = this.processChains[key].cost?.cost_pl;
          } else if (this.type == 'leadtime') {
            newVal = this.processChains[key].leadtime;
          } else if (this.type == 'carbon') {
            newVal = this.processChains[key].cost?.cost_pl;
          }

          if (newVal < cheapest) {
            cheapest = newVal;
          }
        }
      });
      return cheapest;
    },

    calculateRange(chain) {
      let smallVal = 0;
      let bigVal = 0;

      if (this.type == 'cost') {
        smallVal = chain.cost?.cost_pl * 0.5; // Range shown ranges from 50% to 120% of first cost estimation
        bigVal = chain.cost?.cost_pl * 1.2;
      } else if (this.type == 'leadtime') {
        smallVal = chain.leadtime * 0.5; // Range shown ranges from 50% to 120% of first leadtime estimation
        bigVal = chain.leadtime * 1.2;
      } else if (this.type == 'carbon') {
        smallVal = chain.co2?.co2_pl * 0.5; // Range shown ranges from 50% to 120% of first leadtime estimation
        bigVal = chain.co2?.co2_pl * 1.2;
      }

      smallVal = this.roundNumber(smallVal);
      bigVal = this.roundNumber(bigVal);

      // round to two digits if one value smaller than 1 euro, else round to int
      if (smallVal < 1) {
        smallVal = this.$formatZeroDecimalPlaces(smallVal);
        bigVal = this.$formatZeroDecimalPlaces(bigVal);
      } else {
        smallVal = this.$formatZeroDecimalPlaces(smallVal);
        bigVal = this.$formatZeroDecimalPlaces(bigVal);
      }

      // If this is the empty process chain, return empty
      if (chain.prc === 'Default Process') {
        return '';
      }

      return smallVal + ' - ' + bigVal;
    },

    roundNumber(num) {
      let roundedNum = 0;
      if (num > 10 && num < 1000) {
        roundedNum = Math.round(num / 10) * 10;
      } else if (num >= 1000) {
        roundedNum = Math.round(num / 100) * 100;
      } else {
        roundedNum = num;
      }

      return roundedNum;
    },

    setToActive() {
      this.changeInvestigationDetails({ uid: this.chain.process_chain_id });
    },
  },
};
</script>
