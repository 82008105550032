<template>
  <div>
    <h4>Process Synonyms</h4>
    <table class="spark-table">
      <tbody>
        <tr v-for="name in sortedArray" :key="lookUpTable[name]">
          <!-- <td style="width: 10%">icon</td> -->
          <td style="width: 50%">
            <input
              :ref="lookUpTable[name] + 'prc_name'"
              v-model="synonymDictionary[lookUpTable[name]]['prc_name']"
              type="text"
              style="border: 1px solid white; cursor: pointer"
              :class="emptyWarning[lookUpTable[name]] ? 'warning' : ''"
              @blur="saveSynonym(lookUpTable[name], 'prc_name')"
              @keydown="validateEmpty(lookUpTable[name], 'prc_name')"
            />
          </td>
          <td style="width: 50%">
            <input
              :ref="lookUpTable[name] + 'prc_acronym'"
              v-model="synonymDictionary[lookUpTable[name]]['prc_acronym']"
              type="text"
              style="border: 1px solid white; cursor: pointer"
              :class="emptyWarning[lookUpTable[name]] ? 'warning' : ''"
              @blur="saveSynonym(lookUpTable[name], 'prc_acronym')"
              @keydown="validateEmpty(lookUpTable[name], 'prc_acronym')"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'ProcessSynonyms',

  data() {
    return {
      synonymDictionary: {},
      sortedArray: [],
      lookUpTable: {},
      emptyWarning: {},
    };
  },

  computed: {
    ...mapState(['user']),
    ...mapState('application', ['axiosInstance']),
    processSynonyms() {
      return this.user.organization.process_synonyms;
    },
  },

  mounted() {
    this.orderAlphabetically(this.processSynonyms);
    this.synonymDictionary = this.processSynonyms;
  },

  methods: {
    orderAlphabetically(obj) {
      if (obj) {
        for (const [key, value] of Object.entries(obj)) {
          this.lookUpTable[value.prc_name] = key;
          this.sortedArray.push(value.prc_name);
        }
        this.sortedArray.sort();
      }
    },

    validateEmpty(uid, type) {
      let emptyString = /^(?=\s*$)/g;
      let value = this.synonymDictionary[uid][type];
      if (emptyString.test(value)) {
        this.emptyWarning[uid] = true;
      } else {
        this.emptyWarning[uid] = false;
      }
    },

    async saveSynonym(uid, type) {
      if (this.emptyWarning[uid]) {
        this.synonymDictionary[uid][type] = 'Please insert a name';
        this.$refs[uid + type][0].focus();
        return;
      } else {
        if (this.synonymDictionary[uid][type] == '') {
          this.$notify('error', 'Can not be empty');
        } else {
          let formData = this.synonymDictionary[uid];
          this.axiosInstance.post(`/api/v1/process-synonym/${uid}/`, formData).then(response => {
            this.$store.commit('setProcessSynonyms', response.data);
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.spark-table {
  cursor: auto;
}

.edit {
  &:hover {
    cursor: pointer;
    vertical-align: middle;
    i {
      visibility: visible;
    }
  }
}

i {
  visibility: hidden;
}

.edit-input {
  padding: 0px 10px;
  margin: 0px;
}

input:focus.warning {
  background-color: var(--spark-delete);
}
</style>
