<template>
  <div class="mt-64">
    <!-- Input fields for initial parameters -->
    <details close>
      <summary>Machine Calibration</summary>
      <div class="w-1/2">
        <div class="flex min-w-full mt-10">
          <SparkInput
            v-model="initialParameters.layerHeightMin"
            type="number"
            class="m-4 w-full"
            animate-label
            name="layer-height-min"
            label="Min Layer Height [mm]"
          />
          <SparkInput
            v-model="initialParameters.layerHeightMax"
            type="number"
            class="m-4 w-full"
            animate-label
            name="layer-height-max"
            label="Max Layer Height [mm]"
          />
        </div>

        <div class="flex min-w-full mt-10">
          <SparkInput
            v-model="initialParameters.wallThicknessMin"
            type="number"
            class="m-4 w-full"
            animate-label
            name="wall-thickness-min"
            label="Min Wall Thickness [mm]"
          />
          <SparkInput
            v-model="initialParameters.wallThicknessMax"
            type="number"
            class="m-4 w-full"
            animate-label
            name="wall-thickness-max"
            label="Max Wall Thickness [mm]"
          />
        </div>

        <div class="flex min-w-full mt-10">
          <SparkInput
            v-model="initialParameters.infillRatioMin"
            type="number"
            class="m-4 w-full"
            animate-label
            name="infill-ratio-min"
            label="Infill Density Ratio [-]"
          />
          <SparkInput
            v-model="initialParameters.infillRatioMax"
            type="number"
            class="m-4 w-full"
            animate-label
            name="infill-ratio-max"
            label="Infill Density Ratio [-]"
          />
        </div>

        <div class="input-group">
          <SparkInput
            v-model="initialParameters.supportRatioMin"
            type="number"
            class="m-4 w-full"
            animate-label
            name="support-ratio-min"
            label="Min Support Density Ratio [-]"
          />
          <SparkInput
            v-model="initialParameters.supportRatioMax"
            type="number"
            class="m-4 w-full"
            animate-label
            name="support-ratio-max"
            label="Max Support Density Ratio [-]"
          />
        </div>

        <div class="input-group">
          <SparkInput
            v-model="initialParameters.buildRateCoreMin"
            type="number"
            class="m-4 w-full"
            animate-label
            name="build-rate-core-min"
            label="Min Core Build Rate [cm³/h]"
          />
          <SparkInput
            v-model="initialParameters.buildRateCoreMax"
            type="number"
            class="m-4 w-full"
            animate-label
            name="build-rate-core-max"
            label="Max Core Build Rate [cm³/h]"
          />
        </div>

        <div class="input-group">
          <SparkInput
            v-model="initialParameters.buildRateContourMin"
            type="number"
            class="m-4 w-full"
            animate-label
            name="build-rate-contour-min"
            label="Min Contour Build Rate [cm³/h]"
          />
          <SparkInput
            v-model="initialParameters.buildRateContourMax"
            type="number"
            class="m-4 w-full"
            animate-label
            name="build-rate-contour-max"
            label="Max Contour Build Rate [cm³/h]"
          />
        </div>

        <div class="input-group">
          <SparkInput
            v-model="initialParameters.timeLayerMin"
            type="number"
            class="m-4 w-full"
            animate-label
            name="time-layer-min"
            label="Min Time per Layer [s]"
          />
          <SparkInput
            v-model="initialParameters.timeLayerMax"
            type="number"
            class="m-4 w-full"
            animate-label
            name="time-layer-max"
            label="Max Time per Layer [s]"
          />
        </div>

        <div class="input-group">
          <SparkInput
            v-model="initialParameters.fixedTimeMin"
            type="number"
            class="m-4 w-full"
            animate-label
            name="fixed-time-min"
            label="Min Fixed Machine Time [h]"
          />
          <SparkInput
            v-model="initialParameters.fixedTimeMax"
            type="number"
            class="m-4 w-full"
            animate-label
            name="fixed-time-max"
            label="Max Fixed Machine Time [h]"
          />
        </div>

        <SparkInput
          v-model="buildTimeInput"
          type="text"
          class="m-4"
          animate-label
          name="build-time"
          label="Build Times separated by a Comma [h]"
        />
      </div>
      <!-- Button to trigger calibration -->
      <SparkButton small variant="secondary" @click="calibrate">
        <div v-text="'Calibrate'" />
      </SparkButton>
    </details>
    <!-- Display optimized parameters if available -->
    <table v-if="optimizedParameters">
      <thead>
        <tr>
          <th>Parameter</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(value, parameter) in optimizedParameters" :key="parameter">
          <td>{{ parameter }}</td>
          <td>{{ value.toFixed(2) }}</td>
        </tr>
      </tbody>
    </table>

    <!-- Display mean deviation -->
    <h3 v-if="deviationData">Mean Deviation [h]: {{ deviationData.mean_deviation.toFixed(2) }}</h3>

    <!-- Display deviation data in a table -->
    <table v-if="deviationData">
      <thead>
        <tr>
          <th>Part Name</th>
          <th>Deviation per Part [h]</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(partName, index) in deviationData.part_name" :key="index">
          <td>{{ partName }}</td>
          <td>{{ deviationData.deviation_per_part[index].toFixed(2) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import SparkButton from '../../../../components/SparkComponents/SparkButton.vue';

import SparkInput from '@/components/SparkComponents/SparkInput.vue';

export default {
  name: 'CalibrationButton',

  components: { SparkInput, SparkButton },

  props: {
    batchId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      initialParameters: {
        layerHeightMin: '',
        layerHeightMax: '',
        wallThicknessMin: '',
        wallThicknessMax: '',
        infillRatioMin: '',
        infillRatioMax: '',
        supportRatioMin: '',
        supportRatioMax: '',
        buildRateCoreMin: '',
        buildRateCoreMax: '',
        buildRateContourMin: '',
        buildRateContourMax: '',
        timeLayerMin: '',
        timeLayerMax: '',
        fixedTimeMin: '',
        fixedTimeMax: '',
      },

      buildTimeInput: '',
      optimizedParameters: null,
      deviationData: null, // Define optimizedParameters in data
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']), // Assuming you have axiosInstance in application module of Vuex store
  },

  methods: {
    async calibrate() {
      try {
        const payload = {
          batch_id: this.batchId,
          initial_parameters: this.initialParameters,
          buildtime_input: this.buildTimeInput,
          // Add other constraints or parameters as needed
        };

        const response = await this.axiosInstance.post(`/api/v1/calibration/`, payload, {
          headers: { 'Content-Type': 'application/json' },
        });

        if (response.status === 200) {
          // Extract optimized parameters and deviation data
          this.optimizedParameters = response.data['optmizied_parameters'];
          this.deviationData = response.data['deviation'];
        }
      } catch (error) {
        console.error('Calibration Error:', error);
        // Handle errors
      }
    },
  },
};
</script>

<style>
.input-group {
  display: flex;
}

.input-group input {
  margin-right: 10px;
  min-width: 250px;
}
</style>
