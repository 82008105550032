import axios from 'axios';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';

import router from './router';
import store from './store/index';

/**
 * This Script is used to create an axios instance (https://axios-http.com/docs/instance) which
 * is in charge to check if the access-token is expired. If so, it sends the refreshToken to Django, which
 * then asks Keycloak for a new access-token. The new access- and refresh-token are send back and set
 * replace the old ones in localStore and store.
 */
const csrfCookie = Cookies.get('csrftoken');
const baseURL = !import.meta.env.PROD ? 'http://localhost' : document.location.origin;

// Setup Interceptors
// This makes the axios instance have a routine added, which triggers on all calls.
// One can decide, what axios does before and after each request done with this instance.
function applyInterceptors(instance, keycloak) {
  instance.interceptors.request.use(async function (config) {
    if (!keycloak.authenticated) {
      // If user is not logged in
      setAuthState(keycloak);
      if (keycloak.refreshToken === undefined) {
        performLogin(window.location.pathname);
      }
    }
    await refresh_keycloak_token(keycloak);
    const token = keycloak.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      delete config.headers.Authorization;
    }
    return config;
  }, function (error) {
    return Promise.reject(error);
  });
}

export function createAxiosInstance(keycloak) {
  let token = keycloak.token;
  if (token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  } else {
    axios.defaults.headers.common['Authorization'] = '';
  }
  const axiosInstance = axios.create({
    baseURL: baseURL,
    headers: {
      Authorization: "Bearer " + token,
    },
    withCredentials: true,
  });
  applyInterceptors(axiosInstance, keycloak);

  // This was added to help with large uploads -> See Content-Type
  const axiosInstanceFileUpload = axios.create({
    baseURL: baseURL,
    headers: {
      Authorization: 'Bearer ' + token,
      'X-CSRFToken': csrfCookie,
      'Content-Type': 'multipart/form-data',
    },
    withCredentials: true,
  });
  applyInterceptors(axiosInstanceFileUpload, keycloak);

  return { axiosInstance, axiosInstanceFileUpload };
}

export function isTokenExpired(token) {
  let decode = jwt_decode(token);
  return dayjs.unix(decode.exp).diff(dayjs()) < 1;
}

function performLogin(keycloak, destination) {
  const getUrl = window.location;
  let redirect = getUrl.protocol + '//' + getUrl.host;
  if (destination != '/') {
    redirect = redirect + '?r=' + destination;
  }
  keycloak.login({ redirectUri: redirect });
}

export async function refresh_keycloak_token(keycloak) {
  keycloak
    .updateToken(import.meta.env.VITE_TIME_BEFORE_TOKEN_REFRESH)
    .then(function (refreshed) {
      if (refreshed) {
        setAuthState(keycloak);
      }
    })
    .catch(err => {
      console.error(err);
      // If the token is undefined, it might be expired
      if (keycloak.token == undefined) {
        keycloak.clearToken();
        router.push('/login');
      } else {
        keycloak.clearToken();
        router.push('/something-went-wrong');
      }
    });
  return keycloak;
}

function setAuthState(keycloak) {
  store.commit("setAuthenticated", keycloak.authenticated);
}